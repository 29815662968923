import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from 'react-redux';
import DatePicker from 'react-date-picker';
import CloudImage from "../components/CloudImage.js";
import { numbersOnly } from "../util.js";

import {
  Pane,
  Paragraph,
  TextInput,
  TextInputField,
  TextareaField,
  Button,
  IconButton,
  Checkbox,
  Combobox,
  AddIcon,
  TrashIcon,
  HelpIcon,
  Tooltip,
  InfoSignIcon,
} from 'evergreen-ui';

export const TextInputFieldInput = props => {
  const [currentvalue, setCurrentvalue] = useState("");
  const mandatory = props.opts && props.opts.mandatory && props.editing && !currentvalue
    ? true
    : false;

  const showAsterisk = props.formLabel === "ISBN/UID" && props.editing && !currentvalue;

  useEffect(() => {
    if (
      (typeof props.value === 'string') ||
      (typeof props.value === 'number')
    ) {
      setCurrentvalue(props.value);
    }
    else {
      setCurrentvalue("")
    }
  }, [props.value]);

  return (
    <Pane key={props.formLabel}>
      <Pane display="flex">
      <Paragraph size={300} color="#667b7f">{props.formLabel}</Paragraph>
        {(mandatory || showAsterisk) && (
          <Paragraph color="red" size={300} marginLeft={4}>*</Paragraph>
        )}
      </Pane>

      <TextInputField
        key={props.el}
        className={props.className ? props.className : "item-form"}
        onChange={e => props.handleChange({ key: props.el, val: e.target.value })}
        value={currentvalue}
        disabled={props.editing ? false : true}
        width={props.width}
        label=""
        description={props.description}
        marginRight={(props.opts && props.opts.hasOwnProperty("marginRight") ? props.opts.marginRight : 0)}
        marginLeft={(props.opts && props.opts.hasOwnProperty("marginLeft") ? props.opts.marginLeft : 0)}
        marginBottom={(props.opts && props.opts.hasOwnProperty("marginBottom") ? props.opts.marginBottom : 16)}
      /> </Pane>
  );
}

export const TextInputWithUnitInput = props => {
  const [currentunit, setCurrentunit] = useState("in");
  const [currentvalue, setCurrentvalue] = useState("0");

  const setUnit = unit => {
    setCurrentunit(unit);
    props.handleChange({ key: props.el, val: { value: currentvalue, unit: unit } });
  }

  useEffect(() => {
    if (
      (props.value && typeof props.value === 'object') ||
      (props.value && props.value.length > 2)
    ) {
      let value = props.value;
      if (typeof props.value !== 'object') {
        value = JSON.parse(props.value);
      }
      if (value.hasOwnProperty("unit")) {
        setCurrentunit(value.unit);
      } else if (props.el && props.el === "weight") {
        setCurrentunit("lbs");
      }
      if (value.hasOwnProperty("value")) {
        setCurrentvalue(value.value);
      }
    }
  }, [props.value, props.el]);

  return (
    <Pane key={props.el + "parent"}>
      <Paragraph size={300} color="#667b7f">{props.formLabel}</Paragraph>
      <Pane
        key={props.el.replace("\s", "")}
        display="flex"
        flexDirection="row"
        justifycontent="flex-start"
        alignItems="flex-end"
        marginRight={(props.opts && props.opts.hasOwnProperty("marginRight") ? props.opts.marginRight : 0)}
      >


        <TextInputField
          label=""
          className="item-form"
          onChange={e => props.handleChange({ key: props.el, val: { value: e.target.value, unit: currentunit } })}
          value={currentvalue}
          disabled={props.editing ? false : true}
          width={props.width}
          marginRight={8}
        />
        {props.el === "weight" ? (
          <WeightInput el={props.el} current={currentunit} setSelected={setUnit} editing={false} />
        ) : (
          <SizeInput el={props.el} current={currentunit} setSelected={setUnit} editing={false} />
        )}
      </Pane>
    </Pane>
  );
}

const WeightInput = props => {
  const opts = [
    { label: "lbs" },
    { label: "oz" }
  ];
  return (
    <Pane>
      {props.editing ? (
        <Combobox
          key={props.current ? `${props.current}-${props.el}-weightpicker` : "weightpicker"}
          initialSelectedItem={{ label: props.current }}
          items={opts}
          itemToString={item => (item ? item.label : '')}
          onChange={selected => props.setSelected(selected.label)}
          width={75}
          marginLeft={4}
          marginBottom={24}
        />
      ) : (
        <Paragraph size={400} color="grey" marginBottom={24}>{props.current}</Paragraph>
      )}
    </Pane>
  )
}

const SizeInput = props => {
  const opts = [
    { label: "in" },
    { label: "cm" }
  ];
  return (
    <Pane>
      {props.editing ? (
        <Combobox
          key={props.current ? `${props.current}-${props.el}-sizepicker` : `${props.el}-sizepicker`}
          initialSelectedItem={{ label: props.current }}
          items={opts}
          itemToString={item => (item ? item.label : '')}
          onChange={selected => props.setSelected(selected.label)}
          width={75}
          marginLeft={4}
          marginBottom={24}
        />
      ) : (
        <Paragraph size={400} color="grey" marginBottom={24}>{props.current}</Paragraph>
      )}
    </Pane>
  )
}

export const TextareaFieldInput = props => {

  const [currentvalue, setCurrentvalue] = useState("");
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);



  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);

    if (
      (typeof props.value === 'string') ||
      (typeof props.value === 'number')
    ) {
      setCurrentvalue(props.value);
    } else {
      setCurrentvalue("")
    }
  }, [props.value, ref, cursor]);

  const handleInputChange = (e) => {
    setCursor(e.target.selectionStart);
    props.handleChange({ key: props.el, val: e.target.value })
  };
  return (
    <Pane key={props.formLabel}>
      <Paragraph size={300} color="#667b7f">{props.formLabel}</Paragraph>
      <TextareaField
        label=""
        ref={ref}
        key={props.el}
        className="item-form"
        onChange={handleInputChange}
        value={currentvalue}
        disabled={props.editing ? false : true}
        width={props.width}
        marginRight={(props.opts && props.opts.hasOwnProperty("marginRight") ? props.opts.marginRight : 0)}
      /></Pane>

  );
}

// Bug when adding a new one...
export const ArrayInputFieldInput = props => {
  const [max, setMax] = useState(1000);
  const [count, setCount] = useState(0);
  const [itemarr, setItemarr] = useState([]);

  const handleUpdateArr = (item, idx) => {
    // Double check item is not null
    if (!item) {
      return;
    }
    // extra step to remove null values
    let newarr = itemarr.filter(code => code);
    // find the current value in the existing array
    // and replace it
    if (idx === "new") {
      newarr.push(item);
    } else {
      newarr[idx] = item;
    }
    setItemarr(newarr);
    setCount(newarr.length);
    props.handleChange({ key: props.el, val: newarr });
  }

  const handleDeleteItem = item => {
    // Find the right element to delete

    let newarr = itemarr.filter(code => code !== item);
    setItemarr(newarr);
    setCount(newarr.length);
    props.handleChange({ key: props.el, val: newarr });
  }

  const parseArray = str => {
    let items = str;
    if (typeof items !== 'object' && items !== null && !Array.isArray(items)) {
      items = JSON.parse(items);
    }
    return items;
  }

  useEffect(() => {
    if (props.value && props.value.length > 2) {
      let items = parseArray(props.value);
      setItemarr(items);
      setCount(items.length);
      if (props.max) {
        setMax(props.max);
      }
    }
  }, [props.value, props.max]);

  const canEdit = max > count && props.editing;

  return (
    <Pane
      marginBottom={16}
      key={props.el}
      marginRight={(props.opts && props.opts.hasOwnProperty("marginRight") ? props.opts.marginRight : 0)}
    >
      <Paragraph size={300} color="#667b7f">{props.formLabel}</Paragraph>
      <Pane display="flex">
        {Array.isArray(parseArray(itemarr)) && parseArray(itemarr).map((item, idx) => {
          return (
            <Pane key={`${props.el}-${idx}`} display="flex" flexDirection="row" alignItems="center" marginLeft={8}>
              <TextInput width={100} disabled={!props.editing} onChange={e => handleUpdateArr(e.target.value, idx)} value={item} />
              {props.editing && (
                <IconButton marginLeft={8} icon={TrashIcon} intent="danger" onClick={e => handleDeleteItem(item)} />
              )}
            </Pane>
          );
        })}</Pane>
      {count <= 0 && (
        <Paragraph fontStyle="italic">(No {props.formLabel})</Paragraph>
      )}
      {canEdit && (
        <Button marginY={8} iconBefore={AddIcon} onClick={e => handleUpdateArr(" ", "new")}>Add Another...</Button>
      )}
    </Pane>
  );
}

export const CheckboxInput = props => {
  const [checked, setChecked] = useState(props.value)
  return (
    <Checkbox
      className="inputCheckbox"
      marginTop={0}
      marginBottom={(props.opts && props.opts.hasOwnProperty("labelDirection") && props.opts.labelDirection === "row" ? 0 : 16)}
      marginRight={(props.opts && props.opts.hasOwnProperty("marginRight") ? props.opts.marginRight : 0)}
      key={props.el}
      label={props.formLabel}
      checked={checked}
      disabled={!props.editing}
      onChange={e => {
        props.handleChange({ key: props.el, val: !props.value })
        setChecked(!checked)
      }

      }
    />
  );
}

// fix commenting
export const DateFieldInput = props => {
  const [startDate, setStartDate] = useState(new Date());
  const isValidTimestamp = timestamp => {
    const newTimestamp = new Date(timestamp).getTime();
    return !isNaN(parseFloat(newTimestamp)) && isFinite(newTimestamp);
  }

  useEffect(() => {
    if (props.value) {
      let dateUTC = new Date(props.value)
      // Adjust for timezone
      setStartDate(new Date(dateUTC.getTime() - dateUTC.getTimezoneOffset() * -60000));

    }
  }, [props.value]);

  return (
    <Pane
      key={props.el}
      marginBottom={(props.opts && props.opts.hasOwnProperty("labelDirection") && props.opts.labelDirection === "row" ? 0 : 16)}
      marginRight={(props.opts && props.opts.hasOwnProperty("marginRight") ? props.opts.marginRight : 0)}
      display="flex"
      flexDirection={(props.opts && props.opts.hasOwnProperty("labelDirection")) ? props.opts.labelDirection : "column"}
    >
      <Pane display="flex" >
        <Paragraph size={300} color="#667b7f" marginBottom={(props.opts && props.opts.hasOwnProperty("labelDirection") && props.opts.labelDirection === "row" ? 0 : 8)}
          marginRight={(props.opts && props.opts.hasOwnProperty("labelDirection") && props.opts.labelDirection === "row" ? 8 : 0)}>{props.formLabel}</Paragraph>
        {(props.opts && props.opts.hasOwnProperty("tooltip")) &&
          <Tooltip content={
            <Paragraph color="white" size={300}>{props.opts.tooltip}</Paragraph>
          }>
            <InfoSignIcon size={14} marginTop={2} color="muted" marginLeft={4} />
          </Tooltip>}

      </Pane>

      {props.editing ? (
        <DatePicker
          value={startDate}
          onChange={(date) => props.handleChange({ key: props.el, val: date })}
          clearIcon={null}
        />
      ) : (
        <TextInput width={100} value={!props.value ? "Not Set" : props.value} disabled={!props.editing} readOnly />
      )}
    </Pane>
  );
}

export const DateFieldRange = props => {
  const today = new Date();
  const prevMonth = new Date();
  const initStartDate = new Date(prevMonth.setDate(prevMonth.getDate() - 30));
  const initEndDate = today;
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate)

  useEffect(() => {
    if (props.startDate) {
      let dateUTC = new Date(props.startDate)
      // Adjust for timezone
      setStartDate(new Date(dateUTC.getTime() - dateUTC.getTimezoneOffset() * -60000));
    }
    if (props.endDate) {
      let dateUTC = new Date(props.endDate)
      // Adjust for timezone
      setEndDate(new Date(dateUTC.getTime() - dateUTC.getTimezoneOffset() * -60000));
    }
  }, [props.startDate, props.endDate]);

  return (
    <Pane display="flex">
      <Pane >
        <Paragraph color="#667b7f" size={300}>Start Date</Paragraph>
        <DatePicker
          value={startDate}
          maxDate={endDate}
          onChange={(date) => props.handleChange({ key: "startDate", val: date })}
          clearIcon={null}
        />
      </Pane>
      <Pane marginLeft={24}>
        <Paragraph color="#667b7f" size={300}>End Date</Paragraph>
        <DatePicker
          value={endDate}
          minDate={startDate}
          clearIcon={null}
          onChange={(date) => props.handleChange({ key: "endDate", val: date })}
        />
      </Pane>
    </Pane>

  )
}

// <ImageUpload />

export const ImageInput = props => {
  return (
    <Pane key={`${props.el.replace("\s", "")}`}>
      {props.editing ? (
        <CloudImage image={props.value} />
      ) : (
        <CloudImage image={props.value} />
      )}
    </Pane>
  );
}

export const CurrencyInput = props => {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [currentvalue, setCurrentvalue] = useState("");

  console.log("======== currency ======== ", numbersOnly(currentvalue))

  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

    const mandatory = props.opts && props.opts.mandatory && props.editing && !currentvalue
    ? true
    : false;
  useEffect(() => {
    if (
      (typeof props.value === 'string') ||
      (typeof props.value === 'number')
    ) {
      setCurrentvalue(props.value);
    } else {
      setCurrentvalue('')
    }
  }, [props.value]);

  return (
    <Pane
      key={props.el + "parent"}
      marginBottom={0}
      marginRight={(props.opts && props.opts.hasOwnProperty("marginRight") ? props.opts.marginRight : 0)}
    >
      <Pane display="flex">
      <Paragraph size={300} marginBottom={8} marginLeft={16} color="#667b7f">{props.formLabel}</Paragraph>
      {mandatory && (
        <Paragraph color="red" size={300} marginLeft={4}>*</Paragraph>
      )}
      
      
      </Pane>
      

      <Pane display="flex" flexDirection="row" alignItems="center">
        <Paragraph marginRight={8} size={300}>{currency}</Paragraph>
        <TextInput
          key={props.el}
          className="item-form"
          onChange={e => props.handleChange({ key: props.el, val: e.target.value, isValid: numbersOnly(e.target.value) })}
          value={currentvalue}
          disabled={props.editing ? false : true}
          width={props.width}
          // isInvalid={props.editing && currentvalue && numbersOnly(currentvalue) === false}
          isInvalid={props.editing && numbersOnly(currentvalue) === false}
          description={props.description}

        /> 
        {props.editing && !numbersOnly(currentvalue) && (
          <Tooltip content={<Paragraph color="white" size={300}>Only numbers and one period are allowed</Paragraph>}
            border="default"><HelpIcon size={14} marginTop={2} color="muted" marginLeft={4} /></Tooltip>

        )}
      </Pane>

      {props.description && (
        <Paragraph color="red" size={300}>{props.description}</Paragraph>
      )}
    </Pane>
  );
}
