// Item in Statement Info when generating Royalty Statement
import React from "react";
import { commaSepThou, makeAdjustedString } from '../../../util.js';
import JSON5 from 'json5';


import {
  Pane,
  Paragraph,
} from 'evergreen-ui';



const RoyaltyStatementSummary = props => {

  const startDate = props.startDate
  const endDate = props.endDate
  const selected = props.items
  const currency = props.currency

  
  return (
    <Pane>
      <Paragraph size={400} marginTop={8} marginBottom={8} ><b>Statement Period:</b> {startDate} through {endDate}</Paragraph>

      {selected.length > 0 &&
        <table width="80%" padding="4px" style={{
          color: "#474d66",
          fontSize: "12px",
          textAlign: "left",
          borderCollapse: "collapse"
        }}>
          <thead>
            <tr height={32} style={{ backgroundColor: "#F9FAFC", borderTop: "1px solid #E6E8F0", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
              <th style={{ paddingLeft: "8px" }}>PRODUCTS ({selected.length})</th>
              <th >CURRENT BALANCE</th>
            </tr>
          </thead>
          <tbody>
            {selected.map(item => {
              const product = JSON5.parse(makeAdjustedString(item.product))
              const adjustedStatementString = makeAdjustedString(item.statementInfo)
              const statementInfo = JSON5.parse(adjustedStatementString)
              const currentBalance = commaSepThou(Math.abs(statementInfo.lifeCredit - statementInfo.lifeDebit - parseFloat(item.paid)).toFixed(2))
              const balNegCur = (statementInfo.lifeDebit + parseFloat(item.paid)) > statementInfo.lifeCredit

              return (
                <tr height={32} style={{ backgroundColor: "white", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
                  <td style={{ paddingLeft: "8px" }}>{product.title}</td>
                  <td>{balNegCur ? "-" : ""}{currency}{currentBalance}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      }

    </Pane>
  )
}


export default RoyaltyStatementSummary;  
