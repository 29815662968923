import { gql } from '@apollo/client';

const QUERY_TOP_TEN_BY_DATE = gql`
  query TopTenByDate($publisher: Int!, $startDate: String, $endDate: String) {
  	toptenbydate(publisher: $publisher, startDate: $startDate, endDate: $endDate) {
		id,
  		totalAmount,
  		product {
  			id,
  			title,
  			format {
				id, 
  				description
  			}
  		}
  	}
  	expensesbydate(publisher: $publisher, startDate: $startDate, endDate: $endDate) {
		id,
  		expenseDate,
  		product {
  			id
  		},
  		amount
  	}
  }
`;

export default QUERY_TOP_TEN_BY_DATE;