import { gql } from '@apollo/client';

const CREATE_ROYALTY_STATEMENT_MUTATION = gql`
  mutation CreateRoyaltyStatementMutation(
    $publisherId: Int!,
    $creatorId: Int!,
    $statement: String!,
    $productIds: String!
  ) {
    createRoyaltyStatement(
      publisherId: $publisherId,
      creatorId: $creatorId,
      statement: $statement,
      productIds: $productIds
    ) {
      message
    }
  }
`;

export default CREATE_ROYALTY_STATEMENT_MUTATION;