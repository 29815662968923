import React, { useEffect, useState } from "react";

import {
  SelectMenu,
  Button,
  LightbulbIcon,
} from 'evergreen-ui';

function SelectContactInfo(props) {
  const [selected, setSelected] = useState("");
  const [label, setLabel] = useState(false)

  const handleSetChecked = item => {
    const keys = {
      "email": "orderEmail",
      "phone": "orderPhone",
      "address": "orderAddress"
    }
    props.handleChooseInfo(item, keys[props.itemType]);

  }

  const mode = props.itemType === "email" || props.itemType === "phone"
    ? "simple"
    : "complex";

  useEffect(() => {
    setSelected("")
    setLabel(props.staged ? mode === "simple" ? props.staged.value : 'Change Address...' : false)

  }, [props.itemSet, props.staged, mode])


  return (
    <SelectMenu
      hasTitle={false}
      options={props.itemSet.map(item => ({
        key: `${props.itemType}${item.id}`,
        value: JSON.stringify(item),
        label: mode === "simple" ? item.value : 
        ["line1", "line2", "city", "stateOrRegion", "zipcode", "country"].map(field => item[field]).filter(Boolean).join(", ").toString(),
      }))
      }
      selected={selected}
      closeOnSelect={true}
      onSelect={item => {
        handleSetChecked(item.value)
        setSelected(item.value)
        setLabel(item.label)

      }}

      width={300}
      >
      <Button size="small">{label ? label : 'Choose...'}</Button>
    </SelectMenu>
  )
}

export default SelectContactInfo;