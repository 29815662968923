import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

// ------- FUNCTIONS
import { makeDateString } from '../../util.js';

// ------- QUERIES/MUTATIONS
import QUERY_ROYALTY from "../../graphql/queries/Royaltys/QueryRoyalty.js";
import DELETE_ROYALTY from "../../graphql/mutations/Royaltys/DeleteRoyalty.js"
import CREATE_ROYALTY_LINE_ITEM_MUTATION from "../../graphql/mutations/Royaltys/CreateRoyaltyLineItem.js";
import UPDATE_ROYALTY_LINE_ITEM_MUTATION from "../../graphql/mutations/Royaltys/UpdateRoyaltyLineItem.js";
import DELETE_ROYALTY_LINE_ITEM_MUTATION from "../../graphql/mutations/Royaltys/DeleteRoyaltyLineItem.js";
import CREATE_ROYALTY_PAYMENT_MUTATION from "../../graphql/mutations/Royaltys/CreateRoyaltyPayment.js";
import UPDATE_ROYALTY_PAYMENT_MUTATION from "../../graphql/mutations/Royaltys/UpdateRoyaltyPayment.js";
import DELETE_ROYALTY_PAYMENT_MUTATION from "../../graphql/mutations/Royaltys/DeleteRoyaltyPayment.js";

// ------- COMPONENTS
import { baseRoyaltyObject } from './BaseRoyaltyObject.js';
import RoyaltyLineItem from "./RoyaltyLineItem.js";
import PaymentTable from "../../components/PaymentTable.js";
import Breadcrumbs from "../../components/layout/Breadcrumbs.js";
import SaveButtons from "../../components/layout/SaveButtons.js";
import LeavePagePrompt from "../../components/LeavePagePrompt.js";
import CloudImage from "../../components/CloudImage.js";
import { FrozenTooltip } from "../../components/NotSeeingItems.js";

import {
  Pane,
  Paragraph,
  Button,
  TextInput,
  Badge,
  Strong,
  Tablist,
  Tab,
  Card,
  EmptyState,
  InfoSignIcon,
  toaster,
  Table,
  AddIcon,
  ArrowDownIcon,
  Tooltip,
  LockIcon,
} from 'evergreen-ui';

function Royalty() {
  const userid = useSelector((state) => state.user.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const navigate = useNavigate();

  const [royalty, setRoyalty] = useState(baseRoyaltyObject);
  const [items, setItems] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [staged, setStaged] = useState(baseRoyaltyObject);
  const [editing, setEditing] = useState(false);
  const [idCount, setIdCount] = useState(0)
  const [canSave, setCanSave] = useState(true);
  const [canDelete, setCanDelete] = useState(false);

  const [tabs] = useState(['ROYALTY TERMS', 'PAYMENTS'])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { royaltyId } = useParams();

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_ROYALTY, {
    variables: { publisher: parseInt(currentpublisher.id), id: parseInt(royaltyId) },
  });
  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  const handleRefetchRoyalty = () => {
    refetch();
  }

  const [deleteRoyalty, {deletedata}] = useMutation(DELETE_ROYALTY)
  const [createRoyaltyLineItem, { itemcdata }] = useMutation(CREATE_ROYALTY_LINE_ITEM_MUTATION);
  const [updateRoyaltyLineItem, { itemudata }] = useMutation(UPDATE_ROYALTY_LINE_ITEM_MUTATION);
  const [deleteRoyaltyLineItem, { itemddata }] = useMutation(DELETE_ROYALTY_LINE_ITEM_MUTATION);
  const [createRoyaltyPayment, { createpaymentdata }] = useMutation(CREATE_ROYALTY_PAYMENT_MUTATION);
  const [updateRoyaltyPayment, { updatepaymentdata }] = useMutation(UPDATE_ROYALTY_PAYMENT_MUTATION);
  const [deleteRoyaltyPayment, { deletepaymentdata }] = useMutation(DELETE_ROYALTY_PAYMENT_MUTATION);

  const handleNewRoyaltyPayment = async (payment) => {
    let sentDate = !payment.sentDate ? makeDateString(new Date()) : makeDateString(payment.sentDate);
    try {
      let result;
      if (staged.creator.paymentType) {
        result = await createRoyaltyPayment(
          {
            variables: {
              royaltyId: parseInt(staged.id),
              payment: JSON.stringify({
                method: { id: staged.creator.paymentType.id },
                sentDate: sentDate,
                amount: payment.amount
              })
            }
          });
      } else {
        result = await createRoyaltyPayment(
          {
            variables: {
              royaltyId: parseInt(staged.id),
              payment: JSON.stringify({ sentDate: sentDate, amount: payment.amount })
            }
          });
      }
      if (canDelete) {
        setCanDelete(false)
      }
      toaster.closeAll()
      toaster.success(`Recorded royalty payment ${result.data.createRoyaltyPayment.message}`)
      handleRefetchRoyalty();

    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error creating royalty payment. Try again or contact developer", { duration: 5 })
      return error.message
    }

  }

  const handleUpdateRoyaltyPayment = async (itemId, changed) => {
    try {
      const result = await updateRoyaltyPayment(
        {
          variables: {
            id: parseInt(itemId),
            changed: changed,
          }
        });
      toaster.closeAll()
      toaster.success(`Updated royalty payment ID: ${result.data.updateRoyaltyPayment.message}`)
      handleRefetchRoyalty();

    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error saving royalty payment. Try again or contact developer", { duration: 5 })
      return error.message
    }

  }
  const handleDeleteRoyalty = async () => {
    try {
      const results = await deleteRoyalty(
        {
          variables: {
            id: parseInt(staged.id),
          }
        });
      toaster.closeAll()
      toaster.success(`Royalty ${results.data.deleteRoyalty.message} deleted succesfully!`, { duration: 10 })
      navigate('/royalties')

    }
    catch (error) {
      toaster.closeAll()
      toaster.danger("Error deleting royalty. Try again or contact developer", { duration: 5 })
      return error.message;
    }
  }
  const handleDeleteRoyaltyPayment = async (itemId) => {
    try {
      const result = await deleteRoyaltyPayment(
        {
          variables: {
            id: parseInt(itemId),
          }
        });
      
      if (canDelete && royalty.royaltypaymentSet.length === 1) {
        setCanDelete(true)
      }

      toaster.closeAll()
      toaster.success(`Deleted royalty payment ID: ${result.data.deleteRoyaltyPayment.message}`)
      handleRefetchRoyalty();
    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error deleting royalty payment. Try again or contact developer", { duration: 5 })
      return error.message
    }

  }



  const resetForm = () => {
    setStaged(royalty);
    setNewItems([]);
    setChangedItems([]);
    setDeletedItems([]);
    let sortedItems = initializeTempIds(0, royalty.royaltylineitemSet)
    let discountList = sortedItems.map(item => item.tierDiscount).filter((v, i, a) => a.indexOf(v) === i);
    let resetItems = [];
    discountList.forEach((d) => {
      let discarr = sortedItems.filter(item => item.tierDiscount === d)
      discarr.forEach((item, index) => {
        if (index === 0) {
          item.thresholdStart = 0;
        }
        else if (index > 0) {
          discarr[index - 1].thresholdEnd = String(item.thresholdStart - 1)
        }
        if (index === discarr.length - 1) {
          item.thresholdEnd = "\u{221E}"
        }
      })
      resetItems = resetItems.concat(discarr)
    })
    setItems(resetItems)

  }

  const handleUpdateRoyalty = async () => {
    try {

      let cleanedNew = newItems.map(item => {
        return {
          amount: item.amount,
          thresholdStart: item.thresholdStart,
          thresholdEnd: item.thresholdEnd,
          royaltyTerm: item.royaltyTerm,
          category: item.category,
          tierDiscount: item.tierDiscount
        }
      });

      if (cleanedNew.length) {
        const allCreated = await Promise.all(cleanedNew.map(async (line) => {
          const created = await createRoyaltyLineItem(
            {
              variables: {
                royaltyId: parseInt(staged.id),
                royaltyItem: JSON.stringify(line)
              }
            })
          return created
        }))
        toaster.closeAll()
        toaster.success(`Created ${allCreated.length} tiers`, { duration: 10 })
      }

      if (changedItems.length) {
        const allUpdated = await Promise.all(changedItems.map(async (line) => {
          let lineArr = [];

          for (let key in line) {
            if (line.hasOwnProperty(key)) {
              let itemProp = { 'key': key, 'val': line[key] }
              lineArr.push(itemProp)
            }
          };
          const updated = await updateRoyaltyLineItem(
            {
              variables: {
                id: parseInt(line.id),
                changed: JSON.stringify(lineArr)
              }
            })
          return updated
        }))
        toaster.closeAll()
        toaster.success(`Updated ${allUpdated.length} tiers`, { duration: 10 })
      }


      if (deletedItems.length) {
        const allDeleted = await Promise.all(deletedItems.map(async (line) => {
          const deleted = await deleteRoyaltyLineItem(
            {
              variables: {
                id: parseInt(line.id)
              }
            })
          return deleted
        }))
        toaster.closeAll()
        toaster.success(`Deleted ${allDeleted.length} tiers`, { duration: 10 })
      }
      setNewItems([]);
      setDeletedItems([]);
      setChangedItems([]);

    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error saving royalty. Try again or contact developer", { duration: 5 })
      return error.message
    }

  }

  // Functions to handle lineitem actions (add, update, delete)

  const handleAddRoyaltyLineItem = (category, discount) => {
    const lastTier = items.filter(item => item.last === true).filter(item => item.tierDiscount === discount)[0]
    let tmpid = idCount;
    let newItem = {
      tmpid: tmpid + 1,
      amount: "",
      thresholdStart: lastTier ? lastTier.thresholdStart + 100 : 0,
      thresholdEnd: "\u{221E}",
      royaltyTerm: "",
      category: category,
      tierDiscount: discount,
      tmpids: []
    };
    tmpid += 1;
    setIdCount(tmpid);
    let itemarr = [newItem].concat(items);
    updateItemArrays(newItem, itemarr, false)
  }

  const handleUpdateRoyaltyLineItem = item => {
    let itemarr = items.filter(ri => ri.tmpid !== item.tmpid);
    itemarr.push(item);
    updateItemArrays(item, itemarr, false)
  }

  const handleDeleteRoyaltyLineItem = item => {
    let itemarr = items.filter(ri => ri.tmpid !== item.tmpid)
    updateItemArrays(item, itemarr, true)
  }

  const checkIfValid = arr => {
    let invalid = arr.some(item => {
      return (!item.royaltyTerm || item.invalid || !item.amount)
    }) || arr.length < 1
    if (invalid) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }

  const updateItemArrays = (item, itemarr, deleteItem) => {
    let changedarrAdd = [];
    let newarrAdd = [];
    let disc = item.tierDiscount

    setEndThresholds(itemarr, changedarrAdd, newarrAdd, disc)
    let changedIds = changedarrAdd.map(item => item.id)
    let newIds = newarrAdd.map(item => item.tmpid)

    let changedarr = changedItems.filter(ri => !changedIds.includes(ri.id))
    let newarr = newItems.filter(ri => !newIds.includes(ri.tmpid))
    let deletearr = deletedItems.filter(ri => ri.id !== item.id)

    changedarr = changedarr.concat(changedarrAdd)
    newarr = newarr.concat(newarrAdd)

    if (deleteItem) {
      if (item.hasOwnProperty("id")) {
        deletearr.push(item)
        changedarr = changedarr.filter(ri => ri.id !== item.tmpid)
      }
      else {
        newarr = newarr.filter(ri => ri.tmpid !== item.tmpid)
      }
    }

    checkIfValid(itemarr)
    setItems(itemarr)
    setChangedItems(changedarr)
    setNewItems(newarr)
    setDeletedItems(deletearr)

  }

  const setEndThresholds = (itemarr, changeAdd, newAdd, disc) => {
    let discarr = itemarr.filter(item => item.tierDiscount === disc)
    discarr.sort((a, b) => (a.tmpid > b.tmpid) ? 1 : ((b.tmpid > a.tmpid) ? -1 : 0))
    discarr.forEach((item, index) => {
      if (index === 0) {
        item.thresholdStart = 0;
      }
      else if (index > 0) {
        discarr[index - 1].thresholdEnd = String(item.thresholdStart - 1)
        discarr[index - 1].hasOwnProperty('id') ? changeAdd.push(discarr[index - 1]) : newAdd.push(discarr[index - 1])
      }
      if (index === discarr.length - 1) {
        item.thresholdEnd = "\u{221E}"
        item.hasOwnProperty('id') ? changeAdd.push(item) : newAdd.push(item)
      }
    }

    )
    discarr.forEach((item, index) => {
      if (index > 0) {
        item.invalid = item.thresholdStart <= discarr[index - 1].thresholdStart
      }
    })


  }

  const sortItems = (arr, category) => {
    arr = arr.filter(item => parseInt(item.category.id) === category)
    let discountList = sortItemsByDiscount(arr)
    let newarr = [];
    discountList.forEach((d, index) => {
      let discarr = arr.filter(item => item.tierDiscount === d)
      discarr.sort((a, b) => (a.tmpid > b.tmpid) ? 1 : ((b.tmpid > a.tmpid) ? -1 : 0))
      discarr.forEach((item) => {
        item.first = false; item.last = false; item.tierCount = discarr.length;
      })
      discarr[0].first = true;
      discarr[discarr.length - 1].last = true;
      newarr = newarr.concat(discarr)
    })
    return newarr
  }

  const sortItemsByDiscount = arr => {
    // get list of unique discounts
    let discountList = arr.map(item => item.tierDiscount).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => parseInt(a) < parseInt(b) ? 1 : -1)
    let discountTmpids = [];

    // for each unique discount
    discountList.forEach((d) => {
      let temparr = arr.filter(item => item.tierDiscount === d)
      let tmpids = temparr.map(item => item.tmpid)
      temparr.forEach(item => { item.tmpids = tmpids })
      discountTmpids.push({ discount: d, tmpid: Math.min(...tmpids), tmpids: tmpids })
    })

    // so that newly added discount tier is added to bottom of panel
    discountTmpids = discountTmpids.sort((a, b) => (a.tmpid > b.tmpid) ? 1 : ((b.tmpid > a.tmpid) ? -1 : 0))
    discountList = discountTmpids.map(item => item.discount)
    return discountList

  }

  const handleChangeDiscount = e => {
    let tmpids = items.filter(a => a.tmpid.toString() === e.target.id)[0].tmpids
    tmpids.forEach((id) => {
      let item = items.filter(a => a.tmpid === id)[0]
      item.tierDiscount = e.target.value
      handleUpdateRoyaltyLineItem(item)
    })
  }

  const balance = parseFloat(royalty.owed) - parseFloat(royalty.paid);

  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  const initializeTempIds = useCallback((initId, lineitems) => {
    let tmpid = initId;
    let sortedItems = [];
    let regTiers = lineitems.filter(item => item.tierDiscount === "None")
    regTiers = regTiers.sort((a, b) => (a.thresholdStart > b.thresholdStart) ? 1 : ((b.thresholdStart > a.thresholdStart) ? -1 : 0))
    regTiers.forEach((item) => {
      if (!item.hasOwnProperty("tmpid")) {
        item.tmpid = tmpid;
        tmpid += 1;
      }
    })
    sortedItems = sortedItems.concat(regTiers)

    let discountList = lineitems.filter(item => item.tierDiscount !== "None").map(item => item.tierDiscount).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => parseInt(a) < parseInt(b) ? 1 : -1);
    discountList.forEach((d) => {
      let temparr = lineitems.filter(item => item.tierDiscount === d)
      temparr = temparr.sort((a, b) => (a.thresholdStart > b.thresholdStart) ? 1 : ((b.thresholdStart > a.thresholdStart) ? -1 : 0))
      temparr.forEach((item) => {
        if (!item.hasOwnProperty("tmpid")) {
          item.tmpid = tmpid;
          tmpid += 1;
        }
      })
      sortedItems = sortedItems.concat(temparr)
    }
    )
    setIdCount(tmpid)
    return sortedItems
  }, [])


  useEffect(() => {
    if (data && data.royalty.length) {
      const item = data.royalty[0]
      setRoyalty(item);
      setStaged(item);
      let sortedItems = initializeTempIds(idCount, item.royaltylineitemSet)
      setItems(sortedItems)
      const linkedModelCount = item.royaltypaymentSet.length
      if (linkedModelCount === 0) {
        setCanDelete(true)
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, initializeTempIds]);

  if (loading) return null;
  if (error) return `Error! ${error}`;
  if (data.royalty.length === 0) return (
    <Pane >
      <Pane paddingX={16} paddingTop={16}>
        <Breadcrumbs pageLabel="ROYALTIE" sourceUrl="/royalties" />
      </Pane>
      <Pane display="flex" justifyContent="center" marginTop={60}>
        <Card alignItems="center" width="50%" elevation={2}>
          <EmptyState
            background="light"
            title="Royalty not found"
            orientation="vertical"
            icon={<InfoSignIcon size={12} color="#C1C4D6" />}
            iconBgColor="#EDEFF5" />
        </Card>

      </Pane>


    </Pane>);

  return (
    <Pane>
      {
        editing &&
        <LeavePagePrompt
          userid={userid}
          itemid={royaltyId}
          itemType="royalty"
          when={!!(changedItems?.length)}
        />
      }
      <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16}>
        <Breadcrumbs pageLabel="ROYALTY" curPage={royalty.id} sourceUrl="/royalties" />
        <Pane marginTop={8} marginBottom={8} size={300} display="flex" justifyContent="space-between" >
          <Pane display="flex" alignItems="flex-end">
            {royalty.product.frozen && <Tooltip content={<FrozenTooltip parentItem="royalties" items="products" />}><LockIcon size={18} color="muted" /></Tooltip>}

            <Paragraph marginLeft={4} size={300} fontSize={18}> Royalty </Paragraph>

            <Paragraph marginLeft={8} size={300}><Badge color="neutral">ID: {royalty.id}</Badge></Paragraph>
            <Badge marginLeft={8} color={balance < 0 ? "green" : balance >= 100 ? "red" : "neutral"}
            >
              Balance: {balance < 0 ? "-" : ""}{currency}{Math.abs(balance.toFixed(2))}
            </Badge>
            <Badge size={300} marginLeft={8}>Payments Made: {currency}{parseFloat(royalty.paid).toFixed(2)}</Badge>
          </Pane>
          <Pane>
            <SaveButtons
              itemType="royalty"
              editing={editing}
              locked={royalty.locked}
              itemId={royaltyId}
              setEditing={setEditing}
              handleUpdate={handleUpdateRoyalty}
              handleDelete={handleDeleteRoyalty}
              resetForm={resetForm}
              refetch={refetch}
              canDelete={canDelete}
              canSave={canSave}
              deleteMsg={"Cannot delete royalty if there are any linked payments."}
              loading={itemcdata?.loading || itemddata?.loading || itemudata?.loading || createpaymentdata?.loading}
              frozen={royalty.product.frozen}
            />
          </Pane>
        </Pane>
      </Pane>
      <Pane paddingX={16} display="flex">
        <Pane marginTop={16}>
          <Tab className="custom-tab" isSelected={true} width="calc(30vw - 175px)" fontWeight="bold">
            CREATOR/PRODUCT</Tab>
          <Pane className="tab-sidebar" padding={16} display="flex" flexDirection="column" alignItems="center" >



            <Pane marginBottom={16} display="flex" flexDirection="column" alignItems="center">
              <Paragraph size={400} fontWeight="bold" color="#5e5e5e" marginBottom={8}>CREATOR</Paragraph>
              <CloudImage path={staged.creator.photoUrl ? staged.creator.photoUrl : ""} avatar={true} name={staged.creator.displayName} />
              <Paragraph>
                <Link to={`/creators/${staged.creator.id}`}>{staged.creator.displayName} </Link>
              </Paragraph>
              {staged.creator.paymentName && <Paragraph>({staged.creator.paymentName})</Paragraph>}
            </Pane>

            <Pane>
              <Pane marginBottom={16} display="flex" flexDirection="column" alignItems="center">
                <Paragraph size={400} fontWeight="bold" color="#5e5e5e" >PRODUCT</Paragraph>

                <CloudImage path={staged.product.coverImage ? staged.product.coverImage : ""} customClass=" small" />

                <Paragraph>
                  <Link to={`/products/${staged.product.id}`}>{staged.product.title}</Link>
                </Paragraph>
                <Paragraph>
                  <Badge>{staged.product.format ? staged.product.format.description : "No Format"}</Badge>
                </Paragraph>
                <Paragraph size={300} color="#667b7f">MSRP: ${staged.product.retailPrice}</Paragraph>
              </Pane>

            </Pane>
            <Pane>
              {staged.creator.paymentType ? (
                <Pane marginBottom={editing ? 4 : 16} display="flex" flexDirection="column" alignItems="center">
                  <Paragraph size={400} fontWeight="bold" color="#5e5e5e" >PAYMENT METHOD</Paragraph>
                  <Paragraph>{staged.creator.paymentType.value}</Paragraph>
                </Pane>
              ) : (
                <Pane marginBottom={editing ? 4 : 16} display="flex" flexDirection="column" alignItems="center">
                  <Paragraph><Strong>Default Payment Method:</Strong></Paragraph>
                  <Paragraph><Link to={`/creators/${staged.creator.id}`}>(None chosen for creator)</Link></Paragraph>
                </Pane>
              )}
            </Pane>
          </Pane>
        </Pane>
        <Pane padding={16} flex={1} >
          <Tablist>
            {tabs.map((tab, index) => (
              <Tab
                aria-controls={`panel-${tab}`}
                isSelected={index === selectedIndex}
                key={tab}
                onSelect={() => setSelectedIndex(index)}
                className="custom-tab"
                fontWeight="bold"
              >
                {tab}
              </Tab>
            ))}
          </Tablist>
          <Pane id="item-details" elevation={1}
            aria-labelledby={'ROYALTY TERMS'}
            aria-hidden={0 !== selectedIndex}
            display={0 === selectedIndex ? 'flex' : 'none'}
            key={'ROYALTY TERMS'}
            role="tabpanel"
            className="tab-content-short"
            flexDirection="column"
          >

            <Pane>
              <Paragraph size={400} fontWeight="bold" color="#5e5e5e" marginBottom={8}>REGULAR TIERS</Paragraph>
              <Table width={editing ? 600 : 500}>
                <Table.Head height={40} className="table-heading">
                  <Table.HeaderCell flex={editing ? 2 : 1}>
                    Amount
                  </Table.HeaderCell>
                  <Table.HeaderCell flex={editing ? 4 : 2}>
                    Term
                  </Table.HeaderCell>
                  <Table.HeaderCell flex={5} >
                    Tier Start/End
                  </Table.HeaderCell>
                  {editing &&
                    <Table.HeaderCell flex={1} >
                    </Table.HeaderCell>}

                </Table.Head>
                {sortItems(items, 1).map(item =>
                  <RoyaltyLineItem key={`royaltyline${item.tmpid}`} item={item} handleUpdateItem={handleUpdateRoyaltyLineItem} editing={editing} handleDelete={handleDeleteRoyaltyLineItem} />
                )}
              </Table>
              {editing && (
                <Button
                  iconBefore={AddIcon}
                  onClick={e => handleAddRoyaltyLineItem({ id: 1, value: "units sold" }, "None")}
                  size="small"
                  marginTop={8}>
                  ADD TIER
                </Button>
              )}
            </Pane>
            <Paragraph size={400} fontWeight="bold" color="#5e5e5e" marginTop={16} marginBottom={8}>DISCOUNTED SALES TIERS</Paragraph>

            {sortItems(items, 2).length > 0 &&
              <Pane>
                <Table width={editing ? 600 : 500}>
                  <Table.Head height={40} className="table-heading">
                    <Table.HeaderCell flex={editing ? 2 : 1}>
                      Amount
                    </Table.HeaderCell>
                    <Table.HeaderCell flex={editing ? 4 : 2}>
                      Term
                    </Table.HeaderCell>
                    <Table.HeaderCell flex={5} >
                      Tier Start/End
                    </Table.HeaderCell>
                    {editing &&
                      <Table.HeaderCell flex={1} >
                      </Table.HeaderCell>}

                  </Table.Head>
                  {sortItems(items, 2).map(item => {
                    let button2 = editing
                      ? (<TextInput className="item-form" width={50} marginY={0} id={item.tmpid} placeholder={item.tierDiscount} onChange={e => handleChangeDiscount(e)} marginLeft={10} />)
                      : item.tierDiscount;
                    let button3 = editing ?
                      (<Button size="small" iconBefore={ArrowDownIcon} onClick={e => handleAddRoyaltyLineItem({ id: 2, value: "discount" }, item.tierDiscount)}
                      > Add tier for sales discounted by {item.tierDiscount} % </Button>)
                      : "";
                    if (item.first) {
                      return (
                        <Pane >
                          <Table.Row height={editing ? 36 : 32} backgroundColor="var(--offwhite)" >
                            <Table.TextCell flex={editing ? 1 : 2}>
                              <Paragraph size={300} marginLeft={16} fontWeight="bold" color="var(--darkgrey)"> If order discounted by at least {button2} %:</Paragraph>
                            </Table.TextCell>
                            <Table.TextCell flex={1}>
                              {button3}
                            </Table.TextCell>
                          </Table.Row>
                          <RoyaltyLineItem key={`royaltyline${item.tmpid}`} item={item} handleUpdateItem={handleUpdateRoyaltyLineItem} editing={editing} handleDelete={handleDeleteRoyaltyLineItem} />
                        </Pane>
                      );
                    }
                    else {
                      return (
                        <RoyaltyLineItem key={`royaltyline${item.tmpid}`} item={item} handleUpdateItem={handleUpdateRoyaltyLineItem} editing={editing} handleDelete={handleDeleteRoyaltyLineItem} />
                      )
                    }

                  })}
                </Table>

              </Pane>}
            <Pane>
              {editing && (
                <Button iconBefore={AddIcon} size="small"
                  marginTop={8} onClick={e => handleAddRoyaltyLineItem({ id: 2, value: "discount" }, " ")}>ADD DISCOUNTED TIER SET</Button>

              )}
            </Pane>
          </Pane>
          <Pane id="pay-details" elevation={1}
            aria-labelledby={'PAYMENTS'}
            aria-hidden={1 !== selectedIndex}
            display={1 === selectedIndex ? 'flex' : 'none'}
            key={'PAYMENTS'}
            role="tabpanel"
            className="tab-content-short"
          >
            <PaymentTable
              payType={"royalty"}
              method={staged.creator.paymentType}
              paymentSet={staged.royaltypaymentSet}
              handleNewPayment={handleNewRoyaltyPayment}
              handleUpdatePayment={handleUpdateRoyaltyPayment}
              handleDeletePayment={handleDeleteRoyaltyPayment}
              loading={updatepaymentdata?.loading || deletepaymentdata?.loading}
            />
          </Pane>
        </Pane>
      </Pane>


    </Pane>
  )
}

export default Royalty;