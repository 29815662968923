import { gql } from '@apollo/client';

const CREATE_SHIPPINGTYPE_MUTATION = gql`
  mutation CreateShippingTypeMutation(
    $publisherId: Int!,
    $shippingType: String!
  ) {
    createShippingType(
      publisherId: $publisherId,
      shippingType: $shippingType
    ) {
      message
    }
  }
`;

export default CREATE_SHIPPINGTYPE_MUTATION;