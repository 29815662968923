import { gql } from '@apollo/client';

const CREATE_PRODUCT_MUTATION = gql`
  mutation CreateProductMutation(
    $publisherId: Int!,
    $product: String!
  ) {
    createProduct(
      publisherId: $publisherId,
      product: $product
    ) {
      id
      title
      format
    }
  }
`;

export default CREATE_PRODUCT_MUTATION;