// TODO - JSDOC format, mutation results
import React, { useState, } from "react";
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { setprofile } from '../../redux/user/profileSlice.js';
import ImageUpload from "../../components/ImageUpload.js";
import UPDATE_PUBLISHER_USER_MUTATION from "../../graphql/mutations/UpdatePublisherUser.js";
import CloudImage from "../../components/CloudImage.js";
import { getCookie } from '../../util.js';


import {
  Pane,
  Button,
  TextInputField,
  Paragraph,
} from 'evergreen-ui';

function EditAccountForm(props) {
  const profile = useSelector((state) => state.profile.value);
  const dispatch = useDispatch();
  const [reset, setReset] = useState(false)


  const [fname, setFname] = useState(props.publisheruserSet[0].firstName);
  const [lname, setLname] = useState(props.publisheruserSet[0].lastName);

  const [updatePublisherUser, { udata }] = useMutation(UPDATE_PUBLISHER_USER_MUTATION);

  const setUserImage = url => {
    updatePublisherUser(
      {
        variables: {
          id: parseInt(props.publisheruserSet[0].id),
          changed: JSON.stringify([{ key: "photoUrl", val: url }]),
        }
      });
    props.handleRefetchUser();
  }

  const handlePasswordReset = e => {
    e.preventDefault();

    const csrftoken = getCookie('csrftoken');
    
    fetch('/api/v1/auth/password/reset_password/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({email: profile.email})
    }).then(res => res.json())
    .then(resdata => {
      console.log(resdata);
      setReset(true);
    });

      
  };

  return (
    <Pane>
      <Pane display="flex"  justifyContent="space-between" alignItems="flex-end" marginBottom={16}>
        <Paragraph size={400} fontWeight="bold" color="#5e5e5e" >ACCOUNT SETTINGS</Paragraph>

        {profile.editing ?
          (
            <Pane marginLeft={32} display="flex">
              <Button
                intent="success"
                onClick={e => {
                  updatePublisherUser(
                    {
                      variables: {
                        id: parseInt(props.publisheruserSet[0].id),
                        changed: JSON.stringify([
                          { key: "firstName", val: fname },
                          { key: "lastName", val: lname },
                        ])
                      }
                    });
                  dispatch(setprofile({ editing: false }))
                  window.location.reload();

                }}
              >
                Save Changes
              </Button>
              <Button
                intent="danger"
                marginLeft={16}
                onClick={e => dispatch(setprofile({ editing: false }))}
              >
                Cancel
              </Button>
            </Pane>
          ) : (<Pane marginLeft={8} display="flex">
            <Button onClick={e => dispatch(setprofile({ editing: true }))}>Edit</Button>
            <Button marginLeft={8} onClick={e => handlePasswordReset(e)}>Reset Password</Button>
            {reset && (
              <Paragraph>Reset sent. Please check your email!</Paragraph>
            )}
          </Pane>)
        }

      </Pane>
      <Pane display="flex">
        <Pane>
          <Paragraph size={300} color="grey">First Name</Paragraph>
          <TextInputField
            label=""
            inputWidth={120}
            onChange={e => setFname(e.target.value)}
            value={fname ? fname : ""}
            disabled={!props.editing}
          />
        </Pane>
        <Pane marginLeft={16}>
          <Paragraph size={300} color="grey">Last Name</Paragraph>
          <TextInputField
            label=""
            inputWidth={120}
            onChange={e => setLname(e.target.value)}
            value={lname ? lname : ''}
            disabled={!props.editing}
          />
        </Pane>
      </Pane>
      <Pane>
        <Paragraph size={300} color="grey">Email</Paragraph>
        <TextInputField
          inputWidth={200}
          value={profile.email}
          disabled={true}
          label=''
        />
      </Pane>
      <Paragraph size={300} color="grey">Profile Picture</Paragraph>
      {props.editing ? (
        <Pane>
          <Paragraph size={300} color="grey">Current Picture:</Paragraph>
          <CloudImage path={profile.publisheruserSet ? profile.publisheruserSet[0].photoUrl : false} customClass="" />
          <ImageUpload handleImageUpload={setUserImage} pathType="users" itemId={profile.id} />
        </Pane>
        
      ) : (
        <CloudImage path={profile.publisheruserSet ? profile.publisheruserSet[0].photoUrl : false} customClass="" />
      )}

    </Pane>
  )
}

export default EditAccountForm;