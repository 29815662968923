import React, { useCallback } from "react";
import { useSelector } from 'react-redux';
import { useDropzone } from "react-dropzone";
import { storage } from "../firebase_setup/firebase";
import { ref, uploadBytes } from "firebase/storage";


import {
  Pane,
  CloudUploadIcon,
  toaster,
  Paragraph,
  Link,
} from 'evergreen-ui';

function ImageUpload(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);

  const slugify = text => {
    let reg_ex = /[^\w\s-]/g;
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(reg_ex, "")
      .replace(/[\s_-]+/g, "_")
      .replace(/^-+|-+$/g, "");
  }

  const imageDrop = async (file) => {
    let { name } = file;
    let [filename, ext] = name.split(".");
    filename = slugify(filename, true);
    name = filename + "." + ext;
    toaster.notify("Uploading image", { duration: 60 });
    const path = `local/${currentpublisher.id}/${props.pathType}/${props.itemId}/${name}`;
    // const path = `${currentpublisher.id}/${props.pathType}/${props.itemId}/${name}`;
    // const csrftoken = getCookie('csrftoken');
    const storageRef = ref(storage, path);

    try {
      const res = await uploadBytes(storageRef, file)
      console.log(res)
      props.handleImageUpload(path);
      toaster.closeAll();
      toaster.success("Image Upload Complete!");
    } catch (error) {
      const errorCode = error.code;
          const errorMessage = error.message;
          toaster.closeAll();
          toaster.danger("Error uploading image. Try again or contact developer.");
          console.log(errorCode, errorMessage)
    }
    
  }

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    acceptedFiles.forEach(file => {
      let name = file.name.toLowerCase();
      if (
        name.endsWith(".jpg") ||
        name.endsWith(".jpeg") ||
        name.endsWith(".png")
      ) {
        imageDrop(file);
      } else {
        toaster.warning("You can only upload JPG, JPEG, and PNG image files.");
      }
    });
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Pane {...getRootProps()} className="file-uploader">
      <input {...getInputProps()} />
      {
        isDragActive ?
          <Pane size={400}><CloudUploadIcon
            size={24}
            marginRight={8}
            color={"var(--robinegg)"}
          />
          <Paragraph size={300}>{" "}Drop file here ...</Paragraph>
          </Pane> :
          <Pane display="flex" flexDirection="column" alignItems="center"><CloudUploadIcon
            size={24}
            color={"var(--robinegg)"}
          />
          <Paragraph size={400} color={"var(--darkgrey)"}>{" "}<Link>Browse</Link> or drag a file here</Paragraph>
          </Pane>
      }
    </Pane>
  )
}

export default ImageUpload;