import React from "react";
import {Link} from 'react-router-dom';


import {
  Pane,
  Paragraph,
  ChevronLeftIcon
} from 'evergreen-ui';

function Breadcrumbs(props) {

  return (
    <Pane display="flex" alignItems="center">
      <ChevronLeftIcon color="#606c5a" size={12} marginRight={4}/>
      <Paragraph size={300}>
        <Link  to={props.sourceUrl}> {props.singlePage ? props.singlePage : props.pageLabel+"S"} </Link>
      </Paragraph>
      {props.curPage && <Paragraph size={400} marginLeft={4} color="gray">/{" "}{props.pageLabel} {props.curPage}
      </Paragraph>}
      {props.seeAll && <Paragraph marginLeft={4} size={300}> &gt;&gt; <Link to={props.seeAll} onClick={() => window.location.reload()}> SEE ALL ORDERS</Link></Paragraph>}

    </Pane>

  )
}

export default Breadcrumbs;