import React, { useState, useEffect, useCallback } from "react";
import { useLocation, } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { commaSepThou, getCookie } from '../../util.js';

// ------- QUERIES
import QUERY_CREATORS from "../../graphql/queries/Creators/QueryCreators.js";

// ------- COMPONENTS
import LandingPage from "../../components/layout/LandingPage.js";
import NewCreator from "./NewCreator.js";
import CsvUpload from "../../components/layout/CsvUpload.js";

import {
  Pane,
  AddIcon,
  SideSheet,
  DocumentShareIcon
} from 'evergreen-ui';
import CloudImage from "../../components/CloudImage.js";

function Creators() {

  // ------- STATES
  const location = useLocation();
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const [creators, setCreators] = useState([]);
  const [count, setCount] = useState(0);
  const [pagecount, setPagecount] = useState(0);
  const [currpage, setCurrpage] = useState(1);
  const [newmode, setNewmode] = useState(false);
  const [csvmode, setCsvmode] = useState(false);
  const [params, setParams] = useState({});
  const [sourcePageInfo, setSourcePageInfo] = useState(false);

  // ------- HOOKS

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_CREATORS, {
    variables: { publisher: parseInt(currentpublisher.id), page: parseInt(currpage), params: JSON.stringify(params) },
  });

  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  useEffect(() => {
    if (data) {
      setCreators(data.creators);
      const csrftoken = getCookie('csrftoken');
      fetch('/count_creators/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body: JSON.stringify({ publisher: currentpublisher.id })
      })
        .then(res => res.json())
        .then(data => {
          setCount(parseInt(data.message));
          setPagecount(Math.ceil(data.message / 25));
        })
    }
  }, [currentpublisher.id, data]);

  useEffect(() => {
    if (location.state) {
      let locState = location.state;
      setSourcePageInfo(locState)
    }
  }, [location.state]);

  // ------- FUNCTIONS

  const handleRefetchCreators = () => {
    setParams({});
    refetch();
  }

  const handleToggleNewMode = () => {
    setNewmode(!newmode);
  }

  const handleToggleCsvMode = () => {
    setCsvmode(!csvmode);
    refetch();
  }

  // ------- INPUTS
  const buttons = [
    { title: "NEW CREATOR", icon: AddIcon, onClick: setNewmode },
    { title: "IMPORT CREATORS", icon: DocumentShareIcon, onClick: setCsvmode },

  ];

  const cols = [
    { title: "DISPLAY NAME", type: "button", flex: "3", query: "display_name" },
    { title: "PAYMENT NAME", type: "button", flex: "3", query: "payment_name" },
    { title: "EMAIL", type: "default", flex: "4" },
    { title: "PHONE", type: "default", flex: "3" },
    { title: "BALANCE", type: "default", flex: "2", align:"right" },
    { title: "", type: "default", flex: "1" },


  ]

  const searchInit = {
    id: "",
    payment_name: "",
    display_name: ""
  }

  const searchInputs = {
    id: { searchq: 'id', dropLabel: 'By ID' },
    payment_name: { searchq: 'payment_name', dropLabel: 'By Payment Name' },
    display_name: { searchq: 'display_name', dropLabel: 'By Display Name' },
  }

  const filterInputs = {
  }

  const csvFields = [
    "biography",
    "created",
    "displayName",
    "id",
    "paymentName",
    "residence",
    "creatoremailSet",
    "creatorphonenumberSet",
    "creatoraddressSet",
    "paymentType"
  ];

  const csvConfig = {
    "paymentType": ["value"],
    "sets": {},
    "primarySets": {
      "creatoremailSet": {
        "fields": ["value"],
        "subfields": {},
        "label": "emails"
      },
      "creatorphonenumberSet": {
        "fields": ["value"],
        "subfields": {},
        "label": "phoneNumbers"
      },
      "creatoraddressSet": {
        "fields": ["line1", "line2", "city", "stateOrRegion", "country", "zipcode"],
        "subfields": {},
        "label": "addresses"
      }

    },
    "arrays": {}
  }

  const csvTemplate = [
    [
      "display_name",
      "payment_name",
      "residence",
      "payment_type",
      "biography",
      "email",
      "phone",
      "address_line1",
      "address_line2",
      "address_city",
      "address_region",
      "address_zipcode",
      "address_country"
    ],
    [
      "Creator's display name in Working Lit - must be unique!",
      "Creator's payment name (e.g. for sending mail)",
      "",
      "The prefered payment type as it exists in Working Lit",
      "Author bio (CANNNOT HAVE COMMAS)",
      "Primary email",
      "Primary phone number",
      "Street address",
      "Street address - line 2",
      "City",
      "State or Region",
      "Zipcode",
      "Country"

    ],
    [
      "bell hooks",
      "Gloria Jean Watkins",
      "Kentucky",
      "Check",
      "bell hooks was an American author and theorist who worked as a Distinguished Professor in Residence at Berea College. She is best known for her writings on race, feminism, and class.",
      "bellhooks@imaginarydomain.org",
      "1-234-567-8989",
      "37 Book Rd",
      "",
      "Berea",
      "KY",
      "40403",
      "USA"
    ]
  ];

  const csvWidths = [
    3,
    3,
    2,
    2,
    4,
    3,
    2,
    3,
    2,
    2,
    2,
    2,
    3
  ]

  if (error) return `Error! ${error}`;

  return (
    <Pane position="absolute" padding={16} width="100%" height="100%">
      {csvmode ? (
        <CsvUpload handleToggleCsvMode={handleToggleCsvMode} template={csvTemplate} itemType="creator" tableWidth={2000} colWidths={csvWidths} />
      ) : (
        <Pane>
          <SideSheet isShown={newmode} onCloseComplete={() => setNewmode(false)} className="background">
            <NewCreator
              handleToggleNewMode={handleToggleNewMode}
              handleRefetchCreators={handleRefetchCreators}
              publisher={currentpublisher.id}
            />
          </SideSheet>

          <LandingPage
            title="Creators"
            location={location}
            sourcePageInfo={sourcePageInfo}
            data={creators}
            loading={loading}
            buttons={buttons}
            count={count}
            pagecount={pagecount}
            searchInputs={searchInputs}
            filterInputs={filterInputs}
            searchInit={searchInit}
            csvConfig={csvConfig}
            csvFields={csvFields}
            csvType="Creators"
            cols={cols}
            refetch={refetch}
            setParams={setParams}
            RowComponent={CreatorRow}
            currpage={currpage}
            setCurrpage={setCurrpage}
          />
        </Pane>)}

    </Pane>
  )
}

function CreatorRow({ item, allmode, handleCheck, colWidths, makeIDCell, makeTableCellArray }) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

  let balance = 0;
  item.royaltySet.forEach(royalty => balance = balance + parseFloat(royalty.owed) - parseFloat(royalty.paid))
  const balNeg = balance < 0
  const balAbs = commaSepThou(Math.abs(balance).toFixed(2))



  const [checked, setChecked] = useState(false);

  const handleSetChecked = () => {
    handleCheck(checked, item)
    setChecked(!checked);
  }

  const email = item.creatoremailSet.filter(item => item.primary === true);
  const backup_email = item.creatoremailSet.length > 0
    ? item.creatoremailSet[0].value
    : "No email available";

  const phone = item.creatorphonenumberSet.filter(item => item.primary === true);
  const backup_phone = item.creatorphonenumberSet.length > 0
    ? item.creatorphonenumberSet[0].value
    : "No phone number available";

  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  const rowCells = [
    {
      content: makeIDCell(checked, handleSetChecked, item.id), disableSelect: true
    },

    {
      content: <Pane display="flex" alignItems="center">
        <CloudImage path={item.photoUrl ? item.photoUrl : ""} avatar="true" size={36} marginY={8} name={item.displayName} />
        <Pane marginLeft={8}>{item.displayName}</Pane></Pane>
    },
    { content: item.paymentName },
    { content: email.length > 0 ? email[0].value : backup_email, disableSelect: true },
    { content: phone.length > 0 ? phone[0].value : backup_phone, disableSelect: true },
    { isNumber: true, content: <Pane>{balNeg && "-"}{currency}{balAbs}</Pane> },
    { content: "" },


  ]

  useEffect(() => {
    setChecked(allmode);
  }, [allmode]);
  const cellArray = makeTableCellArray(rowCells, colWidths, item.id)

  return cellArray
}

export default Creators;
