// Item in Statement Info when generating Royalty Statement
import React from "react";
import { commaSepThou, makeAdjustedString } from '../../../util.js';
import JSON5 from 'json5';
import Threshold from "../Threshold.js";


import {
  Pane,
  Paragraph,
} from 'evergreen-ui';



const RoyaltyStatementLineItem = props => {

  const adjustedStatementString = makeAdjustedString(props.item.statementInfo)
  const statementInfo = JSON5.parse(adjustedStatementString)
  const tiers = props.item.royaltylineitemSet.map(item => {
    let adjusted = makeAdjustedString(item);
    return JSON5.parse(adjusted);
  });

  const listTier = tiers.some(t => t.royaltyTerm.id === '1')
  const receiptTier = tiers.some(t => t.royaltyTerm.id === '2')
  const netTier = tiers.some(t => t.royaltyTerm.id === '3')
  const trueTier = tiers.some(t => t.royaltyTerm.id === '4')

  const units = commaSepThou(statementInfo.units);
  const revenue = commaSepThou(statementInfo.revenue.toFixed(2));

  const expenses = commaSepThou(statementInfo.expenses.toFixed(2));
  const profit = commaSepThou(Math.abs(statementInfo.revenue - statementInfo.expenses).toFixed(2))
  const profNeg = statementInfo.expenses > statementInfo.revenue;

  const balance = commaSepThou(Math.abs(statementInfo.credit - statementInfo.debit).toFixed(2));
  const balNeg = statementInfo.debit > statementInfo.credit;

  const adjustedProduct = makeAdjustedString(props.item.product);
  const product = JSON5.parse(adjustedProduct);

  // get lifetime totals for this product
  const lifetimeUnits = commaSepThou(statementInfo.lifeUnits);
  const lifetimeRevenue = commaSepThou(statementInfo.lifeRevenue.toFixed(2));
  const lifetimeExpenses = commaSepThou(statementInfo.lifeExpenses.toFixed(2));
  const profNegLife = statementInfo.lifeExpenses > statementInfo.lifeRevenue

  const lifetimeProfit = commaSepThou(Math.abs(statementInfo.lifeRevenue - statementInfo.lifeExpenses).toFixed(2))
  const lifetimeBalance = commaSepThou(Math.abs(statementInfo.lifeCredit - statementInfo.lifeDebit).toFixed(2))
  const currentBalance = commaSepThou(Math.abs(statementInfo.lifeCredit - statementInfo.lifeDebit - parseFloat(props.item.paid)).toFixed(2))
  const balNegLife = statementInfo.lifeDebit > statementInfo.lifeCredit
  const balNegCur = (statementInfo.lifeDebit + parseFloat(props.item.paid)) > statementInfo.lifeCredit

  const currency = props.publisherGlobalData.currency
    ? props.publisherGlobalData.currency.symbol
    : "$";

  const headStyle = { padding: "8px" }
  const prodStyle = { padding: "8px", verticalAlign: "top" }

  const expenseSet = product.expenseSet.filter(exp =>
    (Date.parse(exp.expenseDate) <= Date.parse(props.endDate) && Date.parse(exp.expenseDate) > Date.parse(props.startDate)))
    .sort((a, b) => Date.parse(a.expenseDate) - Date.parse(b.expenseDate))


  return (
    <Pane key={product.id} marginTop={16}>
      <Paragraph size={300}>-------------------------------------------------------------</Paragraph>
      <Paragraph size={400} ><b>PRODUCT {props.idx}: {product.title}</b></Paragraph>
      <Pane marginLeft={16}>
        <table padding="16px" style={{
          color: "#474d66",
          fontSize: "13px",
          textAlign: "left",
          borderCollapse: "collapse",
        }}>
          <colgroup>
          </colgroup>
          <thead>
            <tr height={32} style={{
              backgroundColor: "grey", color: "white", borderTop: "1px solid #E6E8F0", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0"
            }}>
              <th style={headStyle}>PRODUCT DETAILS</th>
              <th style={headStyle}>BALANCE</th>
              <th style={headStyle}>ROYALTY TERMS</th>
            </tr>
          </thead>
          <tbody>
            <tr height={32} style={{ backgroundColor: "white", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
              <td style={prodStyle}>
                <Paragraph size={300}>{product.title}</Paragraph>
                <Paragraph size={300}>({product.format ? product.format.description : "No Format"})</Paragraph>
                <Paragraph size={300}>ISBN {product.isbn}</Paragraph>
              </td>

              <td style={prodStyle}>
                <b> {balNegCur ? "-" : ""}{currency}{currentBalance}</b>
                {/* {listTier && <Paragraph marginLeft={10} size={300}><b>% of list price:</b> % of title's retail price, no matter how much the publisher earns</Paragraph>}
            {receiptTier && <Paragraph marginLeft={10} size={300}><b>% of net receipts:</b> % of publisher's income for title, not taking into account expenses</Paragraph>}
            {netTier && <Paragraph marginLeft={10} size={300}><b>% of net profits:</b> % of title's sales minus expenses</Paragraph>}
            {trueTier && <Paragraph marginLeft={10} size={300}><b>% of true profits/profit sharing:</b> net profits minus publisher's bottom line percentage</Paragraph>} */}
              </td>
              <td style={prodStyle}>
                <Threshold marginLeft={12} royaltylineitemSet={tiers} />
              </td>

            </tr>
          </tbody>
        </table>
        <Paragraph size={300} marginTop={16}><b>ROYALTY STATS</b></Paragraph>
        <table padding="4px" style={{
          color: "#474d66",
          fontSize: "12px",
          textAlign: "left",
          borderCollapse: "collapse"
        }}>
          <thead>
            <tr height={32} style={{ backgroundColor: "#F9FAFC", borderTop: "1px solid #E6E8F0", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
              <th style={headStyle}>STAT</th>
              <th style={headStyle}>PERIOD</th>
              <th style={headStyle}> LIFETIME</th>
            </tr>
          </thead>
          <tbody>

            <tr height={32} style={{ backgroundColor: "white", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
              <td style={headStyle}>Units Sold</td>
              <td style={headStyle}>{units}</td>
              <td style={headStyle}>{lifetimeUnits}</td>
            </tr>
            {(receiptTier || netTier || trueTier) &&
              <tr height={32} style={{ backgroundColor: "white", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
                <td style={headStyle}>Revenue</td>
                <td style={headStyle}>{currency}{revenue}</td>
                <td style={headStyle}>{currency}{lifetimeRevenue}</td>
              </tr>}
            {(netTier || trueTier) &&
              <tr height={32} style={{ backgroundColor: "white", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
                <td style={headStyle}>Expenses</td>
                <td style={headStyle}>{currency}{expenses}</td>
                <td style={headStyle}>{currency}{lifetimeExpenses}</td>
              </tr>
            }
            {(netTier || trueTier) &&
              <tr height={32} style={{ backgroundColor: "white", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
                <td style={headStyle}>Profits</td>
                <td style={headStyle}>{profNeg ? "-" : ""}{currency}{profit}</td>
                <td style={headStyle}>{profNegLife ? "-" : ""}{currency}{lifetimeProfit}</td>
              </tr>}
            <tr height={32} style={{ backgroundColor: "white", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
              <td style={headStyle}>Royalties</td>
              <td style={headStyle}>{balNeg ? "-" : ""}{currency}{balance}</td>
              <td style={headStyle}>{balNegLife ? "-" : ""}{currency}{lifetimeBalance}</td>
            </tr>
          </tbody>
        </table>
        {(netTier || trueTier) &&
          <Pane marginTop={16}>
            <Paragraph marginTop={8} size={300}><b>ITEMIZED EXPENSES FOR PERIOD</b></Paragraph>
            <table padding="4px" style={{
              color: "#474d66",
              fontSize: "12px",
              textAlign: "left",
              verticalAlign: "top",
              borderCollapse: "collapse"
            }}>
              <colgroup>
              </colgroup>
              <thead>
                <tr height={32} style={{ backgroundColor: "#F9FAFC", borderTop: "1px solid #E6E8F0", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
                  <th style={headStyle}> EXPENSE DATE </th>
                  <th style={headStyle}>AMOUNT</th>
                  <th style={headStyle}>DESCRIPTION</th>
                </tr></thead>
              <tbody>
                {expenseSet.length ? expenseSet.map(expense => {
                  return (
                    <tr height={24} style={{ backgroundColor: "white", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
                      <td style={headStyle}>
                        {expense.expenseDate ? expense.expenseDate : "(No Date)"}
                      </td>
                      <td style={headStyle}>
                        {currency}{parseFloat(expense.amount).toFixed(2)}
                      </td>
                      <td style={headStyle}>
                        <Paragraph size={300}>{expense.adminNotes ? expense.adminNotes : ""}</Paragraph>
                        {expense.category?.value ? <Paragraph size={300}>(expense.category.value)</Paragraph> : ""}
                      </td>
                    </tr>
                  )
                }) : <tr height={32} style={{ backgroundColor: "#F9FAFC", borderTop: "1px solid #E6E8F0", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
                  <td style={headStyle}> (No expenses in statement period) </td>

                </tr>}

              </tbody>
            </table>

          </Pane>
        }
        {props.item.royaltypaymentSet.length ?
          <Pane marginTop={16}>
            <Paragraph size={300}><b>PAYMENT HISTORY</b></Paragraph>
            <table padding="4px" style={{
              color: "#474d66",
              fontSize: "12px",
              textAlign: "left",
              verticalAlign: "top",
              borderCollapse: "collapse"
            }}>
              <colgroup>
              </colgroup>
              <thead>
                <tr height={32} style={{ backgroundColor: "#F9FAFC", borderTop: "1px solid #E6E8F0", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
                  <th style={headStyle}>PAYMENT DATE</th>
                  <th style={headStyle}>AMOUNT</th>
                  <th style={headStyle}>METHOD</th>
                </tr></thead>
              <tbody>

                {props.item.royaltypaymentSet.map(payment => {
                  const adjustedPayment = makeAdjustedString(payment);
                  const paymentObj = JSON5.parse(adjustedPayment);
                  const method = paymentObj.method ? paymentObj.method.value : "(No method recorded)";
                  return (
                    <tr height={24} style={{ backgroundColor: "white", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
                      <td style={headStyle}>
                        {paymentObj.sentDate}                    </td>
                      <td style={headStyle}>
                        {currency}{parseFloat(paymentObj.amount).toFixed(2)}                    </td>
                      <td style={headStyle}>
                        {method}
                      </td>
                    </tr>
                  )
                })}
                <tr height={24} style={{ backgroundColor: "#F9FAFC", borderBottom: "1px solid #E6E8F0", borderLeft: "1px solid #E6E8F0", borderRight: "1px solid #E6E8F0" }}>
                  <td style={headStyle}>
                    TOTAL                 </td>
                  <td style={headStyle}>
                    {currency}{parseFloat(props.item.paid).toFixed(2)}                    </td>
                  <td style={headStyle}>
                  </td>
                </tr>

              </tbody>
            </table>
          </Pane> :
          <Paragraph marginTop={16} size={300}>You haven't yet received any payments for this royalty.</Paragraph>


        }
      </Pane> </Pane>
  )
}


export default RoyaltyStatementLineItem;  
