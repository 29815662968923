export const baseRoyaltyPaymentObject = () => {
  return {
    id: "",
    amount: "",
    sentDate: "",
    method: {
      id: "",
      value: ""
    },
    royalty: {
      id: "",
      product: {
        id: "",
        title: "",
        format: {
          id: "",
          value: "",
          description: ""
        },
      },
      creator: {
        id: "",
        displayName: "",
        paymentName: "",
        paymentType: {
          id: "",
          value: ""
        }
      },
      locked: "",
      lastEditor: {
        id: "",
        email: ""
      },
      created: "",
      modified: "",
    }
  }
}
