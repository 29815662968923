import { gql } from '@apollo/client';

const QUERY_CREATORS = gql`
  query Creators($publisher: Int!, $page: Int, $params: String) {
    creators(publisher: $publisher, page: $page, params: $params) {
      id,
      created,
      displayName,
      paymentName,
      photoUrl,
      residence,
      paymentType {
        id,
        value
      },
      biography,
      creatoremailSet {
        id,
        value,
        primary
      }
      creatorphonenumberSet {
        id,
        value,
        primary
      }
      creatoraddressSet {
        id,
        line1,
        line2,
        city,
        stateOrRegion,
        country,
        zipcode,
        primary
      }
      royaltystatementSet {
        id,
        prodArray,
        sentDate
      }
      royaltySet{
        id,
        owed,
        paid,
        product {
          id,
          frozen
        }
      }
      productcreatorSet {
        product {
          id,
          title,
          frozen,
          format {
            id,
            value,
            description
          }
        }
      }
    }
  }
`;

export default QUERY_CREATORS;