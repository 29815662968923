import React, { useState } from "react";
import { useSelector } from 'react-redux';
import JSON5 from 'json5';

// ------- COMPONENTS
import RoyaltyStatementLineItem from "../Royalties/statements/RoyaltyStatementLineItem";
import RoyaltyStatementSummary from "../Royalties/statements/RoyaltyStatementSummary";

import {
  Pane,
  Button,
  Table,
  Dialog,
  Paragraph,
} from 'evergreen-ui';

const StatementTable = props => {
  return (
    <Pane display="flex" flexDirection="column" width="100%">
      {props.royaltystatementSet && (
        <Table.Body width="100%">
          <Table.Head height={40} className="table-heading">
            <Table.TextCell flex={1}>ID</Table.TextCell>
            <Table.TextCell flex={2}>Created</Table.TextCell>
            <Table.TextCell flex={3}>Statement Period</Table.TextCell>
            <Table.TextCell flex={4}>Note to Creator</Table.TextCell>
            <Table.TextCell flex={2}> Summary </Table.TextCell>
            <Table.TextCell flex={2}> Details </Table.TextCell>
          </Table.Head>
          <Pane  maxHeight="calc(100vh - 250px)" overflow="auto">
          {props.royaltystatementSet.sort((a,b)=>Date.parse(b.sentDate) - Date.parse(a.sentDate)).map((item, index) => {
            return (
              <StatementTableRow key={item.id+item.sentDate} item={item} index={index} />
            )
          })}
          </Pane>
          
        </Table.Body>
      )}
    </Pane>
  );
}



const StatementTableRow = props => {
  const startDate = props.item.startDate
  const endDate = props.item.endDate
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [showSummary, setShowSummary] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  let prodArray = [];
  let useLineitemComp = false;

  try {
    prodArray = props.item.prodArray.split("\n")
  } catch (error) {
    console.log(error)
  }

  try {
    prodArray = JSON5.parse(props.item.prodArray)
    useLineitemComp=true;
  } catch (error) {
    console.log(error)
  }

  const rowColor = props.index % 2 === 0 ? "white" : "var(--offwhite)"
  const currency = publisherGlobalData.currency
  ? publisherGlobalData.currency.symbol
  : "$";

  return (
    <Pane>
      <Dialog
        isShown={showSummary}
        title="Statement Summary"
        onCloseComplete={() => setShowSummary(false)}
        confirmLabel="Close"
        onConfirm={(close) => close()}
        hasCancel={false}
        intent="none"
        width="60%"
      >
        <Pane>

          {useLineitemComp ?
            <RoyaltyStatementSummary startDate={startDate} endDate={endDate} items={prodArray} currency={currency} />
            :
            <Paragraph>No summary available, statement generated using older version of WL.</Paragraph>
          }
        </Pane>
      </Dialog>
      <Dialog
        isShown={showDetail}
        title="Statement Detail"
        onCloseComplete={() => setShowDetail(false)}
        confirmLabel="Close"
        onConfirm={(close) => close()}
        hasCancel={false}
        intent="none"
        width="60%"
      >
        <Pane>

          {useLineitemComp ?
            prodArray.map((item, index) => {
              return (RoyaltyStatementLineItem({
                key: "statement" + item.id,
                item: item,
                publisherGlobalData: publisherGlobalData,
                prodTotal: prodArray.length,
                idx: index + 1,
                startDate: props.item.startDate,
                endDate: props.item.endDate

              }))
            }) :
            prodArray.map((line, index) => {
              return (
                <div key={props.item.id + props.item.sentDate + index}
                  className="Container" dangerouslySetInnerHTML={{ __html: line }}></div>)
            })
          }
        </Pane>
      </Dialog>
      <Table.Row className="table-row" value="" backgroundColor={rowColor}>
        <Table.TextCell flex={1}>
          {props.item.id}
        </Table.TextCell>
        <Table.TextCell isNumber flex={2}>
          {props.item.sentDate}
        </Table.TextCell>
        <Table.TextCell flex={3} isNumber>
          {props.item.startDate} - {props.item.endDate}
        </Table.TextCell>
        <Table.TextCell flex={4}>
          {props.item.pubNote}
        </Table.TextCell>
        <Table.TextCell flex={2}>
          <Button onClick={() => setShowSummary(true)} disabled={useLineitemComp ? false : true}>
            {useLineitemComp ?
              `${props.item.products.length} Products`:
              "Not Available"
            }
          </Button>
        </Table.TextCell>

        <Table.TextCell flex={2}>
          <Button onClick={() => setShowDetail(true)}>View Details</Button>
        </Table.TextCell>
      </Table.Row>
    </Pane>
  );
}

export default StatementTable;
