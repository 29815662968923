import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Card, CrossIcon, HelpIcon, IconButton, Pane, Paragraph, Tooltip } from "evergreen-ui";

export function FrozenTooltip({ parentItem, items }) {
    const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

    if (publisherGlobalData.plan === "All Access Plan") {
        return <Pane></Pane>
    }
    else if (!publisherGlobalData.plan || parseInt(publisherGlobalData.numproducts) >= parseInt(publisherGlobalData.maxProducts)) {
        return (
            <Card padding={8}>
                <Paragraph size={400} color="white">
                    Publishers on the {publisherGlobalData.plan ? publisherGlobalData.plan : "Free Plan"} can only create or edit {parentItem} for {items !== "products" ? `${items} associated with` : ""} the <b>first {publisherGlobalData.maxProducts ? publisherGlobalData.maxProducts : "10"} products</b> created in WorkingLit.
                    To add more products or use products created during your free trial, visit your <Link to="/publisher" className="dark-link">publisher settings</Link> to explore other plans.
                </Paragraph>
            </Card>)

    }

    else {
        return <Pane></Pane>
    }
}

export function NotSeeingItems({ headerHeight, items, parentItem, close }) {
    const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

    if (publisherGlobalData.plan === "All Access Plan") {
        return <Pane></Pane>
    }

    else if (!publisherGlobalData.plan || parseInt(publisherGlobalData.numproducts) >= parseInt(publisherGlobalData.maxProducts)) {
        return (
            <Pane
                display="flex"
                alignItems="center"
                borderBottom="default"
                padding={8}
                height={headerHeight}
                boxSizing="border-box"
            >
                <Pane flex="1" display="flex" alignItems="center">
                    <Paragraph color="grey" size={300}>Not seeing all your {items}?</Paragraph>
                    <Tooltip content={<FrozenTooltip parentItem={parentItem} items={items} />}>
                        <HelpIcon size={12} marginLeft={4} color="muted" />
                    </Tooltip>
                </Pane>
                {close ? <IconButton icon={CrossIcon} appearance="minimal" height={24} onClick={close} /> : ""}
            </Pane>
        )
    } else { return <Pane></Pane> }
}

