import { gql } from '@apollo/client';

const DELETE_PHONE_MUTATION = gql`
  mutation DeletePhoneMutation(
    $id: Int!,
    $contactType: String!
  ) {
    deletePhone(
      id: $id,
      contactType: $contactType
    ) {
      message
    }
  }
`;

export default DELETE_PHONE_MUTATION;