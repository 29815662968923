import { gql } from '@apollo/client';

const QUERY_EXPENSES = gql`
  query Expenses($publisher: Int!, $page: Int, $params: String) {
    expenses(publisher: $publisher, page: $page, params: $params) {
      id,
      product {
        id,
        title,
        format {
          id,
          value,
          description
        },
        isbn
      },
      category {
        id,
        value
      },
      adminNotes,
      amount,
      expenseDate,
      locked,
      lastEditor {
        id,
        email
      },
      created,
      modified,
    }
  }
`;

export default QUERY_EXPENSES;