import React, { useState, useEffect } from "react";

import NewContactInfoForm from "./NewContactInfoForm";

import {
  Pane,
  Paragraph,
  Heading,
  Button,
  TextInput,
  Checkbox,
  Badge,
  InboxIcon,
  Card,
  PhoneIcon,
  IconButton,
  TrashIcon,
  Popover,

} from 'evergreen-ui';

function SingleItemWithPrimary(props) {
  const [idCount, setIdCount] = useState(1)
  const [itemSet, setItemSet] = useState(props.itemSet)

  const handleChange = obj => {
    props.handleUpdateStagedSet(obj, props.fieldtype);
  }

  const handleNewItem = obj => {
    obj.tmpid = idCount
    setIdCount(idCount + 1)
    handleChange(obj);
  }

  const handleDelete = item => {
    props.handleDeleteItem(item, props.fieldtype)
  }

  useEffect(() => { setItemSet(props.itemSet) }, [props.itemSet])


  return (
    <Pane marginBottom={16}>
      <Pane display="flex" justifyContent="space-between" alignItems="center" marginBottom={8}>
        <Heading size={300} color="#5e5e5e" >{props.title}</Heading>
        {props.editing &&
          <Popover content={({ close }) => (
            <NewContactInfoForm handleNewItem={handleNewItem} fieldtype={props.fieldtype} close={close} />
          )}>
            <Button size="small" marginLeft={8}> Add New {props.fieldtype}</Button>
          </Popover>
        }
      </Pane>
      <Pane >
        {props.editing && <Badge marginBottom={4} color="green">PRIMARY?</Badge>}
        {itemSet && itemSet.length > 0 ? (
          itemSet.sort((a, b) => {
            if (props.editing) {
              if (a.id === b.id){
                return b.tmpid-a.tmpid
              } else {
                return b.id - a.id
              }
            } else {
              return b.primary - a.primary
            }
          }).map(item => {
            return (
              <ItemField
                key={`${props.fieldtype}${item.id}${item.primary}${item.tmpid}`}
                editing={props.editing}
                item={item}
                handleDelete={handleDelete}
                handleChange={handleChange}
                cancelled={props.cancelled}
                fieldtype={props.fieldtype}
              />
            )
          })
        ) : (
          <Paragraph size={300} color="var(--robinegg)"> (None Associated With Account)</Paragraph>
        )
        }
      </Pane>
    </Pane>
  );
}

function ItemField(props) {
  const [staged, setStaged] = useState(props.item.value);
  const isPrimary = props.item.primary && props.item.primary === true;
  const iconColor = isPrimary ? "success" : "muted"

  useEffect(() => {
    if (props.cancelled === true) {
      setStaged(props.item.value);
    }
  }, [props.cancelled]);

  return (
    <Pane paddingBottom={8} marginBottom={4} width="100%" display="flex">
      {props.editing && (
        <Checkbox
          label=""
          marginY={0}
          marginTop={8}
          marginX={32}
          checked={isPrimary}
          onChange={e => {
            let checkData = props.item.tmpid ?
            { id: props.item.id, value: staged, primary: !isPrimary, tmpid: props.item.tmpid }
            : { id: props.item.id, value: staged, primary: !isPrimary};
            props.handleChange(checkData);
          }}
        />
      )}
      {props.editing ? <TextInput
        className="item-form"
        onChange={e => {
          setStaged(e.target.value);
          let textData = props.item.tmpid ?
          { id: props.item.id, value: e.target.value, primary: isPrimary, tmpid: props.item.tmpid }
          : { id: props.item.id, value: e.target.value, primary: isPrimary};
          props.handleChange(textData);
        }}
        value={staged}
        disabled={props.editing ? false : true}
        width={props.editing ? 200 : 250}
      /> : <Card elevation={1} paddingX={8} paddingY={8} display="flex" justifyContent="space-between" alignItems="center" border="default" width="100%">
        <Pane display="flex" alignItems="center">
          {props.fieldtype === "Email" ? <InboxIcon color={iconColor} /> : <PhoneIcon color={iconColor} />}
          <Paragraph marginLeft={8} size={300}> {staged} </Paragraph>
        </Pane>  {isPrimary && (
          <Paragraph size={300} marginLeft={8} color="var(--robinegg)">(Primary)</Paragraph>
        )} </Card>}

      <Pane>



      </Pane>
      {props.editing && (
        <IconButton icon={TrashIcon} intent="danger" onClick={e => props.handleDelete(props.item)} marginLeft={8} />
      )}
    </Pane>
  )
}

export default SingleItemWithPrimary;