import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { getCookie } from '../../util.js';

import {
  Pane,
  Paragraph,
  Button,
  TextInput,
  toaster,
} from 'evergreen-ui';

function GroupUserInvite(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const [email, setEmail] = useState("");

  const handleInviteUser = () => {
    console.log(email, currentpublisher.id)
    const csrftoken = getCookie('csrftoken');

    fetch('/send_invitation/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({"email": email, "publisher": currentpublisher.id})
    }).then(res => res.json())
      .then(res => {
        if (res.status === 200) {
          toaster.success('Invitation sent to ' + email);
          setEmail("");
          props.addNewInvitation(email, res.key);
        } else {
          toaster.danger('There was an error sending your invitation');
          setEmail("");
        }
      });
  }

  return (
    <Pane marginTop={32}>
      {/*<Paragraph size={300} fontWeight="bold" color="#5e5e5e" >INVITE NEW COLLABORATOR</Paragraph>
      <Pane display="flex" flexDirection="row" justifyContent="flex-start" marginTop={16}>
        <TextInput
          onChange={e => setEmail(e.target.value)}
          value={email}
          width={300}
          marginRight={16}
        />
        <Button intent="success" marginRight={16} onClick={e => handleInviteUser()}>Send Invitation</Button>
        <Button onClick={e => setEmail("")}>Cancel</Button>
      </Pane>*/}
    </Pane>
  )
}

export default GroupUserInvite;
