import React from 'react';

import {
  Box,
  Heading
} from 'grommet';

function NotFound() {
  return (
    <Box flex align='center' justify='center'>
      <Heading level='3'>Not Found</Heading>
      <p>The page you are looking for does not exist.</p>
    </Box>
  );
}

export default NotFound