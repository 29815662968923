import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { sanitizeRecord, getLocationQuery } from "../../util";
import PageHeading from "./PageHeading";
import SearchAdvanced from "./SearchAdvanced";
import SummaryTableHeading from "./SummaryTableHeading";
import PaginationDisplay from "./PaginationDisplay";
import { Pane, Card, Spinner, Paragraph, Table, Checkbox } from "evergreen-ui";

function LandingPage({
    title,
    location,
    sourcePageInfo = "", // Ensure a default string value
    data,
    loading,
    buttons,
    count,
    pagecount,
    searchInputs,
    filterInputs,
    searchInit,
    csvConfig,
    csvFields,
    csvType,
    cols,
    refetch,
    setParams,
    RowComponent,
    currpage,
    setCurrpage,
    cantCreateNew
}) {
    const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
    const navigate = useNavigate();

    const [allmode, setAllmode] = useState(false);
    const [selected, setSelected] = useState([]);
    const [excluded, setExcluded] = useState([]);
    const [searchq, setSearchq] = useState(searchInit);
    const [stagedparams, setStagedparams] = useState({});
    const [sortOrder, setSortOrder] = useState({ sort_order: null });
    const [sortDirection, setSortDirection] = useState({ sort_direction: null });
    const [filterDetails, setFilterDetails] = useState({});
    const [tableHeight, setTableHeight] = useState(0);

    const upperComps = useRef(null);
    const lowerComps = useRef(null);
    const otherComps = useRef(null);

    const handleChangeSearchParams = (obj) => {
        setSearchq((oldState) => ({ ...oldState, [obj.key]: obj.val }));
        setStagedparams((oldState) => ({ ...oldState, [obj.key]: obj.val }));
    };

    const handleSetChecked = (checked, item) => {
        if (allmode === true && checked === true) {
            const newitem = [item];
            setExcluded(excluded.concat(newitem));
        } else if (checked === true) {
            const newarr = selected.filter((obj) => obj.id !== item.id);
            setSelected(newarr);
        } else {
            const newitem = [sanitizeRecord(JSON.parse(JSON.stringify(item)))];
            if (newitem.length) {
                const newSelect = excluded.filter((val) => !newitem.find(({ id }) => val.id === id));
                setExcluded(newSelect);
                setSelected(selected.concat(newitem));
            } else {
                setSelected(selected.concat(newitem));
            }
        }
    };

    const makeTableCellArray = (cellArray, colWidths, itemId) => {
        return cellArray.map((cell, index) => (
            <Table.TextCell
                key={title + index}
                onClick={(e) => {
                    if (!cell.disableSelect) {
                        console.log(title);
                        navigate(`${itemId}`, { replace: true });
                    }
                }}
                flex={colWidths[index]}
                isNumber={cell.isNumber}
                textAlign={cell.isNumber ? "right" : "left"}
            >
                {cell.content}
            </Table.TextCell>
        ));
    };

    const makeIDCell = (checked, handleSetChecked, itemId) => {
        return (
            <Pane display="flex" flexDirection="row" alignItems="center">
                <Checkbox
                    marginLeft={8}
                    marginRight={8}
                    label=""
                    checked={checked}
                    onChange={(e) => handleSetChecked(e.target.checked, itemId)} // Pass checked state
                />
                {title !== "Royalty Payments" ? (
                    <Link to={`/${title.toLowerCase()}/${itemId}`}>{itemId}</Link>
                ) : (
                    itemId
                )}
            </Pane>
        );
    };

    useEffect(() => {
        if (location.state) {
            let locState = location.state;
            getLocationQuery(locState, setParams);
        }
    }, [location.state, setParams]);

    useEffect(() => {
        setParams((oldState) => ({ ...oldState, sort_direction: sortDirection.sort_direction, sort_order: sortOrder.sort_order }));
        refetch();
    }, [refetch, sortDirection, sortOrder, setParams]);

    useEffect(() => {
        if (cantCreateNew && otherComps) {
            setTableHeight(window.innerHeight - (upperComps.current.clientHeight + lowerComps.current.clientHeight) - 20);
        } else if (upperComps.current && lowerComps.current) {
            setTableHeight(window.innerHeight - (upperComps.current.clientHeight + lowerComps.current.clientHeight) - 20);
        }
    }, [cantCreateNew]);

    const handleRefetchItems = () => {
        setSearchq({ ...searchInit });
        setParams({ ...stagedparams });
        refetch();
    };

    const handleSearch = (params, searchStaged) => {
        setSearchq({ ...searchStaged });
        setStagedparams({ ...params });
        setParams({ ...params });
    };

    const handleClearStagedSearchParams = (staged) => {
        setSearchq({ ...searchInit }, handleClearParams());
    };

    const handleClearParams = () => {
        setParams({}, handleClearSearchFields());
    };

    const handleClearSearchFields = () => {
        setStagedparams({}, handleRefetchItems());
    };

    return (
        <Pane display="flex" flexDirection="column">
            <Pane ref={upperComps}>
                <PageHeading
                    title={title}
                    buttons={buttons}
                    plan={publisherGlobalData.plan}
                    maxProducts={publisherGlobalData.maxProducts}
                    sourcePageInfo={typeof sourcePageInfo === 'string' ? sourcePageInfo : ''} // Ensure it's a string
                    frozen={cantCreateNew}
                />
                <SearchAdvanced
                    searches={searchInputs}
                    filters={filterInputs}
                    staged={searchq}
                    handleSearch={handleSearch}
                    handleResetSearch={handleClearStagedSearchParams}
                    setFilterDetails={setFilterDetails}
                    filterDetails={filterDetails}
                />
                <SummaryTableHeading
                    setSelected={setSelected}
                    setExcluded={setExcluded}
                    setAllmode={setAllmode}
                    setSortDirection={setSortDirection}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                    sortDirection={sortDirection}
                    cols={cols}
                    count={count}
                    excluded={excluded}
                    allmode={allmode}
                    selected={selected}
                    csvConfig={csvConfig}
                    csvFields={csvFields}
                    csvType={csvType}
                    filterDetails={filterDetails}
                />
            </Pane>

            <Card id="table" width="100%" backgroundColor="white" border="default" display="flex" flexDirection="column" justifyContent="flex-start">
                {loading ? (
                    <Pane height={tableHeight}>
                        <Pane display="flex" alignItems="center" justifyContent="center" height={100}>
                            <Spinner size={32} />
                        </Pane>
                    </Pane>
                ) : (
                    <Pane height={tableHeight} overflow="auto" elevation={3}>
                        {data.map((item, index) => {
                            const rowColor = index % 2 === 0 ? "white" : "var(--offwhite)";
                            return (
                                <Table.Row key={item.id + "item"} className={title !== "Royalty Payments" ? "table-row-select" : "table-row"} value="" backgroundColor={rowColor} width="100%" paddingY={8}>
                                    <RowComponent
                                        item={item}
                                        allmode={allmode}
                                        handleCheck={handleSetChecked}
                                        colWidths={["1"].concat(cols.map((col) => col.flex))}
                                        makeIDCell={makeIDCell}
                                        makeTableCellArray={makeTableCellArray}
                                    />
                                </Table.Row>
                            );
                        })}
                    </Pane>
                )}
            </Card>
            <Card ref={lowerComps} display="flex" flexDirection="column" alignItems="center">
                <PaginationDisplay
                    currpage={currpage}
                    pagecount={pagecount}
                    setCurrpage={setCurrpage}
                    searchCount={count}
                    count={count}
                />
                {cantCreateNew && (
                    <Paragraph ref={otherComps} size={300} marginBottom={8} color="muted" fontStyle="italic">
                        Publishers on the {publisherGlobalData.plan ? publisherGlobalData.plan : "Free Plan"} may create and maintain up to {publisherGlobalData.maxProducts ? publisherGlobalData.maxProducts : 10} products - <Link to='/publisher'> explore other plans to add more.</Link>
                    </Paragraph>
                )}
            </Card>
        </Pane>
    );
}

export default LandingPage;
