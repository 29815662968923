import { gql } from '@apollo/client';

const QUERY_GROSS_SALES_BY_DATE = gql`
  query GrossSalesByDate($publisher: Int!, $startDate: String, $endDate: String) {
  	grosssalesbydate(publisher: $publisher, startDate: $startDate, endDate: $endDate) {
  		paidDate,
  		shipDate,
  		totalAmount,
  		salesperson {
  			value
  		}
  	}
  }
`;

export default QUERY_GROSS_SALES_BY_DATE;