import { gql } from '@apollo/client';

const LOCK_ITEM_MUTATION = gql`
  mutation LockItemMutation(
    $userId: Int!,
    $itemType: String!,
    $itemId: Int!, 
    $status: Boolean!
  ) {
    lockItem(
      userId: $userId,
      itemType: $itemType,
      itemId: $itemId, 
      status: $status
    ) {
      message
    }
  }
`;

export default LOCK_ITEM_MUTATION;