import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { useParams, Link, useNavigate } from "react-router-dom";
import { useSelector, } from 'react-redux';
import { baseProductObject } from './BaseProductObject.js';
import ProductForm from "./ProductForm.js";
import Breadcrumbs from "../../components/layout/Breadcrumbs.js";
import SaveButtons from "../../components/layout/SaveButtons.js";
import LeavePagePrompt from "../../components/LeavePagePrompt.js";
import QUERY_PRODUCT from "../../graphql/queries/Products/QueryProduct.js";
import UPDATE_PRODUCT_MUTATION from "../../graphql/mutations/Products/UpdateProduct.js";
import CREATE_PRODUCT_CREATOR_MUTATION from "../../graphql/mutations/Products/CreateProductCreator.js";
import DELETE_PRODUCT_CREATOR_MUTATION from "../../graphql/mutations/Products/DeleteProductCreator.js";
import DELETE_PRODUCT_MUTATION from "../../graphql/mutations/Products/DeleteProduct.js";

import {
  Pane,
  Paragraph,
  Badge,
  toaster,
  EmptyState,
  InfoSignIcon,
  Card,
  LockIcon,
  Tooltip
} from 'evergreen-ui';
import { makeDateString } from "../../util.js";

function Product(props) {
  const userid = useSelector((state) => state.user.value);
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [product, setProduct] = useState(baseProductObject);
  const [changed, setChanged] = useState([]);
  const [staged, setStaged] = useState(baseProductObject);
  const [stagedCreators, setStagedCreators] = useState([]);

  const [deletedCreators, setDeletedCreators] = useState([]);
  const [currentCreators, setCurrentCreators] = useState([]);
  const [editing, setEditing] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const { productId } = useParams();
  const navigate = useNavigate();

  const handleUpdateStaged = obj => {
    // check for an existing record in changed
    delete obj.val.__typename;
    delete obj.val.label;
    let newArr = changed.filter(item => item.hasOwnProperty("key") && item.key !== obj.key);
    if (obj.key.includes("Date")) {
      obj.val = makeDateString(obj.val)
    }
    newArr.push(obj)
    setChanged(newArr);
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }));
  }

  const [updateProduct, { productdata }] = useMutation(UPDATE_PRODUCT_MUTATION);
  const [createProductCreator, { productcreatordata }] = useMutation(CREATE_PRODUCT_CREATOR_MUTATION);
  const [deleteProductCreator, { deletecreatordata }] = useMutation(DELETE_PRODUCT_CREATOR_MUTATION);
  const [deleteProduct, { deleteproductdata }] = useMutation(DELETE_PRODUCT_MUTATION);

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_PRODUCT, {
    variables: { publisher: parseInt(currentpublisher.id), id: parseInt(productId) },
  });

  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  const resetForm = () => {
    setStaged(Object.assign({}, product));
    setChanged([]);
    setDeletedCreators([]);
    setStagedCreators([]);
  }


  const handleUpdateProduct = async () => {

    try {
      if (changed.length) {
        const results = await updateProduct(
          {
            variables: {
              id: parseInt(staged.id),
              changed: JSON.stringify(changed),
            }
          });
        toaster.closeAll()
        toaster.success(results.data.updateProduct.message, { duration: 10 })

      }

      if (stagedCreators.length) {
        const allCreated = await Promise.all(stagedCreators.map(async (creator) => {
          const created = await createProductCreator(
            {
              variables: {
                product: parseInt(productId),
                creator: parseInt(creator.creator.id),
                onixContributorCode: parseInt(creator.onixContributorCode.id)
              }
            })
          return created
        }))
        const createdNotif = allCreated.map(item => item.data.createProductCreator.message).join(', ').toString()
        toaster.closeAll()
        toaster.success("Added the following creators to this title: " + createdNotif, { duration: 10 })

      }

      if (deletedCreators.length) {
        const allDeleted = await Promise.all(deletedCreators.map(async (creatorId) => {
          const deleted = await deleteProductCreator(
            {
              variables: {
                id: parseInt(creatorId),
              }
            });
          return deleted
        }))
        const deletedNotif = allDeleted.map(item => item.data.deleteProductCreator.message).join(', ').toString()
        toaster.closeAll()
        toaster.success("Removed the following creators from this title: " + deletedNotif, { duration: 10 })
      }


      setDeletedCreators([]);
      setCurrentCreators(stagedCreators.concat(currentCreators));
      setStagedCreators([]);
      setChanged([]);
      return "updated"

    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error saving product. Try again or contact developer", { duration: 5 })
      return error.message;
    }



  }

  const handleDeleteProduct = async () => {
    try {
      const results = await deleteProduct({
        variables: {
          id: parseInt(staged.id),
        }
      });
      toaster.closeAll();
      toaster.success(`Product ${results.data.deleteProduct.message} deleted successfully!`, { duration: 10 });
      
      // Use navigate directly with the path
      navigate('/products');
  
    } catch (error) {
      toaster.closeAll();
      toaster.danger("Error deleting product. Try again or contact developer", { duration: 5 });
      return error.message;
    }
  }
  

  const handleAttachOrUpdateCreator = (id, creator, code) => {
    let newObj = {
      id: id ? id : stagedCreators.length + 1,
      creator: creator,
      onixContributorCode: code
    }
    let newarr = stagedCreators;
    if (id) {
      newarr = newarr.filter(creator => creator.id !== id);
    }
    newarr = [newObj].concat(newarr);
    setStagedCreators(newarr);
  }

  const handleRemoveCreator = creatorId => {
    let newStaged = stagedCreators.filter(creator => creator.id !== creatorId);
    setStagedCreators(newStaged);
    let newCurrent = currentCreators.filter(creator => creator.id !== creatorId);
    setCurrentCreators(newCurrent);
    let newDeleted = [creatorId].concat(deletedCreators);
    setDeletedCreators(newDeleted);
  }

  const getImageFilename = url => {
    if (url) {
      return url.split("/").pop();
    } else {
      return "";
    }
  }

  const checkInteriorImages = arrStr => {
    if (arrStr && arrStr !== "") {
      let jsonobj = JSON.parse(arrStr);
      return Array.isArray(jsonobj);
    } else {
      return false;
    }
  }

  const setCoverImage = async (url) => {
    try {
      let updateResult = await updateProduct(
        {
          variables: {
            id: parseInt(staged.id),
            changed: JSON.stringify([{ key: "coverImage", val: url }]),
          }
        });
      toaster.closeAll()
      toaster.success(updateResult.data.updateProduct.message, { duration: 3 })
      refetch();

    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error uploading interior image. Try again or contact developer", { duration: 5 })
      return error.message;
    }

  }

  const setInteriorImage = async (url) => {
    let newArr = [url];
    if (checkInteriorImages(product.interiorImages)) {
      newArr = [url].concat(JSON.parse(product.interiorImages));
    }
    try {
      let updateResult = await updateProduct(
        {
          variables: {
            id: parseInt(staged.id),
            changed: JSON.stringify([{ key: "interiorImages", val: newArr }]),
          }
        });
      toaster.closeAll()
      toaster.success(updateResult.data.updateProduct.message, { duration: 3 })
      refetch();

    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error uploading interior image. Try again or contact developer", { duration: 5 })
      return error.message;
    }

  }

  useEffect(() => {
    if (data && data.product.length) {
      const item = data.product[0]
      setProduct(item);
      setStaged(item);
      setCurrentCreators(item.productcreatorSet)
      const linkedModelCount = item.expenseSet.length + item.royaltySet.length + item.orderproductSet.length
      if (linkedModelCount === 0) {
        setCanDelete(true)
      }
    }
  }, [data]);

  if (loading) return null;
  if (error) return `Error! ${error}`;
  if (data.product.length === 0) return (
    <Pane>
      <Pane paddingX={16} paddingTop={16}>
        <Breadcrumbs pageLabel="PRODUCT" sourceUrl="/products" />
      </Pane>
      <Pane display="flex" justifyContent="center" marginTop={60}>
        <Card alignItems="center" width="50%" elevation={2}>
          <EmptyState
            background="light"
            title="Product not found"
            orientation="vertical"
            icon={<InfoSignIcon size={12} color="#C1C4D6" />}
            iconBgColor="#EDEFF5" />
        </Card>

      </Pane>

    </Pane>);

  return (
    <Pane>
      {
        editing &&
        (<LeavePagePrompt
          userid={userid}
          itemid={productId}
          itemType="product"
          when={!!(changed?.length)}
        />)}
      <Pane>
        <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16}>
          <Breadcrumbs pageLabel="PRODUCT" curPage={product.id} sourceUrl="/products" />
          <Pane marginTop={8} marginBottom={8} size={300} display="flex" justifyContent="space-between" >
            <Pane display="flex" alignItems="flex-end">
              {product.frozen && <Tooltip content={<Pane >
                <Paragraph size={300} color="white"> Publishers on the {publisherGlobalData.plan ? publisherGlobalData.plan : "Free Plan"} can only create/maintain up to {publisherGlobalData.maxProducts ? publisherGlobalData.maxProducts : "10"} products To add more products or use products created during your free trial, visit your <Link to="/publisher" className="dark-link">publisher settings</Link> to explore other plans.</Paragraph>
              </Pane>}><LockIcon size={18} color="muted" /></Tooltip>}

              <Paragraph marginLeft={4} size={300} fontSize={18} fontWeight="bold"> {product.title}</Paragraph>

              <Paragraph marginLeft={8} size={300}><Badge color="neutral">ID: {product.id}</Badge></Paragraph>
              <Badge marginLeft={8}>{product.format ? product.format.description : "No Format"}</Badge>
              <Badge marginLeft={8}>{product.status ? product.status.description : "No Status"}</Badge>
              <Paragraph size={300} marginLeft={8}>
                <Link to={{
                  pathname: '/expenses',
                  state: { query: {product__id: staged.id}, queryTitle: "Expenses Associated with " + staged.title + " (" + staged.format.description + ")", sourceURL: '/products/' + staged.id, sourcePageTitle: "PRODUCT " + staged.id }
                }}>
                  EXPENSES ({staged.expenseSet ? staged.expenseSet.length : "None"})
                </Link>
              </Paragraph>
              <Paragraph size={300} marginLeft={8}>
                <Link to={{
                  pathname: '/royalties',
                  state: { query: {product__id: staged.id}, queryTitle: "Royalties Associated with " + staged.title + " (" + staged.format.description + ")", sourceURL: '/products/' + staged.id, sourcePageTitle: "PRODUCT " + staged.id }
                }}>

                  ROYALTIES ({staged.royaltySet ? staged.royaltySet.length : "None"})
                </Link>
              </Paragraph>
              <Paragraph size={300} marginLeft={8}>
              <Link to={{
                  pathname: '/orders',
                  state: { query: {orderproduct__product__id: staged.id}, queryTitle: "Orders Containing " + staged.title + " (" + staged.format.description + ")", sourceURL: '/products/' + staged.id, sourcePageTitle: "PRODUCT " + staged.id }
                }}>
                ORDERS ({staged.orderproductSet ? staged.orderproductSet.length : "None"})
                </Link>
              </Paragraph>
            </Pane>
            <Pane>
              <SaveButtons
                itemType="product"
                editing={editing}
                locked={product.locked}
                itemId={productId}
                setEditing={setEditing}
                handleUpdate={handleUpdateProduct}
                handleDelete={handleDeleteProduct}
                canDelete={canDelete}
                deleteMsg={"Cannot delete product if there are any linked expenses, royalties, or orders."}
                resetForm={resetForm}
                refetch={refetch}
                frozen={product.frozen}
              />

            </Pane>
          </Pane>
        </Pane>
        <Pane padding={16} width="100%" >
          <ProductForm
            staged={staged}
            handleUpdateStaged={handleUpdateStaged}
            handleUpdateCreatorStaged={handleAttachOrUpdateCreator}
            handleRefetchProduct={refetch}
            handleDeleteProductCreator={handleRemoveCreator}
            productcreatorSet={stagedCreators.concat(currentCreators)}
            royaltySet={staged.royaltySet}
            editing={editing}
            editMode="edit"
            getImageFilename={getImageFilename}
            checkInteriorImages={checkInteriorImages}
            setCoverImage={setCoverImage}
            setInteriorImage={setInteriorImage}
          />
        </Pane>
      </Pane>
    </Pane>
  )
}

export default Product;