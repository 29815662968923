import React, { useState } from 'react';
import { TextInputField, Button, Paragraph, Heading, Pane } from 'evergreen-ui';
import { getCookie } from '../../util.js';
import { Redirect, useLocation } from 'react-router-dom';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const query = new URLSearchParams(useLocation().search);
  const email = query.get('email')

  const handleResetPassword = e => {
    e.preventDefault();

    if(password === confirmPassword) {
      const user = {
        email: email,
        password: password
      };
      const csrftoken = getCookie('csrftoken');
      fetch('/api/v1/auth/password/confirm/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ user }),
      })
      .then(res => {
        // if (!res.ok) {
        //   throw new Error('Network response was not ok');
        // }
      //   return res.json();
          if(res.status === 200) {
            fetch('/api/v1/auth/logout/', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
              }
            })
            .then(res => res.json())
            .then(data => {
              localStorage.clear();
              window.location.replace('/login');
            });
          }
      })
      .then(data => {
        if (data.detail) {
          setMessage(data.detail);
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        setMessage('Error changing password reset.');
      });
    } else {
      setMessage('Password does not match.');
    }
  };

  return (
    <Pane backgroundColor="var(--lightgrey)"  display="flex" flexDirection="column" alignItems="center" padding={32}>
      <Heading size={700} marginBottom={16}>
        <a href="/" style={{  color: 'inherit' }}>
          WorkingLit
        </a>
      </Heading>
      <Heading size={500} marginBottom={16}>Reset Password</Heading>
      <Paragraph marginBottom={32}>Enter your new password.</Paragraph>
      <TextInputField
        label="New Password"
        type="password"
        onChange={e => setPassword(e.target.value)}
        value={password}
      />
      <TextInputField
        label="Confirm Password"
        type="password"
        onChange={e => setConfirmPassword(e.target.value)}
        value={confirmPassword}
      />
      <Button onClick={handleResetPassword} type='submit'>Change Password</Button>
      {message && <Paragraph marginTop={16} color="green">{message}</Paragraph>}
    </Pane>
  );
}

export default ResetPassword;
