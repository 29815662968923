import React, { useState, useEffect } from "react";
import { useMutation } from '@apollo/client';
import { makeDateString } from "../../util.js";
import ExpenseForm from "./ExpenseForm.js";
import { baseExpenseObject } from './BaseExpenseObject.js';
import CREATE_EXPENSE_MUTATION from "../../graphql/mutations/Expenses/CreateExpense.js";

import {
  Pane,
  Paragraph,
  Button,
  toaster,
} from 'evergreen-ui';

function NewExpense(props) {
  const [staged, setStaged] = useState(baseExpenseObject);

  const [createExpense, result] = useMutation(CREATE_EXPENSE_MUTATION);
  const [disableSave, setDisableSave] = useState(true);



  const handleClearStaged = () => {
    setStaged(baseExpenseObject);
  }

  const handleCreateExpense = () => {
    if (!staged.expenseDate || staged.expenseDate === "") {
      staged.expenseDate = makeDateString(new Date());
    } else {
      staged.expenseDate = makeDateString(staged.expenseDate);
    }
    createExpense(
      {
        variables: {
          publisherId: parseInt(props.publisher),
          expense: JSON.stringify(staged)
        }
      });
    handleClearStaged();
    props.handleToggleNewMode();
    props.handleRefetchExpenses();
  }

  const handleAttachProduct = product => {
    handleUpdateStaged({
      "key": "product",
      "val": JSON.parse(product)
    })
  }

  const handleUpdateStaged = obj => {
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }));
    if (obj.hasOwnProperty("isValid")) {
      setDisableSave(!obj.isValid);
    }
  }

  const handleProductError = () => {
    toaster.danger('Expenses that are not attached to a product must include a category');
  }

  useEffect(()=>{
    if (result.loading) {
      toaster.notify("Creating New Expense...", { duration: 60 });
    }
    if (result.called && !result.error) {
      toaster.closeAll();
      toaster.success("New Expense Created!", { duration: 1 });

    }
  }, [result]);

  return (
    <Pane backgroundColor="var(--background)" width="100%" height="100%">
      <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16} paddingBottom={8} width="100%" display="flex" justifyContent="space-between" alignItems="flex-end">
        <Paragraph fontSize={18}> New Expense </Paragraph>
        <Pane display="flex" flexDirection="row" justifyContent="flex-start">
          <Button className="confirm-button" disabled={disableSave} marginRight={16} onClick={e => {
            if (!staged.product.id && !staged.category.id) {
              handleProductError(true);
            } else {
              handleCreateExpense();
            }
          }}>
            CREATE EXPENSE
          </Button>
          <Button onClick={e => {
            handleClearStaged();
            props.handleToggleNewMode();
          }}>
            Cancel
          </Button>
        </Pane>

      </Pane>

      <ExpenseForm
        staged={staged}
        handleUpdateStaged={handleUpdateStaged}
        handleAttachProduct={handleAttachProduct}
        editing={true}
        newMode={true}
      />

    </Pane>
  )
}

export default NewExpense;