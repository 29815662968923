import { gql } from '@apollo/client';

const DELETE_ROYALTY_PAYMENT_MUTATION = gql`
  mutation DeleteRoyaltyPaymentMutation(
    $id: Int!
  ) {
    deleteRoyaltyPayment(
      id: $id
    ) {
      message
    }
  }
`;

export default DELETE_ROYALTY_PAYMENT_MUTATION;