// Tier in Royalty
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';


import {
  HelpIcon,
  IconButton,
  Pane,
  Paragraph,
  Table,
  TextInput,
  Tooltip,
  TrashIcon,
} from 'evergreen-ui';

import {
  ForeignKeyDropDownInput,
} from "../../inputs/index.js";

function RoyaltyLineItem({ item, editing, handleUpdateItem, handleDelete }) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [staged, setStaged] = useState(item);

  const handleChange = obj => {
    delete obj.val.__typename;
    delete obj.val.label;
    let newobj = { ...staged, [obj.key]: obj.val };
    setStaged(newobj);
    handleUpdateItem(newobj);
  }


  useEffect(() => {
    if (item) {
      setStaged(item);
    }
  }, [item]);

  if (editing) {
    return (<Table.Row height={48} borderTop="default" borderBottom="none">
      <Table.TextCell flex={2} textAlign="right">
        <TextInput
          className="item-form"
          onChange={e => handleChange({ key: "amount", val: e.target.value })}
          isInvalid={staged.amount ? false : true}
          value={staged.amount}
          width={50}
          marginY={0}
          marginRight={4}
        /> %
      </Table.TextCell>
      <Table.Cell flex={4}>
        <ForeignKeyDropDownInput
          el="royaltyTerm"
          value={staged.royaltyTerm}
          editing={editing}
          width={160}
          formLabel={""}
          handleChange={handleChange}
          values={parseInt(item.category.id) === 1 ? publisherGlobalData.royaltyterms : publisherGlobalData.royaltyterms.slice(0, 2)}
          opts={{ showLabel: false, short:true }}
        />
      </Table.Cell>
      <Table.TextCell flex={5} margin={8} alignContent="right">
        {item.tierCount > 1 ?
          <Pane display="flex" alignItems="center">
            {(item.first) ?
              (
                <Paragraph size={300} marginLeft={72}> {staged.thresholdStart} </Paragraph>
              ) :
              (<Pane display="flex"
                alignItems="center">
                <TextInput
                  className="item-form"
                  onChange={e => handleChange({ key: "thresholdStart", val: e.target.value })}
                  value={staged.thresholdStart}
                  isInvalid={staged.invalid}
                  width={80}
                  marginY={0}
                  disabled={!editing}
                  textAlign="right"
                />
                {staged.invalid &&
                  (<Tooltip content={<Pane> <Paragraph size={300} color="white">
                    Tier start too low.
                  </Paragraph>

                  </Pane>} ><HelpIcon color="muted" marginX={8} /></Tooltip>)}
              </Pane>)}
            <Paragraph size={300} marginLeft={8}>—</Paragraph>
            <Paragraph size={300} marginLeft={8}> {staged.thresholdEnd} </Paragraph>
            <Paragraph size={300} marginLeft={8}> units sold</Paragraph>
          </Pane> : <Paragraph size={300} color="grey"> (for all units sold) </Paragraph> }

      </Table.TextCell>

      <Table.Cell flex={1}>
        <IconButton icon={TrashIcon} intent="danger" size="small" onClick={e => handleDelete(item)} />
      </Table.Cell>

    </Table.Row>)
  } else {
    return (<Table.Row height={40} borderTop="default" borderBottom="none">
      <Table.TextCell flex={1} textAlign="right">
        <Paragraph size={300}> {staged.amount} %</Paragraph>
      </Table.TextCell>
      <Table.Cell flex={2}>
        <Paragraph size={300} marginY={0} >
          {staged.royaltyTerm ? staged.royaltyTerm.value : "(no term chosen)"}
        </Paragraph>
      </Table.Cell>
      <Table.TextCell flex={5} margin={8} alignContent="right">
        {item.tierCount > 1 ?
          <Pane display="flex">
            <Paragraph size={300}> between </Paragraph>
            <Paragraph marginX={12} size={300}> {staged.thresholdStart} — {staged.thresholdEnd}</Paragraph>
            <Paragraph size={300}> units sold</Paragraph>
          </Pane> : 
          <Paragraph size={300} color="grey"> (for all units sold) </Paragraph>
        }
      </Table.TextCell>
    </Table.Row>
    )
  }


}

export default RoyaltyLineItem;