// TODO JSDOC format, mutation results
import React, { useState } from "react";
// import { useMutation } from 'react-apollo';
import { useSelector } from 'react-redux';
// import DELETE_PUBLISHER_USER_MUTATION from "../../graphql/mutations/DeletePublisherUser.js";
import { useMutation } from '@apollo/client';
import UPDATE_USER_ROLE_MUTATION from "../../graphql/mutations/UpdateUserRole.js";

import {
  Button,
  Table,
  Pane,
  IconButton,
  Combobox,
  TickIcon,
  CrossIcon
} from 'evergreen-ui';

function GroupUser(props) {
  const user = useSelector((state) => state.user.value);

  const [thisuser, setThisuser] = useState(props.publisheruser);
  const [changerole, setChangerole] = useState(false);

  const isCurrentUser = parseInt(props.publisheruser.user.id) === parseInt(user);

  const handleChangerole = (val) => {
    setChangerole(val);
  }

  const handleUpdateUser = (change) => {
    thisuser.role.id = change.roleId;
    thisuser.role.name = change.roleName;
    setThisuser(thisuser);
  }

  // const [deletePublisherUser, { data }] = useMutation(DELETE_PUBLISHER_USER_MUTATION);

  return (
    <Table.Row className="table-row" value="">
      <Table.TextCell flexShrink={0} flexGrow={3}>{thisuser.user.email}</Table.TextCell>
      {!changerole ? (
        <Table.TextCell flexShrink={0} flexGrow={2}><Button disabled={isCurrentUser} onClick={e => setChangerole(true)}>{thisuser.role.name}</Button></Table.TextCell>
      ) : (
        <Table.TextCell flexShrink={0} flexGrow={2}>
          <SelectUserRole mode="update" userroles={props.userroles} publisheruser={thisuser} handleChangerole={handleChangerole} handleUpdateUser={handleUpdateUser} />
        </Table.TextCell>
      )}
      <Table.TextCell flexShrink={0} flexGrow={2}>
        <Button 
          disabled={isCurrentUser}
          intent="danger" 
          onClick={e => {
            // deletePublisherUser(
            //   { variables: { 
            //     id: parseInt(thisuser.id),
            //   }
            // });
            props.handleDeletePublisherUser(thisuser.id);
          }}>
          Remove User
        </Button>
      </Table.TextCell>
    </Table.Row>
  )
}

export default GroupUser;


function SelectUserRole(props) {

  const [role, setRole] = useState(props.publisheruser ? props.publisheruser.role : {name: "user", id: "2"});

  const dropdownItems = props.userroles.map(item => {
    return {label: item.name, value: item.id};
  });

  const [updateUserRole, { data }] = useMutation(UPDATE_USER_ROLE_MUTATION);

  return (
    <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
      <Combobox
        initialSelectedItem={{ label: role.name }}
        items={dropdownItems}
        itemToString={item => (item ? item.label : '')}
        onChange={selected => setRole(selected)}
        width={120}
      />
      <IconButton 
        icon={TickIcon} 
        intent="success" 
        onClick={e => {
          updateUserRole(
            { variables: { 
              id: parseInt(props.publisheruser.id),
              role: parseInt(role.value)
            }
          });
          props.handleUpdateUser({id: parseInt(props.publisheruser.id), roleId: parseInt(role.value), roleName: role.label});
          props.handleChangerole(false);
        }} 
      />
      <IconButton icon={CrossIcon} intent="danger" onClick={e => props.handleChangerole(false)} />
    </Pane>
  )
}
