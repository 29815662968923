import React from "react";
import ReactDOM from 'react-dom';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import store from './store/store.js';
import { Provider } from 'react-redux';
import App from './App';
import Login from "./views/Login/Login.js";
import ResetPassword from "./views/Login/ResetPassword.js";
import ResetNewPassword from "./views/Login/ResetNewPassword.js";
import AcceptInvite from "./views/AcceptInvite.js";
import reportWebVitals from './reportWebVitals';
import './assets/styles/index.css';

const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_GRAPHQL_URL : window.location.protocol + '//' + window.location.hostname;

const client = new ApolloClient({
  uri: baseUrl + '/graphql/',
  cache: new InMemoryCache()
});

const checkAuthentication = () => {
  return localStorage.getItem('token') !== null;
};

const checkUserid = () => {
  return localStorage.getItem('userid') || false;
};

const checkPublisher = () => {
  return localStorage.getItem('publisher') || false;
};

const checkPublisherName = () => {
  return localStorage.getItem('publisher_name') || false;
};

const isAuthenticated = checkAuthentication();
const user = checkUserid();
const publisher = checkPublisher();
const publisher_name = checkPublisherName();

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/reset_password/:resetToken" element={<ResetNewPassword />} />
          <Route path="/accept_invite" element={<AcceptInvite />} />
          <Route path="*" element={isAuthenticated ? <App isAuthenticated={isAuthenticated} user={user} publisher={publisher} publisherName={publisher_name} baseUrl={baseUrl} /> : <Navigate to="/login" />} />        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
