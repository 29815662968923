import { gql } from '@apollo/client';

const CREATE_ORDER_PAYMENT_MUTATION = gql`
  mutation CreateOrderPaymentMutation(
    $orderId: Int!,
    $payment: String!,
  ) {
    createOrderPayment(
      orderId: $orderId,
      payment: $payment,
    ) {
      message
    }
  }
`;

export default CREATE_ORDER_PAYMENT_MUTATION;