import { gql } from '@apollo/client';

const QUERY_ORDERS = gql`
  query Orders($publisher: Int!, $page: Int, $params: String) {
    orders(publisher: $publisher, page: $page, params: $params) {
      id,
      customer {
        id,
        adminNotes,
        firstName,
        lastName,
        company,
        customeremailSet {
          id,
          value,
          primary
        },
        customerphonenumberSet {
          id,
          value,
          primary
        },
        customeraddressSet {
          id,
          line1,
          line2,
          city,
          stateOrRegion,
          country,
          zipcode,
          primary,
          addressType
        }
      },
      orderAddress {
        id,
        line1,
        line2,
        city,
        stateOrRegion,
        country,
        zipcode,
        primary,
        addressType
      },
      orderEmail {
        id,
        value,
        primary
      },
      orderPhone {
        id,
        value,
        primary
      },
      orderStatus {
        id,
        value
      },
      poNumber,
      paymentTerm {
        id,
        value
      },
      salesperson {
        id,
        value
      },
      shippingType {
        id,
        value
      },
      shippingCost,
      adminNotes,
      totalAmount,
      paid,
      paidDate,
      shipped,
      shipDate,
      orderproductSet {
        id,
        product {
          id,
          title,
          isbn,
          created,
          inventoryQuantity,
          format {
            id,
            value,
            description
          }
        }
        quantity,
        discountPerUnit,
        netPrice,
        calcPrice,
        calcDiscount,
        retailPrice
      },
      orderpaymentSet {
        id,
        method {
          id,
          value
        }
        amount,
        memo
      },
      locked,
      lastEditor {
        id,
        email
      },
      created,
      modified,
    }
  }
`;

export default QUERY_ORDERS;