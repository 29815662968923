import { gql } from '@apollo/client';

const DELETE_PRODUCT_MUTATION = gql`
  mutation DeleteProductMutation(
    $id: Int!,
  ) {
    deleteProduct(
      id: $id,
    ) {
      message
    }
  }
`;

export default DELETE_PRODUCT_MUTATION;