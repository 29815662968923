import { createSlice } from '@reduxjs/toolkit';

export const onixGlobalDataSlice = createSlice({
  name: 'profile',
  initialState: {
    value: {
      onixcontributorcodes: false,
      onixproductforms: false,
      onixproductformdetails: false,
      onixproductavailabilitys: false,
      onixaudiencetypes: false,
    },
  },
  reducers: {
    setonixGlobalData: (state, action) => {
      if (action.payload.hasOwnProperty("__typename")) {
        delete action.payload.__typename;
      }
      const obj = Object.assign({}, state.value, action.payload);
      state.value = obj;
      // state.value = action.payload
    },
  },
})

export const { setonixGlobalData } = onixGlobalDataSlice.actions;

export default onixGlobalDataSlice.reducer;