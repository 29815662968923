import { gql } from '@apollo/client';

const QUERY_ROYALTYS = gql`
  query Royaltys($publisher: Int!, $page: Int, $params: String, $startDate: String, $endDate: String) {
    royaltys(publisher: $publisher, page: $page, params: $params, startDate: $startDate, endDate: $endDate) {
      id,
      product {
        id,
        title,
        frozen,
        retailPrice,
        format {
          id,
          value,
          description
        },
        isbn,
        orderproductSet {
          id,
          order {
            id,
            paid,
            paidDate,
            shipped,
            shipDate
          },
          product {
            id,
            retailPrice
          },
          quantity,
          discountPerUnit,
          netPrice,
          created
        },
        expenseSet {
          id,
          expenseDate,
          amount
        }
      },
      creator {
        id,
        displayName,
        paymentName,
        paymentType {
          id,
          value
        },
        creatoremailSet {
          id,
          value,
          primary
        },
        creatoraddressSet {
          id,
          line1,
          line2,
          city,
          stateOrRegion,
          country,
          zipcode,
          primary
        }
      },
      royaltylineitemSet {
        id,
        amount,
        thresholdStart,
        thresholdEnd,
        category {
          id,
          value
        },
        royaltyTerm {
          id,
          value
        },
        tierDiscount
      },
      royaltypaymentSet {
        id,
        method {
          id,
          value
        },
        amount,
        sentDate
      },
      locked,
      lastEditor {
        id,
        email
      },
      created,
      modified,
      owed,
      paid
    }
  }
`;

export default QUERY_ROYALTYS;