import React, { useState } from 'react';
import { TextInputField, Button, Paragraph, Heading, Pane } from 'evergreen-ui';
import { getCookie } from '../../util.js';
import axios from "axios";

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleReset = e => {
    e.preventDefault();
    const csrftoken = getCookie('csrftoken');
    fetch('/api/v1/auth/password/reset_password/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      body: JSON.stringify({ email }),
    })
    .then(res => {
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
    .then(data => {
      if (data.detail) {
        setMessage('Password reset link has been sent to your email.');
      } else {
        setMessage('Sorry, there was an error sending the password reset link.');
      }
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      setMessage('Error sending password reset link.');
    });
  };

  return (
    <Pane backgroundColor="var(--lightgrey)"  display="flex" flexDirection="column" alignItems="center" padding={32}>
      <Heading size={700} marginBottom={16}>
        <a href="/" style={{  color: 'inherit' }}>
          WorkingLit
        </a>
      </Heading>
      <Heading size={500} marginBottom={16}>Reset Password</Heading>
      <Paragraph marginBottom={32}>Enter your email address to receive a password reset link.</Paragraph>
      <TextInputField
        label="Email Address"
        onChange={e => setEmail(e.target.value)}
        value={email}
      />
      <Button onClick={handleReset} type='submit'>Send Reset Link</Button>
      {message && <Paragraph marginTop={16} color="green">{message}</Paragraph>}
    </Pane>
  );
}

export default ResetPassword;