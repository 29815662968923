import { gql } from '@apollo/client';

const CREATE_PAYMENTTERM_MUTATION = gql`
  mutation CreatePaymentTermMutation(
    $publisherId: Int!,
    $paymentTerm: String!
  ) {
    createPaymentTerm(
      publisherId: $publisherId,
      paymentTerm: $paymentTerm
    ) {
      message
    }
  }
`;

export default CREATE_PAYMENTTERM_MUTATION;