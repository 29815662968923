// Row in Royaltys table when filtered by creator
import React, { useState, useEffect } from "react";
import { makeAdjustedString, commaSepThou } from '../../../util.js';
import JSON5 from 'json5';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Threshold from "../Threshold.js";

import {
  Pane,
  Badge,
  Table,
  Checkbox,
  Paragraph
} from 'evergreen-ui';

function RoyaltyItemCreator(props) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [checked, setChecked] = useState(false);
  const statementInfo = props.paymentMode ? "none" : JSON5.parse(makeAdjustedString(props.item.statementInfo))
  const owed = props.paymentMode ? parseFloat(props.item.owed) : (parseFloat(statementInfo.lifeCredit) - parseFloat(statementInfo.lifeDebit))
  const balance = owed - parseFloat(props.item.paid)

  const handleSetChecked = () => {
    if (props.selectall === true && checked === true) {
      props.handleAddExcluded(props.item);
    } else if (checked === true) {
      // remove it
      props.handleRemoveSelected(props.item);
    } else {
      // add it
      props.handleAddSelected(props.item);
    }
    setChecked(!checked);
  }

  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  useEffect(() => {
    setChecked(props.selectall);
  }, [props.selectall]);

  return (
    <Table.Row className="table-row" value="" >
      <Table.TextCell flex="2">
        <Pane>
          <Pane display="flex" alignItems="center">
            <Checkbox
              marginRight={16}
              label=""
              checked={props.selectall ? true : checked}
              onChange={e => handleSetChecked()}
            />
            <Link to={`/royalties/${props.item.id}`}>{props.item.product.title}</Link>
            <Paragraph marginLeft={4} size={300}>({props.item.product.format ? props.item.product.format.description : "No Format"})</Paragraph>

          </Pane>
        </Pane>
      </Table.TextCell>
      <Table.TextCell flex="2"><Threshold paddingY={8} royaltylineitemSet={props.item.royaltylineitemSet} /></Table.TextCell>
      <Table.TextCell flex="1"><Badge color={balance < 0 ? "green" : balance >= 100 ? "red" : "neutral"}>{balance < 0 ? "-" : ""}{currency}{commaSepThou(Math.abs(balance).toFixed(2))}</Badge></Table.TextCell>
    </Table.Row>
  )
}

export default RoyaltyItemCreator;