import { gql } from '@apollo/client';

const UPDATE_ROYALTY_LINE_ITEM_MUTATION = gql`
  mutation UpdateRoyaltyLineItemMutation(
    $id: Int!,
    $changed: String!
  ) {
    updateRoyaltyLineItem(
      id: $id,
      changed: $changed
    ) {
      message
    }
  }
`;

export default UPDATE_ROYALTY_LINE_ITEM_MUTATION;