import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { useParams, Link, useNavigate } from "react-router-dom";
import { useSelector, } from 'react-redux';

// ------- QUERIES/MUTATIONS
import QUERY_CUSTOMER from "../../graphql/queries/Customers/QueryCustomer.js";
import UPDATE_CUSTOMER_MUTATION from "../../graphql/mutations/Customers/UpdateCustomer.js";
import DELETE_CUSTOMER_MUTATION from "../../graphql/mutations/Customers/DeleteCustomer.js";

// ------- COMPONENTS
import { baseCustomerTemplate } from './BaseCustomerTemplate.js';
import EditCustomerForm from "./CustomerForm.js";
import Breadcrumbs from "../../components/layout/Breadcrumbs.js";
import SaveButtons from "../../components/layout/SaveButtons.js";
import LeavePagePrompt from "../../components/LeavePagePrompt.js";
import { ContactInfo } from "../../inputs/index.js";

import {
  Pane,
  Paragraph,
  Badge,
  toaster,
  Card,
  Tablist,
  Tab,
  EmptyState,
  InfoSignIcon
} from 'evergreen-ui';

function Customer() {
  const userid = useSelector((state) => state.user.value);
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const navigate = useNavigate();

  const { customerId } = useParams();
  const [tabs] = useState(['OVERVIEW'])
  const [selectedIndex, setSelectedIndex] = useState(0)

  // States
  const [customer, setCustomer] = useState(baseCustomerTemplate);
  const [changed, setChanged] = useState([]);
  const [staged, setStaged] = useState(baseCustomerTemplate);
  const [editing, setEditing] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [saved, setSaved] = useState(false)

  // Mutations
  const [updateCustomer, { customerdata }] = useMutation(UPDATE_CUSTOMER_MUTATION);
  const [deleteCustomer, { deleteCustomerdata }] = useMutation(DELETE_CUSTOMER_MUTATION);

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_CUSTOMER, {
    variables: { publisher: parseInt(currentpublisher.id), id: parseInt(customerId) },
  });


  // Functions
  const handleUpdateStaged = obj => {
    // check for an existing record in changed
    if (saved) { setSaved(false) }
    delete obj.val.__typename;
    delete obj.val.label;
    let newArr = changed.filter(item => item.hasOwnProperty("key") && item.key !== obj.key);
    newArr.push(obj)
    setChanged(newArr);
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }));
  }

  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  const resetForm = () => {
    setStaged(customer);
    setChanged([]);
    setSaved(false);
  }

  const handleUpdateCustomer = async () => {
    try {
      let updateResult = await updateCustomer(
        {
          variables: {
            id: parseInt(staged.id),
            changed: JSON.stringify(changed),
          }
        });
      toaster.closeAll()

      toaster.success(updateResult.data.updateCustomer.message, { duration: 3 })
      setSaved(true)
      return "Saved"

    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error saving customer. Try again or contact developer", { duration: 5 })
      return error.message;
    }
  }

  const handleDeleteCustomer = async () => {
    try {
      const results = await deleteCustomer(
        {
          variables: {
            id: parseInt(staged.id),
          }
        });
      toaster.closeAll()
      toaster.success(`Customer ${results.data.deleteCustomer.message} deleted succesfully!`, { duration: 10 })
      navigate('/customers')

    }
    catch (error) {
      toaster.closeAll()
      toaster.danger("Error deleting Customer. Try again or contact developer", { duration: 5 })
      return error.message;
    }
  }


  const fieldDict = {
    "Address": { setName: "customeraddressSet" },
    "Email": { setName: "customeremailSet" },
    "Phone": { setName: "customerphonenumberSet" }
  }

  useEffect(() => {
    if (data && data.customer.length) {
      setCustomer(data.customer[0]);
      setStaged(data.customer[0]);
      const linkedModelCount = data.customer[0].orderSet.length
      if (linkedModelCount === 0) {
        setCanDelete(true)
      }
    }
  }, [data]);

  if (loading) return null;
  if (error) return `Error! ${error}`;
  if (data.customer.length === 0) return (
    <Pane >
      <Pane paddingX={16} paddingTop={16}>
        <Breadcrumbs pageLabel="CUSTOMER" sourceUrl="/customers" />
      </Pane>
      <Pane display="flex" justifyContent="center" marginTop={60}>
        <Card alignItems="center" width="50%" elevation={2}>
          <EmptyState
            background="light"
            title="Customer not found"
            orientation="vertical"
            icon={<InfoSignIcon size={12} color="#C1C4D6" />}
            iconBgColor="#EDEFF5" />
        </Card>

      </Pane>


    </Pane>);

  return (
    <Pane>
      {
        editing &&
        <LeavePagePrompt
          userid={userid}
          itemid={customerId}
          itemType="customer"
          when={!!(changed?.length)}
        />
      }
      <Pane width="100%">
        <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16}>
          <Breadcrumbs pageLabel="CUSTOMER" curPage={customer.id} sourceUrl="/customers" />
          <Pane marginTop={8} marginBottom={8} size={300} display="flex" justifyContent="space-between" >
            <Pane display="flex" alignItems="flex-end">
              <Paragraph marginLeft={4} size={300} fontSize={18} fontWeight="bold"> {customer.company}</Paragraph>
              <Paragraph marginLeft={8} size={300}><Badge color="neutral">ID: {customer.id}</Badge></Paragraph>
              <Paragraph size={300} marginLeft={8}>
                <Link to={{
                  pathname: '/orders',
                  state: { query: {customer__id: staged.id}, queryTitle: "Orders From " + staged.company, sourceURL: '/customers/' + staged.id, sourcePageTitle: "CUSTOMER " + staged.id }
                }}>
                  ALL ORDERS ({staged.orderSet ? staged.orderSet.length : "None"})
                </Link>
              </Paragraph>
            </Pane>
            <Pane>
              <SaveButtons
                itemType="customer"
                editing={editing}
                locked={customer.locked}
                itemId={staged.id}
                setEditing={setEditing}
                setCancelled={setCancelled}
                handleUpdate={handleUpdateCustomer}
                handleDelete={handleDeleteCustomer}
                canDelete={canDelete}
                deleteMsg={"Cannot delete customer if there are any linked orders."}
                resetForm={resetForm}
                refetch={refetch}
              />
            </Pane>
          </Pane>
        </Pane>
        <Pane padding={16} width="100%">
          <Tablist>
            {tabs.map((tab, index) => (
              <Tab
                aria-controls={`panel-${tab}`}
                isSelected={index === selectedIndex}
                key={tab}
                onSelect={() => setSelectedIndex(index)}
                className="custom-tab"
                fontWeight="bold"
              >
                {tab}
              </Tab>
            ))}
          </Tablist>
          <Pane>
            <Pane id="item-details" elevation={1}
              aria-labelledby={'OVERVIEW'}
              aria-hidden={0 !== selectedIndex}
              display={0 === selectedIndex ? 'flex' : 'none'}
              key={'OVERVIEW'}
              role="tabpanel"
              className="tab-content"
              maxWidth="calc(100vw - 232px)">
              <Pane display="flex" width="100%">
                <Pane marginRight={64}>
                  <EditCustomerForm
                    staged={staged}
                    handleUpdateStaged={handleUpdateStaged}
                    editing={editing}
                    editMode="edit"
                  />
                </Pane>
                <Pane>
                  <ContactInfo
                    contactType="customer"
                    staged={staged}
                    setStaged={setStaged}
                    fieldDict={fieldDict}
                    saved={saved}
                    setSaved={setSaved}
                    phoneSet={staged.customerphonenumberSet}
                    emailSet={staged.customeremailSet}
                    addressSet={staged.customeraddressSet}
                    editing={editing}
                    cancelled={cancelled}
                    refetch={refetch}
                  />
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </Pane>

  )
}

export default Customer;