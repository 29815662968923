import { gql } from '@apollo/client';

const CREATE_CUSTOMER_MUTATION = gql`
  mutation CreateCustomerMutation(
    $publisherId: Int!,
    $customer: String!
  ) {
    createCustomer(
      publisherId: $publisherId,
      customer: $customer
    ) {
      message
    }
  }
`;

export default CREATE_CUSTOMER_MUTATION;