import { createSlice } from '@reduxjs/toolkit';

export const currentpublisherSlice = createSlice({
  name: 'publisher',
  initialState: {
    value: {
    	id: false,
    	name: false
    }
  },
  reducers: {
    setcurrent: (state, action) => {
      if (action.payload.hasOwnProperty("__typename")) {
        delete action.payload.__typename;
      }
      const obj = Object.assign({}, state.value, action.payload);
      state.value = obj;
      // state.value = action.payload
    },
  },
})

export const { setcurrent } = currentpublisherSlice.actions;

export default currentpublisherSlice.reducer;