import { gql } from '@apollo/client';

const QUERY_NET_SALES_BY_DATE = gql`
  query NetSalesByDate($publisher: Int!, $startDate: String, $endDate: String) {
  	grosssalesbydate(publisher: $publisher, startDate: $startDate, endDate: $endDate) {
  		paidDate,
  		shipDate,
  		totalAmount,
  		salesperson {
  			value
  		}
  	}
    expensesbydate(publisher: $publisher, startDate: $startDate, endDate: $endDate) {
      expenseDate,
      product {
        id
      },
      amount
    }
  }
`;

export default QUERY_NET_SALES_BY_DATE;