import React from "react";
import { Link } from "react-router-dom";

import AddCustomer from "./AddCustomer";
import { SelectContactInfo } from "../../inputs/index.js";

import {
  Card,
  Pane,
  Paragraph,
  Popover,
  Button,
  Table


} from 'evergreen-ui';


function OrderCustomer({ newMode, order, staged, editing, handleChooseInfo }) {

  return (
    <Card id="customer-info" border="default" padding={16} margin={8} flex={4} backgroundColor="white" elevation={1}>
      <Pane display="flex" justifyContent="space-between">
        <Paragraph size={400} fontWeight="bold" color="#5e5e5e" marginBottom={8}>CUSTOMER INFORMATION</Paragraph>
        {!newMode &&<Popover
          content={({ close }) => (
            <Pane padding={8} width={150}> <Paragraph size={300}>{staged.customer && staged.customer.adminNotes}</Paragraph></Pane>
          )}
        >
           <Button>Account Notes</Button>
        </Popover>}
      </Pane>
      <Pane>
        <Paragraph size={300} color="grey">Company</Paragraph>
        {editing ? <AddCustomer mode={staged.customer ? "Change" : "Choose"} handleChooseInfo={handleChooseInfo} customer={staged.customer} />
          : staged.customer ?
            <Paragraph size={400}>
              <Link to={`/customers/${staged.customer.id}`}>
                {staged.customer.company && (staged.customer.company)}
              </Link>
            </Paragraph> : <Paragraph size={300} color="grey">
              (No Customer Attached to Order)
            </Paragraph>
        }
        <Table.Row marginTop={8} height="auto" paddingY={8} backgroundColor="#f9faff" >
          <Table.TextCell>
            Name
          </Table.TextCell>
          <Table.TextCell textAlign="right">
            {editing ?
              (staged.customer && <Pane>{staged.customer.firstName} {staged.customer.lastName}</Pane>) :
              (order.customer && <Pane>{order.customer.firstName} {order.customer.lastName}</Pane>)
            }
          </Table.TextCell>
        </Table.Row>
        <Table.Row height="auto" paddingY={8} backgroundColor="#F4F5F9">
          <Table.TextCell>
            Email
          </Table.TextCell>
          <Table.TextCell textAlign="right">
            {editing ?
              (staged.customer &&
                <SelectContactInfo
                  staged={staged.orderEmail}
                  handleChooseInfo={handleChooseInfo}
                  itemSet={staged.customer.customeremailSet}
                  itemType="email"
                />) :
              (staged.orderEmail && staged.orderEmail.value
              )}
          </Table.TextCell>
        </Table.Row>
        <Table.Row backgroundColor="#f9faff" height="auto" paddingY={8}>
          <Table.TextCell>
            Phone Number
          </Table.TextCell>
          <Table.TextCell textAlign="right">
            {editing ?
              (staged.customer &&
                <SelectContactInfo
                  staged={staged.orderPhone}
                  handleChooseInfo={handleChooseInfo}
                  itemSet={staged.customer.customerphonenumberSet}
                  itemType="phone"
                />) : (order.orderPhone && order.orderPhone.value)}
          </Table.TextCell>
        </Table.Row>
        <Table.Row height="auto" paddingY={8} backgroundColor="#F4F5F9">
          <Table.TextCell>
            Address
          </Table.TextCell>
          <Table.TextCell textAlign="right" flexGrow={3} >
            {editing ?
              (staged.customer &&
                <Pane >
                  <SelectContactInfo
                    staged={staged.orderAddress}
                    handleChooseInfo={handleChooseInfo}
                    itemSet={staged.customer.customeraddressSet}
                    itemType="address"
                  />
                  {staged.orderAddress &&
                    <Pane>
                      <Paragraph size={300}>
                        {["line1", "line2"].map(item => staged.orderAddress[item]).filter(Boolean).join(", ")}
                      </Paragraph>
                      <Paragraph size={300}>
                        {["city", "stateOrRegion"].map(item => staged.orderAddress[item]).filter(Boolean).join(", ")} {staged.orderAddress.zipcode}
                      </Paragraph>
                      <Paragraph size={300}>
                        {staged.orderAddress.country}
                      </Paragraph>
                    </Pane>

                  }

                </Pane>) : (order.orderAddress &&
                  <Pane>
                    <Paragraph size={300}>
                      {["line1", "line2"].map(item => order.orderAddress[item]).filter(Boolean).join(", ")}
                    </Paragraph>
                    <Paragraph size={300}>
                      {["city", "stateOrRegion"].map(item => order.orderAddress[item]).filter(Boolean).join(", ")} {order.orderAddress.zipcode}
                    </Paragraph>
                    <Paragraph size={300}>
                      {order.orderAddress.country}
                    </Paragraph>
                  </Pane>

              )}

          </Table.TextCell>
        </Table.Row>
      </Pane>


    </Card>)
}
export default OrderCustomer;