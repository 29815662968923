import { createSlice } from '@reduxjs/toolkit';

export const publisherGlobalDataSlice = createSlice({
  name: 'profile',
  initialState: {
    value: {
      publisherimprints: false,
      publishertags: false,
      producttypes: false,
      productseriess: false,
      publishercreators: false,
      paymentterms: false,
      customerclasses: false,
      shippingtypes: false,
      paymenttypes: false,
      orderstatuss: false,
      salespersons: false,
      expensecategorys: false,
      royaltycategorys: false,
      royaltyterms: false,
      currency: false,
      currencys: false,
      accountingMethod: false,
    },
  },
  reducers: {
    setpublisherGlobalData: (state, action) => {
      if (action.payload.hasOwnProperty("__typename")) {
        delete action.payload.__typename;
      }
      const obj = Object.assign({}, state.value, action.payload);
      state.value = obj;
      // state.value = action.payload
    },
  },
})

export const { setpublisherGlobalData } = publisherGlobalDataSlice.actions;

export default publisherGlobalDataSlice.reducer;