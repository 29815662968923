// Globally stored info
import { configureStore } from '@reduxjs/toolkit';
import currentpublisherReducer from '../redux/currentpublisher/currentpublisherSlice.js';
import publisherGlobalDataReducer from '../redux/currentpublisher/publisherGlobalDataSlice.js';
import onixGlobalDataReducer from '../redux/onix/onixGlobalDataSlice.js';
import publishersReducer from '../redux/publishers/publishersSlice.js';
import userReducer from '../redux/user/userSlice.js';
import profileReducer from '../redux/user/profileSlice.js';
import contribcodesReducer from '../redux/onixcontributorcodes/contribcodesSlice.js';

export default configureStore({
  reducer: {
    currentpublisher: currentpublisherReducer,
    publisherGlobalData: publisherGlobalDataReducer,
    onixGlobalData: onixGlobalDataReducer,
    publishers: publishersReducer,
    user: userReducer,
    profile: profileReducer,
    contribcodes: contribcodesReducer,
  },
})