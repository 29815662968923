export const baseCustomerTemplate = () => {
  return {
    id: "",
    publisher:"",
    firstName:"",
    lastName: "",
    company: "",
    outOfBusiness: false,
    paymentTerms: {
      id: "",
      value: ""
    },
    customerClass: {
      id: "",
      value: ""
    },
    adminNotes: "",
    locked: false,
    lastEditor: {
      id: "",
      email: ""
    },
    created: "",
    modified: "",
  }
}