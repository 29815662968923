import React from "react";
import { useSelector } from 'react-redux';
import { getCookie, makeDateString } from '../../util.js';

import {
  Pane,
  Paragraph,
  Button,
  Table,
} from 'evergreen-ui';

function Billing(props) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

  // const plans = [{ name: "Basic Plan", features: "11-25 products", price: "$25.00", stripeId: "price_1NYrUxBugJUMdg1KA3cSR06k" },
  // { name: "Basic Plus Plan", features: "26-100 products", price: "$69.00", stripeId: "price_1NYrVqBugJUMdg1KonU9Ufh9" },
  // { name: "Premium Plan", features: "101-500 products", price: "$299.00", stripeId: "price_1NYrX4BugJUMdg1Kkqf5mEHb" },
  // { name: "Premium Plus Plan", features: "501-1000 products", price: "$599.00", stripeId: "price_1NYrXqBugJUMdg1KhTCPKSjo" },
  // { name: "All Access Plan", features: "Unlimited products!", price: "$1299.00", stripeId: "price_1NYrYLBugJUMdg1KsUd1iBVg" },
  // ]

  const plans = [{ name: "Basic Plan", features: "11-25 products", price: "$25.00", stripeId: "price_1PPC2TBugJUMdg1KWE0EPvhF" },
    { name: "Basic Plus Plan", features: "26-100 products", price: "$69.00", stripeId: "price_1PPC2pBugJUMdg1KTugB7E7C" },
    { name: "Premium Plan", features: "101-500 products", price: "$299.00", stripeId: "price_1PPC3DBugJUMdg1KoVSZWcrG" },
    { name: "Premium Plus Plan", features: "501-1000 products", price: "$599.00", stripeId: "price_1PPC3dBugJUMdg1KzxKVo2yM" },
    { name: "All Access Plan", features: "Unlimited products!", price: "$1299.00", stripeId: "price_1PPC3xBugJUMdg1KURT9Mk3T" },
    ]

  const csrftoken = getCookie('csrftoken');

  const createCheckoutSession = (priceId) => {
    fetch('/create_checkout_session/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({ price: priceId })
    })
      .then(res => res.json())
      .then(data => {
        window.location.replace(data.message);
      });
  }

  const csrftoken2 = getCookie('csrftoken');

  const createPortalSession = () => {
    fetch('/create_portal_session/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken2
      },
      body: JSON.stringify({ billingId: publisherGlobalData.billingId })
    })
      .then(res => res.json())
      .then(data => {
        window.location.replace(data.message);
      });
  }



  const nextBillDate = publisherGlobalData.billingDate
    ? new Date(publisherGlobalData.billingDate)
    : new Date();
  

  return (
    <Pane display="flex" >
      <Pane >
        {!publisherGlobalData.billingId ? (
          <Pane>
            <Paragraph>You are on the free plan.</Paragraph>
            <Paragraph>You must upgrade to create and maintain more than 10 titles (each format counts as a separate title).</Paragraph>
          </Pane>
        ) : (
          <Pane>
            <Paragraph>You are on the {publisherGlobalData.plan}.</Paragraph>
            <Paragraph>Your subscription will renew on {makeDateString(new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 1)))}.</Paragraph>
            <Paragraph>To cancel, email info@workinglit.com.</Paragraph>
            <Paragraph marginTop={16}><Button onClick={e => createPortalSession()}>Manage Subscription</Button></Paragraph>
          </Pane>
        )}
      </Pane>


      <Pane marginLeft={16}>
        <Table.Head height={40} width={750} backgroundColor="#F4F5F9">
          <Table.TextCell>Plan </Table.TextCell>
          <Table.TextCell> Features </Table.TextCell>
          <Table.TextCell> Price per month </Table.TextCell>
          {!publisherGlobalData.billingId &&
            <Table.TextCell> Subscribe </Table.TextCell>}

        </Table.Head>
        {plans.map(plan => {
          return (

            <Table.Row key={plan.name} height={40} width={750}>
              <Table.TextCell>{plan.name} </Table.TextCell>
              <Table.TextCell>{plan.features}</Table.TextCell>
              <Table.TextCell> {plan.price}</Table.TextCell>
              {!publisherGlobalData.billingId &&
                <Table.TextCell> <Button onClick={e => createCheckoutSession(plan.stripeId)}>Choose Plan</Button></Table.TextCell>}

            </Table.Row>)
        })}


      </Pane>








    </Pane>

  );
}

export default Billing;
