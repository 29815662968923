import { gql } from '@apollo/client';

const QUERY_PRODUCT = gql`
  query Product($publisher: Int!, $id: Int!) {
    product(publisher: $publisher, id: $id) {
      id,
      frozen,
      title,
      subtitle,
      description,
      isbn,
      shipDate,
      publicationDate,
      status {
        id,
        value,
        description
      },
      format {
        id,
        value,
        description
      },
      formatDetail {
        id,
        value,
        description
      },
      retailPrice,
      wholesalePrice,
      inventoryQuantity,
      cartonQty,
      weight,
      width,
      height,
      thickness,
      pageCount,
      imprint {
        id,
        value
      },
      audience {
        id,
        value,
        description
      },
      audienceDetails,
      keySellingPoints,
      marketing,
      adminNotes,
      tags,
      edition,
      volume,
      series {
        id,
        value
      },
      bisac,
      outOfPrint,
      hasShippingCharges,
      coverImage,
      interiorImages,
      comps,
      locked,
      lastEditor {
        id,
        email
      },
      created,
      modified,
      productcreatorSet {
        id,
        creator {
          id,
          displayName,
        },
        onixContributorCode {
          id,
          value,
          description
        }
      }
      expenseSet {
        id,
        expenseDate,
        amount
      }
      royaltySet {
        id,
        creator {
          id,
          displayName
        }
      }
      orderproductSet {
      id,
      }
    }
  }
`;

export default QUERY_PRODUCT;