import React, { useState, useEffect, useCallback } from "react";

import {
  Pane,
  Paragraph,
  TextInputField,
  Checkbox,
  Badge,
  IconButton,
  Select,
  TrashIcon,
  MapMarkerIcon
} from 'evergreen-ui';

function AddressItem(props) {
  const [cleandata, setCleandata] = useState({});
  const isPrimary = props.address.primary && props.address.primary === true
    ? true
    : false;

  const handleUpdateStaged = obj => {
    setCleandata(oldState => ({ ...oldState, [obj.key]: obj.val }));
  }

  const handleCleanData = useCallback(() => {
    let fields = ["id", "tmpid", "primary", "line1", "line2", "city", "stateOrRegion", "zipcode", "country", "addressType"];
    let cleandata = {};
    fields.forEach(field => {
      if (!props.address[field]) {
        cleandata[field] = "";
      } else {
        cleandata[field] = props.address[field];
      }
    });
    return cleandata;
  },[props.address])

  const handleUpdateAddress = obj => {
    handleUpdateStaged(obj);
    let updated = { ...cleandata, [obj.key]: obj.val };
    props.handleUpdateAddressItem(updated, "Address");
  }

  let addressType = "";
  if (cleandata.hasOwnProperty('addressType') && props.itemType === "customer") {
    if (props.editing) {
      addressType = (
        <AddressType
          objKey="addressType"
          handleUpdateAddressItem={handleUpdateAddress}
          myKey="addressType"
          value={cleandata.addressType}
          editing={props.editing} />);
    } else {
      addressType = (<Paragraph><Badge color="neutral">{cleandata.addressType ? cleandata.addressType : "OTHER"}</Badge></Paragraph>);
    }
  }

  useEffect(() => {
    let data = handleCleanData();
    setCleandata(data);
  }, [props.address, handleCleanData]);

  useEffect(() => {
    if (props.cancelled === true) {
      let data = handleCleanData();
      setCleandata(data);
    }
  }, [props.cancelled, handleCleanData]);

  return (
    <Pane padding={8}>

      <Pane display="flex" flexDirection="column">
        
        {props.editing ?
          <Pane>
            <Pane display="flex">
              <Checkbox
                label="Primary Address?"
                checked={isPrimary}
                onChange={e => {
                  handleUpdateAddress({ key: "primary", val: !isPrimary })
                }}
              />
              {cleandata.hasOwnProperty('addressType') && addressType}

              <IconButton icon={TrashIcon} intent="danger" onClick={e => props.handleDelete(cleandata)} marginLeft={8} />


            </Pane>
            <Pane display="flex" marginBottom={8}>
              <Pane>
                <Paragraph size={300} color="#667b7f">Line 1</Paragraph>
                <AddressLine label="Line 1" objKey="line1" value={cleandata.line1} editing={props.editing} handleUpdateAddressItem={handleUpdateAddress} />
              </Pane>
              <Pane marginLeft={8}>
                <Paragraph size={300} color="#667b7f">Line 2</Paragraph>
                <AddressLine label="Line 2" objKey="line2" value={cleandata.line2} editing={props.editing} handleUpdateAddressItem={handleUpdateAddress} />
              </Pane>
            </Pane>
            <Pane display="flex" marginBottom={8}>
              <Pane>
                <Paragraph size={300} color="#667b7f">City</Paragraph>
                <AddressLine objKey="city" value={cleandata.city} editing={props.editing} handleUpdateAddressItem={handleUpdateAddress} />
              </Pane>
              <Pane marginLeft={8}>
                <Paragraph size={300} color="#667b7f" >State/Region</Paragraph>
                <AddressLine objKey="stateOrRegion" value={cleandata.stateOrRegion} editing={props.editing} handleUpdateAddressItem={handleUpdateAddress} />
              </Pane>
            </Pane>
            <Pane display="flex">
              <Pane>
                <Paragraph size={300} color="#667b7f">Postal Code</Paragraph>
                <AddressLine objKey="zipcode" value={cleandata.zipcode} editing={props.editing} handleUpdateAddressItem={handleUpdateAddress} />
              </Pane>
              <Pane marginLeft={8}>
                <Paragraph size={300} color="#667b7f">Country</Paragraph>
                <AddressLine objKey="country" value={cleandata.country} editing={props.editing} handleUpdateAddressItem={handleUpdateAddress} />
              </Pane>
            </Pane>
          </Pane>
          :

          <Pane>
            <Pane display="flex" justifyContent="space-between" alignItems="center" marginBottom={8}>
              <Pane display="flex" alignItems="center">
                <MapMarkerIcon color={isPrimary ? "success" : "muted"} marginRight={8}/>
                {cleandata.hasOwnProperty('addressType') && addressType}


              </Pane>


              {isPrimary && (
                <Paragraph size={300}  color="var(--robinegg)">(Primary)</Paragraph>
              )}

            </Pane>
            <Paragraph size={400}>
              {["line1", "line2"].map(item => cleandata[item]).filter(Boolean).join(", ")}
            </Paragraph>
            <Paragraph size={400}>
              {["city", "stateOrRegion"].map(item => cleandata[item]).filter(Boolean).join(", ")} {cleandata.zipcode}
            </Paragraph>
            <Paragraph size={400}>
              {cleandata.country}
            </Paragraph>
          </Pane>}


      </Pane>

    </Pane>

  )
}

const AddressLine = props => {
  return (
    <Pane>
      <TextInputField
        label=""
        className="item-form"
        onChange={e => props.handleUpdateAddressItem({ key: props.objKey, val: e.target.value })}
        value={props.value}
        disabled={props.editing ? false : true}
        marginY={0}
      />
    </Pane>
  )
}


const AddressType = props => {
  return (
    <Pane marginRight={32}>
      <Paragraph size={300} color="#667b7f">Address Type</Paragraph>

      <Select
        value={props.value}
        onChange={e => props.handleUpdateAddressItem({ key: props.objKey, val: e.target.value })}
      >
        <option value="">None</option>
        <option value="Shipping">Shipping</option>
        <option value="Billing">Billing </option>
        <option value="Other">Other</option>
      </Select>

    </Pane>

  )
}

export default AddressItem;