import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { sumArray, getActualAmountArr } from '../../util.js';

import {
  IconButton,
  TextInput,
  Table,
  Badge,
  TickIcon,
  EditIcon,
  CrossIcon,
  TrashIcon,
  Text,
  Paragraph,
  Pane,
  SelectField
} from 'evergreen-ui';

const OrderProduct = props => {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

  const item = props.item;
  const product = item.product;
  const discounted = parseFloat(item.calcDiscount) !== 0 && item.calcDiscount;

  const [editing, setEditing] = useState(false);
  const [discount, setDiscount] = useState(
    parseFloat(item.discountPerUnit) !== 0 && item.discountPerUnit
      ? "percent"
      : item.netPrice
        ? "net"
        : "retail"
  );

  const [staged, setStaged] = useState(props.item)

  const handleUpdateStaged = (key, value) => {
    setStaged(oldState => ({ ...oldState, [key]: value }))
  }

  const handleUpdate = () => {
    
    staged.calcDiscount = 0;
    if (staged.netPrice) {
      staged.calcDiscount = 100*(staged.retailPrice - staged.netPrice)/(staged.retailPrice)
    } else if (staged.discountPerUnit) {
      staged.calcDiscount = staged.discountPerUnit
    }
    staged.calcPrice = staged.retailPrice * (100 - staged.calcDiscount)/100
    props.handleUpdateOrderProduct(staged);
    setEditing(false);
  }

  const handleDelete = () => {
    props.handleDeleteOrderProduct(staged);
    setEditing(false);
  }

  let quantity = editing
    ? (<TextInput width={60} marginTop={12} value={staged.quantity} onChange={e => handleUpdateStaged('quantity', e.target.value)} />)
    : item && item.quantity !== undefined
      ? item.quantity
      : "0";
  let total_amount_arr = item
    ? getActualAmountArr([item])
    : [0];

  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

    useEffect(() => {
      if (!props.editing) {
        setEditing(false)
        setStaged(item)
        setDiscount(parseFloat(item.discountPerUnit) !== 0 && item.discountPerUnit
        ? "percent"
        : item.netPrice
          ? "net"
          : "retail")

      }
    }, [item, props.editing])


  return (
    <Table.Row key={item.id ? `opRow${item.id}` : `opTempRow${item.tmpid}`} className="table-row">
      <Table.TextCell flex={props.editing ? 4 : 3} >
        <Pane display="flex" marginBottom={4}>
          <Paragraph size={300} className="breakable block"><Link to={`/products/${product.id}`}>{product.title}{product.subtitle && `: ${product.subtitle}`}</Link>  </Paragraph>
          {props.editing && <Badge marginLeft={4}>{item && product.format ? product.format.description : "No Format"}</Badge>}

        </Pane>
        {product.isbn && props.editing && <Text size={300} className="block" color="grey">ISBN {product.isbn}</Text>}
      </Table.TextCell>
      {!props.editing && <Table.TextCell flex="3" fontWeight="bold">
        <Badge>{item && product.format ? product.format.description : "No Format"}</Badge>
      </Table.TextCell>}
      {!props.editing && <Table.TextCell isNumber flex="3" fontWeight="bold">
        {product.isbn && <Text size={300} className="block" color="grey">ISBN {product.isbn}</Text>}
      </Table.TextCell>}
      <Table.TextCell isNumber textAlign="right" justifyContent="flex-end" flex="2">{quantity}</Table.TextCell>

      <Table.TextCell isNumber={!editing} textAlign={editing ? "left" : "right"} flex="6">
        {editing ?
          <Pane display="flex" marginTop={8} justifyContent="flex-end">
            <Pane display="flex" flexDirection="column">
              <Paragraph color="#667b7f" size={300} > Discount Type </Paragraph>
              <SelectField
                width={150}
                value={discount}
                defaultValue={"retail"}
                label=""
                onChange={e => {
                  setDiscount(e.target.value)
                  setStaged(oldState => ({
                    ...oldState,
                    "discountPerUnit": "",
                    "netPrice": ""
                  }))


                }}
              >
                <option value="retail"> None (Retail Price) </option>
                <option value="percent">Discount</option>
                <option value="net">Net Price</option>
              </SelectField>
            </Pane>
            {discount === "net" && (
              <Pane marginLeft={16}>
                <Paragraph marginLeft={8} color="#667b7f" size={300} marginBottom={8} >Net Price </Paragraph>
                <Paragraph marginBottom={8}>
                  {currency} <TextInput width={75} value={staged.netPrice}
                    onChange={e => handleUpdateStaged('netPrice', e.target.value)

                    }
                  />
                </Paragraph>

              </Pane>
            )}
            {discount === "percent" && (
              <Pane marginLeft={16}>
                <Paragraph color="#667b7f" size={300} marginBottom={8} > Discount</Paragraph>
                <Paragraph >
                  <TextInput width={75} value={staged.discountPerUnit}
                    onChange={e => handleUpdateStaged('discountPerUnit', e.target.value)} /> %
                </Paragraph>
              </Pane>
            )}
            {discount === "retail" && (
              <Pane marginLeft={16}>
                <Paragraph color="#667b7f" size={300} marginBottom={8} > Retail Price </Paragraph>
                <Paragraph marginBottom={8}>
                  {currency} <TextInput width={75} value={item.retailPrice} disabled />
                </Paragraph>
              </Pane>
            )}
          </Pane>

          :

          <Pane>
            <Pane size={300} className="block" fontWeight="bold">{currency}
              {parseFloat(item.calcPrice).toFixed(2)}
            </Pane>
            {discounted &&
              <Paragraph size={300} className="block" color="grey"> MSRP {currency}{parseFloat(item.retailPrice).toFixed(2)} at {parseFloat(item.calcDiscount).toFixed(2).replace(/[.,]00$/, "")}% DISC </Paragraph>
            }
          </Pane>}

      </Table.TextCell>
      <Table.TextCell isNumber textAlign="right" flex="2">{currency}{!isNaN(sumArray(total_amount_arr)) ? sumArray(total_amount_arr).toFixed(2) : "0.00"}</Table.TextCell>
      {props.editing && (editing ? (
        <Table.TextCell flex="3">
          <IconButton marginLeft={8} icon={TickIcon} onClick={e => handleUpdate()}>Save</IconButton>
          <IconButton marginLeft={8} icon={CrossIcon} onClick={e => setEditing(false)}>Cancel</IconButton>
        </Table.TextCell>
      ) : (
        <Table.TextCell flex="3">
          <IconButton marginLeft={8} icon={EditIcon} onClick={e => setEditing(true)}>Edit</IconButton>
          <IconButton marginLeft={8} icon={TrashIcon} intent="danger" onClick={e => handleDelete()}>Delete</IconButton>
        </Table.TextCell>
      ))}
    </Table.Row>
  )
}

export default OrderProduct;