import { gql } from '@apollo/client';

const CREATE_ORDER_PRODUCT_MUTATION = gql`
  mutation CreateOrderProductMutation(
    $orderId: Int!,
    $productId: Int!,
    $orderProduct: String!
  ) {
    createOrderProduct(
      orderId: $orderId,
      productId: $productId,
      orderProduct: $orderProduct
    ) {
      message
    }
  }
`;

export default CREATE_ORDER_PRODUCT_MUTATION;