import { gql } from '@apollo/client';

const CREATE_ROYALTY_LINE_ITEM_MUTATION = gql`
  mutation CreateRoyaltyLineItemMutation(
    $royaltyId: Int!,
    $royaltyItem: String!
  ) {
    createRoyaltyLineItem(
      royaltyId: $royaltyId,
      royaltyItem: $royaltyItem
    ) {
      message
    }
  }
`;

export default CREATE_ROYALTY_LINE_ITEM_MUTATION;