import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: {},
  },
  reducers: {
    setuser: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { setuser } = userSlice.actions;

export default userSlice.reducer;