import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { storage } from "../firebase_setup/firebase";
import { ref, getDownloadURL } from "firebase/storage";

import {
  Avatar,
  MediaIcon,
  Pane,
} from 'evergreen-ui';

function CloudImage(props) {
  const [imageUrl, setImageUrl] = useState("");
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

  const hasImage = !props.path || props.path === "" || props.path === "https://www.hederis.com/images/nellie.png"
    ? false
    : true;

  useEffect(() => {
    if (hasImage) {
      const path = props.path;
      if (publisherGlobalData.firebaseAuth){
        const getImage = async () => {
          try {
            const imgRef = ref(storage, path)
            const res = await getDownloadURL(imgRef)
            setImageUrl(res)
          } catch (error) {
            const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
          }
        }
        getImage() 

      }
        
    }
  }, [hasImage, props.path, publisherGlobalData.firebaseAuth]);

  let imgComp;

  if (props.avatar) {
    imgComp = <Avatar src={imageUrl} size={props.size ? props.size : 50} name={hasImage && !publisherGlobalData.firebaseAuth ? "??": props.name}></Avatar>
  } else {
    imgComp =
    <Pane>
      {hasImage ? (
        <Pane className={`profileHolder${props.customClass}`}>
          <img className={`profile${props.customClass}`} src={imageUrl} alt={"alt-text"} />
        </Pane>
      ) : (
        <Pane className={`profileHolder${props.customClass}`} display="flex" justifyContent="center" alignItems="center" backgroundColor="var(--medgrey)" >
          <MediaIcon size={16} color="var(--darkgrey)" />
        </Pane>
      )}
    </Pane>
  }

  return imgComp
}



export default CloudImage;