import { gql } from '@apollo/client';

const QUERY_CREATOR = gql`
  query Creator($publisher: Int!, $id: Int!) {
    creator(publisher: $publisher, id: $id) {
      id,
      created,
      displayName,
      paymentName,
      photoUrl,
      residence,
      locked,
      paymentType {
        id,
        value
      },
      biography,
      creatoremailSet {
        id,
        value,
        primary
      }
      creatorphonenumberSet {
        id,
        value,
        primary
      }
      creatoraddressSet {
        id,
        line1,
        line2,
        city,
        stateOrRegion,
        country,
        zipcode,
        primary
      }
      royaltystatementSet {
        id,
        prodArray,
        pubNote,
        sentDate,
        startDate,
        endDate,
        products {
          id,
          title
        }
      },
      royaltySet {
        id,
        owed,
        paid
      }
      productcreatorSet {
      id,
      product{
      id
      }
      }
    }
    creatorroyaltypayments(creator: $id, page: 1) {
      id,
      amount,
      sentDate,
      method {
        id,
        value
      },
      royalty {
        id,
        product {
          id,
          title,
          retailPrice,
          format {
            id,
            value,
            description
          },
        },
        royaltylineitemSet {
          id,
          amount,
          thresholdStart,
          royaltyTerm {
            id,
            value
          },
          category {
            id,
            value
          },
        },
        locked,
        lastEditor {
          id,
          email
        },
        created,
        modified
      }
    }
  }
`;

export default QUERY_CREATOR;