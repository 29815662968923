import React, { useState } from "react";

import {
  Pane,
  TextInputField,
  Checkbox,
  IconButton,
  AddIcon,
  CrossIcon
} from 'evergreen-ui';

function NewContactInfoForm(props) {
  const baseObject = {
    value: "",
    primary: false,
    id: ""
  }
  const [staged, setStaged] = useState(baseObject);  

  const handleClearStaged = () => {
    setStaged(baseObject);
    props.close();
  }
  
  const handleCreateItem = () => {
    props.handleNewItem(staged);
    handleClearStaged();
  }

  const handleUpdateStaged = obj => {
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }))
  }

  return (
    <Pane display="flex" padding={16}>
      <Pane>
      
      <TextInputField
        label=""
        className="item-form"
        placeholder={props.fieldtype === "Email" ? "e.g. jandoe@mail.com" : "e.g. 1-234-567-8910"}
        onChange={e => handleUpdateStaged({ key: "value", val: e.target.value })}
        value={staged.value}
        marginTop={0}
        marginBottom={8}       
      />
      <Checkbox
        marginY={0}
        label="Primary?"
        checked={staged.primary}
        onChange={e => handleUpdateStaged({ key: "primary", val: !staged.primary })}
      />
       </Pane>
      <IconButton icon={AddIcon} disabled={!staged.value.length} marginX={8} marginTop={8} intent="success" appearance="primary" onClick={e => {
        handleCreateItem(); 
      }}/>
      <IconButton icon={CrossIcon} marginTop={8} intent="danger" onClick={e => {
        handleClearStaged();
      }}/>

    </Pane>
    
      
    
  )
}

export default NewContactInfoForm;
