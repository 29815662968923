import React, { useState, useEffect } from "react";

import {
  Button,
  SelectMenu,
} from 'evergreen-ui';
import {NotSeeingItems} from "../../components/NotSeeingItems";

function SelectRoyalty(props) {
  const [selected, setSelected] = useState('');
  const [royaltys, setRoyaltys] = useState(props.royaltys)

  useEffect(() => {
    setSelected('')
    setRoyaltys(props.royaltys);
  }, [props.royaltys]);

  const prodFormat = product => {return product.format ? product.format.description : "No Format"}

  return (
    <SelectMenu
      title=""
      titleView={({ close, headerHeight }) => {
        return (
          <NotSeeingItems headerHeight={headerHeight} close={close} items="royalties" parentItem={props.parent}/>
        )
      }}
      options={royaltys.map((item) => ({
        key: "royalty" + item.id,
        label: `[${item.id}] ${item.product.title} (${prodFormat(item.product)})`,
        value: JSON.stringify(item)
      }))}
      selected={selected}
      closeOnSelect={true}
      onSelect={(option) => {
        setSelected(option.label)
        props.handleNewRoyalty(option.value)
      }}>
      <Button disabled={!props.creator}>{selected ? selected : 'Select Royalty...'}</Button></SelectMenu>
  )
}

export default SelectRoyalty;