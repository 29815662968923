import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from '@apollo/client';
import LOCK_ITEM_MUTATION from "../graphql/mutations/LockItem.js";
import { Dialog, Pane } from 'evergreen-ui';

function LeavePagePrompt(props) {
  const [isShown, setIsShown] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const [lockItem] = useMutation(LOCK_ITEM_MUTATION);
  const { userid, itemid, itemType } = props;
  const navigate = useNavigate();

  useEffect(() => {
    // Lock the item and navigate if confirmed
    if (lastLocation && confirmedNavigation) {
      lockItem({
        variables: {
          userId: parseInt(userid, 10),
          itemType: itemType,
          itemId: parseInt(itemid, 10),
          status: false,
        }
      });
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, userid, itemid, itemType, lockItem, navigate]);

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && props.when) {
      setIsShown(true);
      setLastLocation(nextLocation);
      return false; // Block navigation
    }
    return true; // Allow navigation
  };

  const handleConfirmNavigationClick = () => {
    if (lastLocation) {
      setConfirmedNavigation(true);
    }
  };

  const handleCancelClick = () => {
    setIsShown(false);
    setLastLocation(null);
  };

  // Listen for navigation events
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (props.when && !confirmedNavigation) {
        event.preventDefault();
        event.returnValue = ''; // Show confirmation dialog
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [props.when, confirmedNavigation]);

  return (
    <Pane>
      <Dialog
        isShown={isShown}
        title={props.title ?? "You have unsaved changes."}
        onConfirm={handleConfirmNavigationClick}
        onCancel={handleCancelClick}
      >
        {props.message ?? "Leave page without saving?"}
      </Dialog>
    </Pane>
  );
}

export default LeavePagePrompt;