import React, { useState } from "react";
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { setpublisherGlobalData } from '../../redux/currentpublisher/publisherGlobalDataSlice.js';
import CreatorForm from "./CreatorForm.js";
import CREATE_CREATOR_MUTATION from "../../graphql/mutations/Creators/CreateCreator.js";

import {
  Pane,
  TextInputField,
  Button,
  Paragraph,
  Card,
  Spinner
} from 'evergreen-ui';

function NewCreator(props) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const dispatch = useDispatch();

  const [staged, setStaged] = useState({
    onixCodeId: "1",
    displayName: "",
    paymentName: "",
    photoUrl: "",
    residence: "",
    biography: "",
    email: "",
    phone: "",
    paymentType: ""
  });

  const [createCreator, { creatordata }] = useMutation(CREATE_CREATOR_MUTATION);

  const handleClearStaged = () => {
    setStaged({
      onixCodeId: "1",
      displayName: "",
      paymentName: "",
      photoUrl: "https://www.hederis.com/images/nellie.png",
      residence: "",
      biography: "",
      email: "",
      phone: "",
      paymentType: ""
    });
  }

  const handleCreateCreator = async () => {
    const results = await createCreator(
      {
        variables: {
          publisherId: parseInt(props.publisher),
          onixCodeId: parseInt(staged.onixCodeId),
          displayName: staged.displayName,
          paymentName: staged.paymentName,
          photoUrl: staged.photoUrl,
          biography: staged.biography,
          residence: staged.residence,
          email: staged.email,
          phone: staged.phone
        }
      });
    handleClearStaged();
    // add to the creator list, for products etc.
    let newCreatorList = [{ displayName: staged.displayName, id: results.data.createCreator.id }].concat(publisherGlobalData.publishercreators);
    dispatch(setpublisherGlobalData({ ...publisherGlobalData, publishercreators: newCreatorList }));
    // refetch the list of creators to include new one
    props.handleRefetchCreators();
    props.handleToggleNewMode();
  }

  const handleUpdateStaged = obj => {
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }))
  }

  return (
    <Pane backgroundColor="var(--background)" width="100%" height="100%">
      <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16} paddingBottom={8} width="100%" display="flex" justifyContent="space-between" alignItems="flex-end">
        <Paragraph fontSize={18}> New Creator </Paragraph>
        <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
          {creatordata?.loading && <Spinner size={24} marginRight={8} />}
          <Button className="confirm-button" marginRight={16} onClick={e => {
            handleCreateCreator();
          }}>
            CREATE CREATOR
          </Button>
          <Button onClick={e => {
            handleClearStaged();
            props.handleToggleNewMode();
          }}>
            Cancel
          </Button>
        </Pane>

      </Pane>
      <Pane padding={16}>
        <Card backgroundColor="white" paddingX={16} paddingTop={16} elevation={1}>
          <CreatorForm
            staged={staged}
            handleUpdateStaged={handleUpdateStaged}
            editing={true}
          />
          <Pane display="flex">
            <Pane marginRight={32}>
              <Paragraph size={300} color="#667b7f">Primary Email Address</Paragraph>
              <TextInputField
                label=""
                className="item-form"
                onChange={e => handleUpdateStaged({ key: 'email', val: e.target.value })}
                value={staged.email}
                width={200}
              />
            </Pane>
            <Pane>
              <Paragraph size={300} color="#667b7f">Primary Phone Number</Paragraph>
              <TextInputField
                label=""
                className="item-form"
                onChange={e => handleUpdateStaged({ key: 'phone', val: e.target.value })}
                value={staged.phone}
                width={200}
              />
            </Pane>




          </Pane>

        </Card>

      </Pane>



    </Pane>
  )
}

export default NewCreator;