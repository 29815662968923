import React from "react";

import { Card, Pagination, Paragraph } from "evergreen-ui";

function PaginationDisplay({ currpage, pagecount, setCurrpage, searchCount, count }) {
    return (
    <Card display="flex" justifyContent="center" alignItems="center">

        <Pagination
            page={currpage}
            totalPages={pagecount}
            onPageChange={e => setCurrpage(parseInt(e))}
            onNextPage={e => setCurrpage(currpage + 1)}
            onPreviousPage={e => setCurrpage(currpage - 1)}
        >
        </Pagination>
        <Paragraph size={300}>Displaying: {1 + (currpage - 1) * 25}-{Math.min(25 + (currpage - 1) * 25, searchCount)} out of {count} </Paragraph>
    </Card>)
}

export default PaginationDisplay;