import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { getCookie, sanitizeRecord, getLocationQuery } from '../../util.js';
import Sort from "./Sort.js";
import OrderItem from "./OrderItem.js";
import NewOrder from "./NewOrder.js";
import CsvDownload from "../../components/layout/CsvDownload.js";
import CsvUpload from "../../components/layout/CsvUpload.js";
import SearchOrders from "./SearchOrders.js";
import QUERY_ORDERS from "../../graphql/queries/Orders/QueryOrders.js";
import Breadcrumbs from "../../components/layout/Breadcrumbs.js";


import {
  Pane,
  Paragraph,
  Button,
  Pill,
  AddIcon,
  DocumentShareIcon,
  Spinner,
} from 'evergreen-ui';
import PaginationDisplay from "../../components/layout/PaginationDisplay.js";

function Orders(props) {
  const location = useLocation();
  const [sourcePageInfo, setSourcePageInfo] = useState(false)
  const navigate = useNavigate();

  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [searchCount, setSearchCount] = useState(0);
  const [pagecount, setPagecount] = useState(0);

  const [currpage, setCurrpage] = useState(1);

  // selection
  const [newmode, setNewmode] = useState(false);
  const [csvmode, setCsvmode] = useState(false);
  const [allmode, setAllmode] = useState(false);

  const [selected, setSelected] = useState([]);
  const [excluded, setExcluded] = useState([]);

  const [params, setParams] = useState({});
  const [sortOrder, setSortOrder] = useState({ sort_order: null });
  const [sortDirection, setSortDirection] = useState(null);

  const [tableHeight, setTableHeight] = useState(0)

  const upperComps = useRef(null);
  const lowerComps = useRef(null);


  const sortInputs = {
    id: { formLabel: "ID" },
    order_status__value: { formLabel: "Status" },
    customer__company: { formLabel: "Customer" },
    paid: { formLabel: "Paid" },
    shipped: { formLabel: "Shipped" },
    backordered: { formLabel: "Backordered" },
    salesperson__value: { formLabel: "Salesperson" },
    created: { formLabel: "Date Created" },
  }

  const handleToggleNewMode = () => {
    setNewmode(!newmode);
  }

  const handleSelectAll = () => {
    //   // empty both arrays
    setSelected([]);
    setExcluded([]);
    setAllmode(!allmode);
    //   // set all visible items to checked
  }

  const handleAddSelected = item => {
    const newitem = [sanitizeRecord(JSON.parse(JSON.stringify(item)))];
    if (newitem.length) {
      const newSelect = excluded.filter(val => !newitem.find(({ id }) => val.id === id));
      setExcluded(newSelect);
      setSelected(selected.concat(newitem));
    } else {
      setSelected(selected.concat(newitem));
    }
  }

  const handleRemoveSelected = item => {
    // find the item in the current array
    const newarr = selected.filter(obj => obj.id !== item.id);
    setSelected(newarr);
  }

  const handleAddExcluded = item => {
    const newitem = [item];
    setExcluded(excluded.concat(newitem));
  }

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_ORDERS, {
    variables: { publisher: parseInt(currentpublisher.id), page: parseInt(currpage), params: JSON.stringify(params) },
  });
  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  const handleClearSearch = () => {
    setParams({})
    refetch()
  }

  const handleRefetchOrders = () => {
    refetch();
  }

  const handleSearchOrders = (searchParams) => {
    setParams({ ...searchParams })
    refetch();
  }

  const handleSetSortField = selected => {
    setSortOrder({ sort_order: selected });
  }

  const handleSetSortDirection = selected => {
    setSortDirection(selected);
  }

  const handleSort = evt => {
    setParams(oldState => ({ ...oldState, sort_order: sortOrder.sort_order, sort_direction: sortDirection }))
    handleRefetchOrders();
  }

  const handleGetOrderCount = useCallback(() => {
    const csrftoken = getCookie('csrftoken');
    fetch('/count_orders/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({ publisher: currentpublisher.id })
    })
      .then(res => res.json())
      .then(data => {
        setCount(parseInt(data.message));
        setPagecount(Math.ceil(parseInt(data.message) / 25))

      })
  }, [currentpublisher.id])

  // ------- CSV Stuff

  const handleToggleCsvMode = () => {
    setCsvmode(!csvmode);
    refetch();

  }

  // if you make changes to these arrays, be sure to update the lists in views.py as well
  const csvFields = [
    "adminNotes",
    "created",
    "customer",
    "id",
    "modified",
    "orderAddress",
    "orderEmail",
    "orderPhone",
    "orderStatus",
    "orderpaymentSet",
    "paid",
    "paidDate",
    "paymentTerm",
    "poNumber",
    "salesperson",
    "shipDate",
    "shipped",
    "shippingCost",
    "shippingType",
    "totalAmount"
  ];

  const csvConfig = {
    "shippingType": ["value"],
    "paymentTerm": ["value"],
    "salesperson": ["value"],
    "orderStatus": ["value"],
    "orderPhone": ["value"],
    "orderEmail": ["value"],
    "orderAddress": ["line1", "line2", "city", "stateOrRegion", "country", "zipcode"],
    "customer": ["id", "firstName", "lastName", "company"],
    "sets": {
      "orderproductSet": {
        "fields": ["id", "quantity", "discountPerUnit"],
        "subfields": {},
        "label": "products"
      },
      "orderpaymentSet": {
        "fields": ["amount"],
        "subfields": {},
        "label": "payments"
      }
    },
    "primarySets": {},
    "arrays": {}
  }


  const csvTemplate = [
    [
      "order_group",
      "isbn",
      "quantity",
      "retail_price",
      "net_price",
      "order_status",
      "po_number",
      "payment_term",
      "salesperson",
      "shipping_type",
      "shipping_cost",
      "admin_notes",
      "paid",
      "paid_date",
      "shipped",
      "ship_date",

    ],
    [
      "All products in the same order should have the same order_group number (REQUIRED)",
      "Product ISBN (REQUIRED)",
      "Product Quantity (REQUIRED)",
      "Retail Price for item at the time of order (REQUIRED)",
      "Price paid by customer once any discounts are applied",
      "The exact name of the status as it exists in WorkingLit",
      "An optional PO number to associate with the order",
      "The exact name of the payment term as it exists in WorkingLit",
      "The exact name of the salesperson as it exists in WorkingLit",
      "The exact name of the shipping type as it exists in WorkingLit",
      "Numbers and periods only; e.g. 12.99",
      "",
      "true or false",
      "yyyy-mm-dd",
      "true or false",
      "yyyy-mm-dd",

    ],
    [
      "1",
      "9781683963967",
      "10",
      "9.99",
      "",
      "Ready",
      "AB-95647",
      "Net 60",
      "Elly Blue",
      "UPS Ground",
      "49.60",
      "",
      "true",
      "2023-12-03",
      "false",
      "",

    ],
    [
      "1",
      "9781683963564",
      "25",
      "15.99",
      "",
      "Ready",
      "AB-95647",
      "Net 60",
      "Elly Blue",
      "UPS Ground",
      "49.60",
      "",
      "true",
      "2023-12-03",
      "false",
      "",

    ],
    [
      "1",
      "978168392567",
      "25",
      "13.99",
      "10.99",
      "Ready",
      "AB-95647",
      "Net 60",
      "Elly Blue",
      "UPS Ground",
      "49.60",
      "",
      "true",
      "2023-12-03",
      "false",
      "",

    ],
    [
      "2",
      "6437890",
      "25",
      "4.95",
      "",
      "Backordered",
      "",
      "Net 30",
      "Joe Biel",
      "USPS Media Mail",
      "5.50",
      "",
      "true",
      "2023-11-15",
      "true",
      "2023-12-25",
    ]
  ];

  const csvWidths = [
    3,
    3,
    3,
    3,
    3,
    3,
    3,
    3,
    2,
    3,
    2,
    3,
    3,
    2,
    3,
    3
  ]
  const numSelected = allmode ? count - excluded.length : selected ? selected.length : 0

  // ------- HOOKS

  useEffect(() => {
    if (location.state) {
      let locState = location.state;
      setSourcePageInfo(locState)
      getLocationQuery(locState, setParams);
    }
  }, [location.state]);

  useEffect(() => {
    if (data) {
      setOrders(data.orders);
      setSearchCount(data.orders.length)
      handleGetOrderCount();
    }
  }, [data, handleGetOrderCount, navigate]);

  useEffect(() => {
    if (upperComps.current && lowerComps.current) {

      setTableHeight(window.innerHeight - (upperComps.current.clientHeight + lowerComps.current.clientHeight) - 20)
    }

  }, [sourcePageInfo])

  if (error) return `Error! ${error}`;

  return (
    <Pane position="absolute" padding={16} width="100%" height="100%">
      {newmode ? (
        <NewOrder
          handleToggleNewMode={handleToggleNewMode}
          handleRefetchOrders={handleRefetchOrders}
          publisher={currentpublisher.id}
        />
      ) : csvmode ? (
        <CsvUpload handleToggleCsvMode={handleToggleCsvMode} template={csvTemplate} itemType="order" tableWidth={2000} colWidths={csvWidths} title="Orders by Customer" />
      ) : (
        <Pane >
          <Pane ref={upperComps}>
            <Pane id="page-heading" width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" paddingBottom={16}>
              <Pane>
                <Paragraph fontWeight="bolder" size={500}>Orders</Paragraph>
                {sourcePageInfo?.queryTitle && <Breadcrumbs singlePage={sourcePageInfo?.sourcePageTitle ? sourcePageInfo.sourcePageTitle : "HOME"} pageLabel="" curPage={sourcePageInfo.queryTitle} sourceUrl={sourcePageInfo ? sourcePageInfo.sourceURL : "/dashboard"} />}
              </Pane>
              <Pane display="flex" alignItems="center">
                <Button
                  iconBefore={AddIcon} marginRight={16} onClick={e => setNewmode(true)}
                  className="new-button"
                >NEW ORDER</Button>
                <Button iconBefore={DocumentShareIcon} onClick={e => setCsvmode(true)}
                  className="new-button"
                > IMPORT ORDERS </Button>
              </Pane>
            </Pane>
            {!sourcePageInfo.queryTitle &&
              <SearchOrders
                name="Orders"
                handleSearch={handleSearchOrders}
                handleClearSearch={handleClearSearch}
              />}
            <Pane display="flex" alignItems="center" marginBottom={8}>
              {/* <Checkbox
              marginY={0}
              label="Select All"
              checked={allmode}
              onChange={e => handleSelectAll()}
            /> */}
              <Pane display="flex" marginLeft={16}>
                {/* TODO fix searchCount */}
                <Paragraph id="record-count" size={300}> <Pill marginRight={4} display="inline-flex" color={numSelected > 0 ? "green" : "neutral"}>{numSelected}</Pill> selected out of
                  <Pill marginLeft={8} display="inline-flex" className="none-badge">{count}</Pill> records
                </Paragraph>
              </Pane>
              <Paragraph size={300} marginX={16}>
                <CsvDownload
                  excluded={excluded}
                  allmode={allmode}
                  csvData={selected}
                  include={csvFields}
                  config={csvConfig}
                  csvType={"Orders"}
                  count={count}
                />
              </Paragraph>
              <Sort
                name="Orders"
                sortOptions={sortInputs}
                handleSort={handleSort}
                handleSetSortField={handleSetSortField}
                handleSetSortDirection={handleSetSortDirection}
                selectedSortOrder={sortOrder.sort_order}
              />
            </Pane>
          </Pane>
          {loading ? (
            <Pane height={tableHeight} display="flex" alignItems="center" justifyContent="center">
              <Spinner size={32} />
            </Pane>
          )
            :
            <Pane width="100%" height={tableHeight} overflowY="auto" padding={8} border="default">
              {orders.map(item => {
                return (
                  <OrderItem
                    key={item.id}
                    item={item}
                    selectall={allmode}
                    handleAddSelected={handleAddSelected}
                    handleRemoveSelected={handleRemoveSelected}
                    handleAddExcluded={handleAddExcluded}
                    handleRefetchOrders={handleRefetchOrders}
                  />
                );
              })}

            </Pane>
          }
          <Pane ref={lowerComps} marginBottom={8}>
            <PaginationDisplay
              currpage={currpage}
              pagecount={pagecount}
              setCurrpage={setCurrpage}
              searchCount={searchCount}
              count={count}
            />
          </Pane>


        </Pane>
      )}
    </Pane>
  )
}

export default Orders;