import { gql } from '@apollo/client';

const CREATE_PRODUCT_CREATOR_MUTATION = gql`
  mutation CreateProductCreatorMutation(
    $creator: Int!,
    $product: Int!,
    $onixContributorCode: Int!
  ) {
    createProductCreator(
      creator: $creator,
      product: $product,
      onixContributorCode: $onixContributorCode
    ) {
      message
    }
  }
`;

export default CREATE_PRODUCT_CREATOR_MUTATION;