import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sumArray, getActualAmountArr } from '../../util.js';


import {
  Pane,
  Paragraph,
  Strong,
  Checkbox,
  Badge,
  Card,
  Table,
  EyeOpenIcon,
  Popover,
  Button,
  ShopIcon
} from 'evergreen-ui';

function OrderItem(props) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const created = props.item.created.replace(/T\S*$/, "");

  const [checked, setChecked] = useState(false);
  const paid = props.item.paid
  const shipped = props.item.shipped
  // const [paid, setPaid] = useState(props.item.paid)
  // const [shipped, setShipped] = useState(props.item.shipped)


  const handleSetChecked = () => {
    if (props.selectall === true && checked === true) {
      props.handleAddExcluded(props.item);
    } else if (checked === true) {
      // remove it
      props.handleRemoveSelected(props.item);
    } else {
      // add it
      props.handleAddSelected(props.item);
    }
    setChecked(!checked);
  }

  // const handleSetPaid = () => {
  //   setPaid(!paid)
  // }

  // const handleSetShipped = () => {
  //   setShipped(!shipped)
  // }


  useEffect(() => {
    setChecked(props.selectall);
  }, [props.selectall]);


  const total_quantity_arr = props.item.orderproductSet.map(item => item.quantity);
  const total_amount_arr = getActualAmountArr(props.item.orderproductSet);
  const total_paid_arr = props.item.orderpaymentSet.map(item => item.amount);

  const totalDue = props.item.paid
    ? "0.00"
    : props.item.shippingCost
      ? (sumArray(total_amount_arr) + parseFloat(props.item.shippingCost) - sumArray(total_paid_arr) ).toFixed(2)
      : (sumArray(total_amount_arr) - sumArray(total_paid_arr)).toFixed(2);
  
  const orderPaid = sumArray(total_paid_arr).toFixed(2)


  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  return (
    <Pane>
      <Card elevation={3} width="100%" marginBottom={16}>
        <Pane display="flex" backgroundColor="var(--medgrey)" paddingY={8} paddingX={16} justifyContent="space-between" alignItems="center">
          <Pane display="flex" alignItems="center">
            <Checkbox marginLeft={8} marginRight={12} marginY={0}
              label=""
              checked={checked}
              onChange={e => handleSetChecked()}
            />
            <Paragraph size={400} fontWeight="bold" marginLeft={0}><Link to={`/orders/${props.item.id}`}>ORDER #{props.item.id} ({created})</Link></Paragraph>
            <Paragraph size={300} marginLeft={8}>{props.item.poNumber && `(PO#: ${props.item.poNumber})`}</Paragraph>
            <Paragraph size={300} marginLeft={8}> STATUS:</Paragraph>

            <Badge marginLeft={4}
              color={props.item.orderStatus?.value === "Ready" ? "green" : props.item.orderStatus?.value === "Canceled" ? "red" : "neutral"}>
              {props.item.orderStatus ? props.item.orderStatus.value : "N/A"}</Badge>
            <Paragraph size={300} marginLeft={8}> SHIPPING:</Paragraph>

            <Badge marginLeft={4}>{props.item.shippingType ? props.item.shippingType.value : "N/A"}</Badge>
            <Paragraph size={300} marginLeft={8}> TERM:</Paragraph>

            <Badge marginLeft={4}>{props.item.paymentTerm ? props.item.paymentTerm.value : "N/A"}</Badge>
          </Pane>
          <Pane display="flex" alignItems="center">
            <Checkbox marginY={0} marginLeft={8} marginRight={4} label="PAID" checked={paid} disabled={true} /> {paid && <Paragraph size={300}>({props.item.paidDate}) </Paragraph>}
            <Checkbox marginY={0} marginRight={4} marginLeft={8} label="SHIPPED" checked={shipped} disabled={true} />{shipped && <Paragraph size={300}>({props.item.shipDate})</Paragraph>}
          </Pane>


        </Pane>
        <Pane display="flex" justifyContent="space-between" backgroundColor="white" paddingY={8} >
          <Pane id="customer-info" elevation={1} marginLeft={16} padding={8} border="default" >
            <Pane display="flex" alignContent="center">
              <ShopIcon marginRight={8} color="#667b7f" />
              <Paragraph fontWeight="bold" size={300} marginBottom={8} color="#667b7f">  CUSTOMER</Paragraph>

            </Pane>
            {props.item.customer && <Pane> <Paragraph size={300}> </Paragraph>
              <Paragraph size={300}><Link to={`/customers/${props.item.customer.id}`}>
                {props.item.customer.firstName} {props.item.customer.lastName}
              </Link>
              </Paragraph>
              <Paragraph size={300}>
                {props.item.customer.company}
              </Paragraph>
            </Pane>}

            {props.item.orderAddress && (
              <Pane marginTop={8}>
                <Paragraph size={300}><Strong size={300}>Shipping Address:</Strong></Paragraph>
                <Paragraph size={300}>{props.item.orderAddress.line1}</Paragraph>
                <Paragraph size={300}>{props.item.orderAddress.line2}</Paragraph>
                <Paragraph size={300}>{props.item.orderAddress.city} {props.item.orderAddress.state_or_region} {props.item.orderAddress.country}</Paragraph>
              </Pane>
            )}
            {props.item.orderEmail && (
              <Pane marginTop={8}>
                <Paragraph size={300}><Strong size={300}>Email:</Strong></Paragraph>
                <Paragraph size={300}>{props.item.orderEmail.value}</Paragraph>
              </Pane>
            )}
            {props.item.orderPhone && (
              <Pane marginTop={8}>
                <Paragraph size={300}><Strong size={300}>Phone:</Strong> {props.item.orderPhone.value}</Paragraph>
              </Pane>
            )}
            <Popover

              content={({ close }) => (
                <Pane><Paragraph>{props.item.customer ? props.item.customer.adminNotes : "No Customer Attached to Order"}</Paragraph></Pane>
              )}
            >
              <Button size="small" marginTop={4}><EyeOpenIcon marginRight={8} />Account Note</Button>
            </Popover>
          </Pane>




          <Pane elevation={1} flex={4} overflow="auto" id="product-info" marginLeft={16} border="none">

            <Table.Head height={30} className="table-heading-small">
              <Table.TextCell flex={3}><b>Titles ({props.item.orderproductSet.length}) </b></Table.TextCell>
              <Table.TextCell flex={2}><b>Price/Item</b></Table.TextCell>
              <Table.TextCell flex={1}><b>Quantity</b></Table.TextCell>
              <Table.TextCell flex={1}><b>Item Total</b></Table.TextCell>
            </Table.Head>
            <Pane width="100%" maxHeight={150} overflowY="scroll" border="default">

              {props.item.orderproductSet.map(product => {
                return (
                  <Table.Row key={product.id} height={28} value="">
                    <Table.TextCell flex={3}>
                      <Pane display="flex">
                        <Link to={`/products/${product.product.id}`}>{product.product.title}</Link>
                        <Badge marginLeft={4}>{product.product.format?.description}</Badge>
                      </Pane>
                    </Table.TextCell>


                    <Table.TextCell flex={2}><Pane display="flex" alignItems="center">
                      {product.calcDiscount === 0 ? (
                        <Pane>{currency}{parseFloat(product.calcPrice).toFixed(2)}
                        </Pane>
                      ) :
                        (<Pane display="flex" alignItems="center">{currency}{parseFloat(product.calcPrice).toFixed(2)}
                          <Paragraph size={300} marginLeft={4} color="#667b7f">({(parseFloat(product.calcDiscount)).toFixed(2)}% DISC)</Paragraph>
                        </Pane>)
                      }

                    </Pane></Table.TextCell>
                    <Table.TextCell flex={1}>
                      {(parseInt(product.product.inventoryQuantity) < parseInt(product.quantity) && !shipped) ? (
                        <Badge color="red">{product.quantity}</Badge>
                      ) : (
                        product.quantity
                      )}
                    </Table.TextCell>
                    <Table.TextCell flex={1}>

                      <Pane>{currency}{(parseFloat(product.calcPrice) * parseFloat(product.quantity)).toFixed(2)}</Pane>

                    </Table.TextCell>
                  </Table.Row>
                )
              })}
            </Pane>
          </Pane>
          <Pane flex={2} elevation={1} id="order-details" marginX={16} padding={8} border="default">
            <Pane display="flex" justifyContent="space-between" marginBottom={8} >
              <Paragraph fontWeight="bold" size={300} color="#667b7f"> SUMMARY</Paragraph>

              <Badge
                color={totalDue === "0.00" ? "green" : "red"}
              >
                Due: {currency}{totalDue}
              </Badge>
              <Badge
              >
                Total: {currency}{(sumArray(total_amount_arr)).toFixed(2)}
              </Badge>
            </Pane>



            <Table.Row key="shippingCost" height={28} backgroundColor="#F4F5F9" >
              <Table.TextCell>
                Shipping Cost
              </Table.TextCell>
              <Table.TextCell textAlign="right">
                {currency}{props.item.shippingCost ? parseFloat(props.item.shippingCost).toFixed(2) : 0}
              </Table.TextCell>
            </Table.Row>
            <Table.Row key="totalItems" height={28} backgroundColor="#F4F5F9" >
              <Table.TextCell>
                Total # Items
              </Table.TextCell>
              <Table.TextCell textAlign="right">
                {total_quantity_arr.reduce((partial_sum, a) => partial_sum + a, 0)}
              </Table.TextCell>
            </Table.Row>
            <Table.Row key="payReceived" height={28} backgroundColor="#f9faff">
              <Table.TextCell>
                Payment Received
              </Table.TextCell>
              <Table.TextCell textAlign="right">
                {currency}{orderPaid}
              </Table.TextCell>
            </Table.Row>
            <Table.Row key="Salesperson" height={28} backgroundColor="#F4F5F9" >
              <Table.TextCell>
                Salesperson
              </Table.TextCell>
              <Table.TextCell textAlign="right">
                {props.item.salesperson ?
                  <Paragraph size={300} >
                      {props.item.salesperson.value}
                  </Paragraph> : "N/A"}
              </Table.TextCell>
            </Table.Row>
          </Pane>
        </Pane>
      </Card >

    </Pane >
  )
}

export default OrderItem;
