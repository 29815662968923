import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NewExpense from "../Expenses/NewExpense.js";
import NewOrder from "../Orders/NewOrder.js";
import NewProduct from "../Products/NewProduct.js";
import GrossSalesByDate from "./reports/GrossSalesByDate.js";
import ExpensesByDate from "./reports/ExpensesByDate.js";
import NetSalesByDate from "./reports/NetSalesByDate.js";
import GrossSalesBySalesperson from "./reports/GrossSalesBySalesperson.js";
import TopTenByDate from "./reports/TopTenByDate.js";


import {
  Pane,
  Card,
  Heading,
  Button,
  TextInput,
  AddIcon,
  Paragraph,
} from 'evergreen-ui';

function Dash() {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const [newOrderMode, setNewOrderMode] = useState(false);
  const [newExpenseMode, setNewExpenseMode] = useState(false);
  const [newProductMode, setNewProductMode] = useState(false);
  const [orderCustomer, setOrderCustomer] = useState("");
  const [orderId, setOrderId] = useState("");
  const [productTitle, setProductTitle] = useState("");

  const handleToggleNewOrderMode = () => {
    setNewOrderMode(!newOrderMode);
  }

  const handleToggleNewExpenseMode = () => {
    setNewExpenseMode(!newExpenseMode);
  }

  const handleToggleNewProductMode = () => {
    setNewProductMode(!newProductMode);
  }

  // TODO: Get rid of this
  const fakeHandler = () => {
    console.log("fake handled");
  }

  const today = new Date().toISOString().replace(/T.*/, '').split('-').join('-');
  const yesterday = new Date().setDate(new Date().getDate() - 1);
  const tomorrow = new Date().setDate(new Date().getDate() + 1);

  return (
    <Pane padding={16}>
      {newOrderMode && (
        <NewOrder
          handleToggleNewMode={handleToggleNewOrderMode}
          handleRefetchOrders={fakeHandler}
          publisher={currentpublisher.id}
        />
      )}
      {newExpenseMode && (
        <NewExpense
          handleToggleNewMode={handleToggleNewExpenseMode}
          handleRefetchExpenses={fakeHandler}
          publisher={currentpublisher.id}
        />
      )}
      {newProductMode && (
        <NewProduct
          handleToggleNewMode={handleToggleNewProductMode}
          handleRefetchProducts={fakeHandler}
          publisher={currentpublisher.id}
        />
      )}
      {!newProductMode && !newExpenseMode && !newOrderMode && (
        <Pane>
          <Pane display="flex" flexDirection="row">
            <Card background="white" elevation={3} padding={16} marginRight={16}>
              <Pane display="flex" alignItems="center" justifyContent="space-between">
                <Paragraph size={500} fontWeight="bolder" marginRight={8} color="var(--darkgreen)">QUICK START</Paragraph>


              </Pane>
              <Pane display="flex" marginTop={8} alignItems="center">
                {/* <Tooltip content={"Temporarily disabled for site update."} ><HelpIcon color="muted" marginRight={16} /></Tooltip> */}

                <Pane marginRight={8}>
                  <Button iconBefore={AddIcon} className="new-button" onClick={e => setNewOrderMode(true)}>NEW ORDER</Button>
                </Pane>
                <Pane marginX={8}>
                  <Button iconBefore={AddIcon} className="new-button" onClick={e => setNewExpenseMode(true)}>NEW EXPENSE</Button>
                </Pane>
                <Pane marginX={8}>
                  <Button iconBefore={AddIcon} className="new-button" onClick={e => setNewProductMode(true)}>NEW PRODUCT</Button>
                </Pane>
              </Pane>
              <Pane display="flex" marginTop={16}>

                <Pane id="quick-links" marginRight={32}>
                  <Paragraph size={400} fontWeight="bold" marginBottom={8}>View</Paragraph>

                  <Heading size={300} marginY={8}>
                    <Link to={{
                      pathname: '/expenses',
                      state: { query: {created__lte: tomorrow, created__gte: yesterday}, queryTitle: "Expenses Created Today (" + today + ")" }
                    }}>
                      &gt; Expenses Created Today
                    </Link>
                  </Heading>
                  <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Heading size={300} marginY={8}>
                      <Link to={{
                        pathname: '/orders',
                        state: { query: {created__lte: tomorrow, created__gte: yesterday}, queryTitle: "Orders Created Today (" + today + ")" }
                      }}>
                        &gt; Orders Created Today
                      </Link>
                    </Heading>
                  </Pane>
                  <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    {/* <Heading size={400} marginY={8}>
                <Link to='/orders?shipped=false&paid=true'>View Orders Awaiting Shipment &gt;</Link>
              </Heading> */}
                    <Heading size={300} marginY={8}>
                      <Link to={{
                        pathname: '/orders',
                        state: { query: {shipped: false, paid: true}, queryTitle: "Orders Awaiting Shipment" }
                      }}>
                        &gt; Orders Awaiting Shipment
                      </Link>
                    </Heading>
                  </Pane>
                  <Pane display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Heading size={300} marginY={8}>
                      <Link to={{
                        pathname: '/orders',
                        state: { query: {backordered: true}, queryTitle: "Backorders" }
                      }}>
                        &gt; Backorders
                      </Link>
                    </Heading>
                  </Pane>

                </Pane>

                <Pane id="quick-searches">
                  <Paragraph size={400} fontWeight="bold" marginBottom={4} >Search</Paragraph>
                  <Paragraph size={300} >Find Products by Title</Paragraph>
                  <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <TextInput
                      onChange={e => setProductTitle(e.target.value)}
                      width={200}

                      value={productTitle}
                      marginY={4}
                    />
                    <Button>
                      <Link to={{
                        pathname: '/products',
                        state: { query: {title: productTitle}, queryTitle: `Titles containing: ${productTitle}` }
                      }}>
                        Go
                      </Link>
                      
                        </Button>
                  </Pane>
                  <Paragraph size={300} >Find Orders by Company</Paragraph>
                  <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <TextInput
                      onChange={e => setOrderCustomer(e.target.value)}
                      width={200}

                      value={orderCustomer}
                      marginY={4}
                    />
                    <Button>
                    <Link to={{
                        pathname: '/orders',
                        state: { query: {customer__company: orderCustomer}, queryTitle: `Company name containing: ${orderCustomer}` }
                      }}>
                        Go
                      </Link></Button>
                  </Pane>
                  <Paragraph size={300} >Find Orders by ID</Paragraph>
                  <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <TextInput
                      onChange={e => setOrderId(e.target.value)}
                      width={200}
                      value={orderId}
                      marginY={4}
                    />
                    <Button><Link to={`/orders/${orderId}`}>Go</Link></Button>
                  </Pane>

                </Pane>

              </Pane>



            </Card>
            <GrossSalesBySalesperson />
            <TopTenByDate />

          </Pane>
          <Pane display="flex" flexDirection="row" justifyContent="center" marginTop={16}>
            <GrossSalesByDate />
            <NetSalesByDate />
            <ExpensesByDate />
          </Pane>
        </Pane>
      )}

    </Pane>
  );
}

export default Dash;