import React, { useEffect, useState } from "react";
import { 
  createBrowserRouter,
  Route, 
  Routes, 
  Link, 
  Navigate,
  useLocation,
  useNavigationType ,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { setcurrent } from './redux/currentpublisher/currentpublisherSlice.js';
import { setpublisherGlobalData } from './redux/currentpublisher/publisherGlobalDataSlice.js';
import { setcontribcodes } from './redux/onixcontributorcodes/contribcodesSlice.js';
import { setonixGlobalData } from './redux/onix/onixGlobalDataSlice.js';
import { setall } from './redux/publishers/publishersSlice.js';
import { setuser } from './redux/user/userSlice.js';
import Dash from "./views/Dashboard/Dash.js";
import UserSettings from "./views/Settings/UserSettings.js";
import PublisherSettings from "./views/Settings/PublisherSettings.js";
import Creators from "./views/Creators/Creators.js";
import Creator from "./views/Creators/Creator.js";
import Products from "./views/Products/Products.js";
import Help from "./views/Help/Help.js";
import Product from "./views/Products/Product.js";
import Customers from "./views/Customers/Customers.js";
import Customer from "./views/Customers/Customer.js";
import Orders from "./views/Orders/Orders.js";
import Order from "./views/Orders/Order.js";
import Expenses from "./views/Expenses/Expenses.js";
import Expense from "./views/Expenses/Expense.js";
import Royalties from "./views/Royalties/Royalties.js";
import Royalty from "./views/Royalties/Royalty.js";
import RoyaltyPayments from "./views/RoyaltyPayments/RoyaltyPayments.js";
import ResetPassword from "./views/Login/ResetPassword.js";
import NotFound from "./views/NotFound.js";
import UserMenu from "./components/layout/UserMenu.js";
import SideBar from "./components/layout/SideBar.js";
import MobileNav from "./components/layout/MobileNav.js";
import logo from './assets/images/logo-orange.png';
import QUERY_GLOBAL_DATA from "./graphql/queries/QueryGlobalData.js";
import './assets/styles/index.css';
import inboxmsgs from './inboxmsgs.json'
import { getCookie, makeDateString } from "./util.js";
import JSON5 from 'json5'

import {
  Pane,
  Heading,
  Paragraph,
  SideSheet,
  Button,
  InboxIcon,
  Pill,
} from 'evergreen-ui';
import InboxItem from "./components/layout/InboxItem.js";
import UPDATE_PUBLISHER_MUTATION from "./graphql/mutations/PublisherData/UpdatePublisher.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase_setup/firebase.js";
import Impersonate from "./impersonate/impersonate.js";
import * as Sentry from "@sentry/react";
import { browserTracingIntegration } from "@sentry/tracing";
import { ApolloClient, InMemoryCache } from "@apollo/client";

// Initialize Sentry
Sentry.init({
  dsn: "https://b29aa3e8da966b2daeded4d62bd5707a@o4507947661852672.ingest.us.sentry.io/4507980770181120",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);

const router = sentryCreateBrowserRouter([
  { path: '/', element: <Navigate to="/dashboard" /> },
  { path: '/dashboard', element: <Dash /> },
  { path: '/user', element: <UserSettings /> },
  { path: '/publisher', element: <PublisherSettings /> },
  { path: '/creators', element: <Creators /> },
  { path: '/creators/:creatorId', element: <Creator /> },
  { path: '/products', element: <Products /> },
  { path: '/products/:productId', element: <Product /> },
  { path: '/customers', element: <Customers /> },
  { path: '/customers/:customerId', element: <Customer /> },
  { path: '/orders', element: <Orders /> },
  { path: '/orders/:orderId', element: <Order /> },
  { path: '/expenses', element: <Expenses /> },
  { path: '/expenses/:expenseId', element: <Expense /> },
  { path: '/royalties', element: <Royalties /> },
  { path: '/royalties/:royaltyId', element: <Royalty /> },
  { path: '/royaltypayments', element: <RoyaltyPayments /> },
  { path: '/reset_password', element: <ResetPassword /> },
  { path: '/impersonate', element: <Impersonate /> },
  { path: '/help', element: <Help /> },
  { path: '*', element: <NotFound /> },
]);

function App(props) {
  const userid = useSelector((state) => state.user.value);
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [showInbox, setShowInbox] = useState(false);
  const [fbAuth, setFbAuth] = useState(publisherGlobalData.firebaseAuth);
  const [updatePublisher] = useMutation(UPDATE_PUBLISHER_MUTATION);
  const dispatch = useDispatch();

  const currYear = new Date().getFullYear();
  const newNotifCount = inboxmsgs.filter(item => {
    let inboxDate = new Date(publisherGlobalData.inboxDate);
    return new Date(Date.parse(item.date)) > inboxDate;
  }).length;

  const handleSetInboxDate = () => {
    const tomorrow = new Date().setDate(new Date().getDate() + 1);
    const tomorrowDate = new Date(tomorrow);
    const todayDate = makeDateString(tomorrowDate);
    dispatch(setpublisherGlobalData({ inboxDate: todayDate }));
    updatePublisher({
      variables: {
        id: parseInt(currentpublisher.id),
        changed: JSON.stringify([{ key: "inboxDate", val: todayDate }]),
      }
    });
  };

  useEffect(() => {
    dispatch(setuser(props.user));
    if (props.publisher) {
      dispatch(setcurrent({ id: props.publisher, name: props.publisherName }));
    } else {
      console.log("no pub");
    }
  }, [props.publisherName, props.publisher, props.user, dispatch]);

  const intId = parseInt(userid);
  const noData = !currentpublisher.id || isNaN(intId);

  const createContribCodeArray = codes => {
    return codes.map(code => ({
      label: `${code.value} - ${code.description}`,
      value: code.id,
    }));
  };

  useEffect(() => {
    if (!fbAuth && currentpublisher.id) {
      const csrftoken = getCookie('csrftoken');
      fetch('/fetch_firebase_credentials/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        }
      })
      .then(res => res.json())
      .then(data => {
        let obj = JSON5.parse(data.message);
        signInWithEmailAndPassword(auth, obj.user, obj.pass)
          .then(() => {
            return updatePublisher({
              variables: {
                id: parseInt(currentpublisher.id),
                changed: JSON.stringify([{ key: "firebaseAuth", val: true }]),
              }
            });
          })
          .then(() => {
            setFbAuth(true);
          })
          .catch(error => {
            console.error("Firebase Auth Error:", error);
            Sentry.captureException(error); // Capture any Firebase errors
          });
      });
    }
  }, [fbAuth, currentpublisher.id, updatePublisher]);

  useEffect(() => {
    if (fbAuth) {
      dispatch(setpublisherGlobalData({ firebaseAuth: true }));
    }
  }, [fbAuth, dispatch]);

  const { loading, error, data } = useQuery(QUERY_GLOBAL_DATA, {
    variables: { user: intId, publisher: parseInt(currentpublisher.id) },
    skip: noData
  });

  useEffect(() => {
    if (data) {
      let codeArray = createContribCodeArray(data.onixcontributorcodes);
      dispatch(setcontribcodes(codeArray));
      let onixObj = {
        onixcontributorcodes: data.onixcontributorcodes,
        onixproductforms: data.onixproductforms,
        onixproductformdetails: data.onixproductformdetails,
        onixproductavailabilitys: data.onixproductavailabilitys,
        onixaudiencetypes: data.onixaudiencetypes,
      };
      dispatch(setonixGlobalData(onixObj));
      let publisherObj = {
        created: data.publisher.created,
        inboxDate: data.publisher.inboxDate,
        numproducts: data.products.length,
        publisherimprints: data.publisherimprints,
        publishertags: data.publishertags,
        producttypes: data.producttypes,
        productseriess: data.productseriess,
        publishercreators: data.publishercreators,
        customerclasses: data.customerclasses,
        paymentterms: data.paymentterms,
        shippingtypes: data.shippingtypes,
        salespersons: data.publishersalespersons,
        paymenttypes: data.paymenttypes,
        orderstatuss: data.orderstatuss,
        expensecategorys: data.expensecategorys,
        royaltycategorys: data.royaltycategorys,
        royaltyterms: data.royaltyterms,
        currencys: data.currencys,
        currency: data.publisher.currency,
        accountingMethod: data.publisher.accountingMethod,
        billingId: data.publisher.billingId,
        billingDate: data.publisher.billingDate,
        plan: data.publisher.plan,
        maxProducts: data.publisher.maxProducts,
        firebaseAuth: data.publisher.firebaseAuth,
      };
      dispatch(setpublisherGlobalData(publisherObj));
      if (data.userpublishers && data.userpublishers.length > 0) {
        const currPubId = data.userpublishers[0].publisher.id;
        const currPubName = data.userpublishers[0].publisher.name;
        if (!currentpublisher || !currentpublisher.id) {
          dispatch(setcurrent({ id: currPubId }));
        }
        if (!currentpublisher || !currentpublisher.name) {
          dispatch(setcurrent({ name: currPubName }));
        }
      }
      dispatch(setall(data.userpublishers));
    }
  }, [data, currentpublisher, dispatch]);

  if (loading) return null;
  if (error) {
    Sentry.captureException(error); // Capture GraphQL errors
    return `Error! ${error}`;
  }

  return (!noData && data && (
    <Pane>
      <Pane id="outer-container" backgroundColor={"var(--cream)"}>
        <Pane id="left-nav" elevation={1}>
          <Pane id="left-nav-inner">
            <Pane display="flex" flexDirection="column">
              <Heading id="logo-container"><Link to='/'><img className="logo" src={logo} alt="working lit logo" /></Link></Heading>
              {props.baseUrl !== "https://app.workinglit.com" && props.baseUrl !== "https://www.workinglit.com" && props.baseUrl !== "https://workinglit.com" && process.env.NODE_ENV !== 'development' && <Paragraph size={400} fontWeight="bold" color="#e85cc9">STAGING SERVER!</Paragraph>}
              <UserMenu pubUser={data.userpublishers[0]} />
              <SideSheet isShown={showInbox} onCloseComplete={() => setShowInbox(false)} className="background" width="25vw">
                <Pane display="flex" justifyContent="space-between" alignItems="center" margin={16}>
                  <Paragraph size={500} fontWeight="bolder" color="var(--darkgreen)">INBOX</Paragraph>
                  <Button size="small" disabled={newNotifCount === 0} onClick={e => handleSetInboxDate()}>Mark all as read</Button>
                </Pane>
                {inboxmsgs.map(item => {
                  if (!publisherGlobalData.inboxDate) {
                    return (<InboxItem key={`${item.date}_${item.title.replace(/\s/g, "")}`} item={item} unread={true} />);
                  } else {
                    let inboxDate = new Date(publisherGlobalData.inboxDate);
                    if (new Date(Date.parse(item.date)) > inboxDate) {
                      return (<InboxItem key={`${item.date}_${item.title.replace(/\s/g, "")}`} item={item} unread={true} />);
                    } else {
                      return (<InboxItem key={`${item.date}_${item.title.replace(/\s/g, "")}`} item={item} unread={false} />);
                    }
                  }
                })}
              </SideSheet>
              <Button marginTop={4} iconBefore={InboxIcon} className="inbox-button" onClick={e => setShowInbox(!showInbox)}>
                <Pane display='flex' justifyContent="space-between" alignItems="center">
                  <Paragraph className="inbox-button-text">Inbox</Paragraph>
                  {newNotifCount > 0 && <Pill color={"orange"} marginLeft={16}>{newNotifCount}</Pill>}
                </Pane>
              </Button>
              <Routes>
                <Route path='/:page' element={<SideBar />} />
                <Route path='/' element={<SideBar />} />
                <Route path={`/creators/:creatorId`} element={<SideBar />} />
                <Route path={`/products/:productId`} element={<SideBar />} />
                <Route path={`/customers/:customerId`} element={<SideBar />} />
                <Route path={`/orders/:orderId`} element={<SideBar />} />
                <Route path={`/expenses/:expenseId`} element={<SideBar />} />
                <Route path={`/royalties/:royaltyId`} element={<SideBar />} />
              </Routes>
            </Pane>
            <Pane>
              {process.env.NODE_ENV === 'development' && <Paragraph size={300} color="var(--lightorange)" marginBottom={8}>You are running this app in {process.env.NODE_ENV} mode.</Paragraph>}
              <Paragraph color="white" fontSize={10}><a className="link-dark" href="https://docs.google.com/document/d/1_kjfaoJzEogR4GNToaibl4377smPQVXHaO1OAw4UJbI/edit?usp=sharing">Terms of Service</a></Paragraph>
              <Paragraph size={300} fontSize="10px" color='white'>Copyright &copy; {currYear}</Paragraph>
              <Paragraph size={300} fontSize="10px" color='white'>Microcosm Publishing</Paragraph>
            </Pane>
          </Pane>
          <Pane id="mobile-nav">
            <Heading id="logo-container"><Link to='/'><img className="logo" src={logo} alt="working-lit-logo" /></Link></Heading>
            <Pane id="mobile-nav-inner">
              <MobileNav />
              <UserMenu />
            </Pane>
          </Pane>
        </Pane>

        <Pane id="main-container" elevation={0}>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dash />} />
            <Route path="/user" element={<UserSettings />} />
            <Route path="/publisher" element={<PublisherSettings />} />
            <Route path="/creators" element={<Creators />} />
            <Route path={`/creators/:creatorId`} element={<Creator />} />
            <Route path="/products" element={<Products />} />
            <Route path={`/products/:productId`} element={<Product />} />
            <Route path="/customers" element={<Customers />} />
            <Route path={`/customers/:customerId`} element={<Customer />} />
            <Route path="/orders" element={<Orders />} />
            <Route path={`/orders/:orderId`} element={<Order />} />
            <Route path="/expenses" element={<Expenses />} />
            <Route path={`/expenses/:expenseId`} element={<Expense />} />
            <Route path="/royalties" element={<Royalties />} />
            <Route path={`/royalties/:royaltyId`} element={<Royalty />} />
            <Route path="/royaltypayments" element={<RoyaltyPayments />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route path="/impersonate" element={<Impersonate />} />
            <Route path="/help" element={<Help />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Pane>
      </Pane>
    </Pane>
  ));
}

export default App;