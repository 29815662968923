import { gql } from '@apollo/client';

const CREATE_IMPRINT_MUTATION = gql`
  mutation CreateImprintMutation(
    $publisherId: Int!,
    $imprint: String!
  ) {
    createImprint(
      publisherId: $publisherId,
      imprint: $imprint
    ) {
      message
    }
  }
`;

export default CREATE_IMPRINT_MUTATION;