import { createSlice } from '@reduxjs/toolkit';

export const contribcodesSlice = createSlice({
  name: 'contribcodes',
  initialState: {
    value: [{}],
  },
  reducers: {
    setcontribcodes: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { setcontribcodes } = contribcodesSlice.actions;

export default contribcodesSlice.reducer;