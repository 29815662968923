import { gql } from '@apollo/client';

const CREATE_ROYALTY_MUTATION = gql`
  mutation CreateRoyaltyMutation(
    $publisherId: Int!,
    $royalty: String!
  ) {
    createRoyalty(
      publisherId: $publisherId,
      royalty: $royalty
    ) {
      message
    }
  }
`;

export default CREATE_ROYALTY_MUTATION;