import { gql } from '@apollo/client';

const CREATE_CUSTOMERCLASS_MUTATION = gql`
  mutation CreateCustomerClassMutation(
    $publisherId: Int!,
    $customerClass: String!
  ) {
    createCustomerClass(
      publisherId: $publisherId,
      customerClass: $customerClass
    ) {
      message
    }
  }
`;

export default CREATE_CUSTOMERCLASS_MUTATION;