import React from "react";
import CsvDownload from "./CsvDownload";

import {
  Pane,
  Button,
  CaretDownIcon,
  Table,
  SmallMinusIcon,
  Checkbox,
  CaretUpIcon,
  Tooltip,
  HelpIcon,
  Paragraph,
  Pill,
  Card,
  Badge,
} from 'evergreen-ui';


function SummaryTableHeading(props) {

  const cols = props.cols
  const handleSelectAll = () => {
    // empty both arrays
    props.setSelected([]);
    props.setExcluded([]);
    props.setAllmode(!props.allmode);
    // set all visible items to checked
  }

  const handleSort = selected => {
    let newDirection = "ascending";
    if (props.sortDirection.sort_direction && props.sortDirection.sort_direction === "ascending") {
      newDirection = "descending";
    }
    props.setSortDirection({ sort_direction: newDirection });
    props.setSortOrder({ sort_order: selected });
  }

  const checkSort = selected => {
    return props.sortDirection.sort_direction && props.sortOrder.sort_order && props.sortOrder.sort_order === selected;
  }

  const includedFilters = Object.keys(props.filterDetails).filter(key => props.filterDetails[key].exclude === false)
  const anyIncluded = includedFilters.map(key => props.filterDetails[key].labels).flat(1).length

  const excludedFilters = Object.keys(props.filterDetails).filter(key => props.filterDetails[key].exclude === true)
  const anyExcluded = excludedFilters.map(key => props.filterDetails[key].labels).flat(1).length
  const numSelected = props.allmode ? props.count - props.excluded.length : props.selected ? props.selected.length : 0



  return (
    <Pane width="100%">
      <Pane display="flex" alignItems="flex-end" marginTop={16} marginBottom={8}>
        <Pane marginRight={8} alignItems="center">
          <Paragraph id="record-count" size={300}>
            <Pill marginRight={4} display="inline-flex" color={numSelected > 0 ? "green" : "neutral"}>{numSelected}</Pill> selected out of
            <Pill marginLeft={8} display="inline-flex" className="none-badge">{props.count}</Pill> records
          </Paragraph>

        </Pane>
        <Pane marginRight={8}>
          <CsvDownload
            excluded={props.excluded}
            allmode={props.allmode}
            csvData={props.selected}
            include={props.csvFields}
            config={props.csvConfig}
            csvType={props.csvType}
            count={props.count}
          />
        </Pane>
        <Card display="flex" marginX={8} alignItems="flex-end">
          <Paragraph size={300} fontWeight="bold">INCLUDE: </Paragraph>

          {anyIncluded > 0 ? 
          <Card padding={4} paddingLeft={0} paddingTop={0} marginX={8} display="flex" backgroundColor="var(--lightgrey)" 
          maxWidth="25vw" maxHeight={48} overflow="auto" flexWrap="wrap" >
            {includedFilters.map(key => 
                {return props.filterDetails[key].labels.map(filter =>
                  <Badge key={`in${key}${filter}`} marginLeft={4} marginTop={4} backgroundColor="var(--lightgreen)">
                    {filter}
                  </Badge>
                )})
              
              
            } </Card > : <Badge marginLeft={4} className="none-badge"> NONE SELECTED </Badge>}

          <Paragraph size={300} fontWeight="bold" marginX={4}>EXCLUDE:</Paragraph>

          {anyExcluded > 0 ? 
          <Card padding={4} paddingLeft={0} paddingTop={0} marginX={8} backgroundColor="var(--lightgrey)" 
          maxWidth="25vw" maxHeight={48} overflow="auto" flexWrap="wrap" >
            {excludedFilters.map(key => 
                {return props.filterDetails[key].labels.map(filter =>
                  <Badge key={`in${key}${filter}`} marginLeft={4} marginTop={4} backgroundColor="var(--beige)">
                    {filter}
                  </Badge>
                )})
              
              
            } </Card > : <Badge marginLeft={4} className="none-badge"> NONE SELECTED </Badge>}

        </Card>

      </Pane >
      <Table.Head className="table-heading" elevation={3}>
        <Table.Cell flex="1" >
          <Pane display="flex" alignItems="center" paddingRight={8}>
            <Checkbox
              marginX={8}
              checked={props.allmode}
              indeterminate={props.allmode && props.excluded.length > 0}
              onChange={e => handleSelectAll()}
            />
            <Button
              textAlign="left"
              paddingLeft={0}
              size="small"
              fontWeight="bold"
              onClick={e => handleSort("id")}
              appearance="minimal"
              iconAfter={!checkSort("id") ? SmallMinusIcon : props.sortDirection.sort_direction === "ascending" ? CaretUpIcon : CaretDownIcon}
            >
              ID
            </Button>


          </Pane>
        </Table.Cell>
        {cols.map(col => {
          let align = col.align ? col.align : "left"
          return col.type === "button" ?
            <Table.Cell key={col.title} flex={col.flex} justifyContent={col.align ? "flex-end" : "flex-start"}>
              <Button
                paddingLeft={0}
                fontWeight="bold"
                onClick={e => handleSort(col.query)}
                appearance="minimal"
                iconAfter={!checkSort(col.query) ? SmallMinusIcon : props.sortDirection.sort_direction === "ascending" ? CaretUpIcon : CaretDownIcon}
              >
                {col.title}
              </Button>
            </Table.Cell>
            : col.type === "tooltip" ?
              <Table.TextCell key={col.title} flex={col.flex} textAlign={align}
              >
                <Pane display="flex" alignItems="center"><b>{col.title}</b>
                  <Tooltip content={col.tooltip} appearance="card"><HelpIcon color="muted" marginLeft={4} /></Tooltip>
                </Pane>
              </Table.TextCell>
              : <Table.TextCell key={col.title} flex={col.flex} textAlign={align}>
                <Pane marginLeft={4} fontWeight="bold">{col.title}</Pane>
              </Table.TextCell>

        })}
      </Table.Head>
    </Pane>

  )
};

export default SummaryTableHeading;