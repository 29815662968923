import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { makeDateString} from '../../util.js';
import { baseExpenseObject } from './BaseExpenseObject.js';
import ExpenseForm from "./ExpenseForm.js";
import Breadcrumbs from "../../components/layout/Breadcrumbs.js";
import SaveButtons from "../../components/layout/SaveButtons.js";
import LeavePagePrompt from "../../components/LeavePagePrompt.js";
import QUERY_EXPENSE from "../../graphql/queries/Expenses/QueryExpense.js";
import UPDATE_EXPENSE_MUTATION from "../../graphql/mutations/Expenses/UpdateExpense.js";
import DELETE_EXPENSE_MUTATION from "../../graphql/mutations/Expenses/DeleteExpense.js";
import {
  Pane,
  Paragraph,
  Badge,
  toaster,
  Card,
  EmptyState,
  InfoSignIcon,
} from 'evergreen-ui';

function Expense() {
  const userid = useSelector((state) => state.user.value);
  const navigate = useNavigate()
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const [expense, setExpense] = useState(baseExpenseObject);
  const [changed, setChanged] = useState([]);
  const [staged, setStaged] = useState(baseExpenseObject);
  const [editing, setEditing] = useState(false);

  const { expenseId } = useParams();

  const handleUpdateStaged = obj => {
    // check for an existing record in changed
    delete obj.val.__typename;
    delete obj.val.label;
    let newArr = changed.filter(item => item.hasOwnProperty("key") && item.key !== obj.key);
    newArr.push(obj)
    setChanged(newArr);
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }));
  }

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_EXPENSE, {
    variables: { publisher: parseInt(currentpublisher.id), id: parseInt(expenseId) },
  });
  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  const [updateExpense, expensedata] = useMutation(UPDATE_EXPENSE_MUTATION);
  const [deleteExpense, expensedeletedata] = useMutation(DELETE_EXPENSE_MUTATION);


  const resetForm = () => {
    setStaged(expense);
    setChanged([])
  }

  
  const handleUpdateExpense = async () => {
    let newChanged = changed.map(item => {
      if (item.key === "expenseDate") {
        return { key: item.key, val: makeDateString(item.val) }
      } else {
        return item;
      }
    });

    try {
      let updateResult = await updateExpense(
        {
          variables: {
            id: parseInt(staged.id),
            changed: JSON.stringify(newChanged),
          }
        });
        toaster.closeAll()
        toaster.success(updateResult.data.updateExpense.message, { duration: 3 })
        return "Saved"

    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error saving expense. Try again or contact developer", { duration: 5 })
      return error.message;
    }
   
  }

  const handleDeleteExpense = async () => {
    try {
      const results = await deleteExpense(
        {
          variables: {
            id: parseInt(staged.id),
          }
        });
      toaster.closeAll()
      toaster.success(`Expense ${results.data.deleteExpense.message} deleted succesfully!`, { duration: 10 })
      navigate('/expenses')

    }
    catch (error) {
      toaster.closeAll()
      toaster.danger("Error deleting expense. Try again or contact developer", { duration: 5 })
      return error.message;
    }
  }

  const handleAttachProduct = product => {
    handleUpdateStaged({
      "key": "product",
      "val": JSON.parse(product)
    })
  }

  useEffect(() => {
    if (data && data.expense.length) {
      setExpense(data.expense[0]);
      setStaged(data.expense[0]);
    }
  }, [data]);

  if (loading) return null;
  if (error) return `Error! ${error}`;
  if (data.expense.length === 0) return (
    <Pane >
      <Pane paddingX={16} paddingTop={16}>
        <Breadcrumbs pageLabel="EXPENSE" sourceUrl="/expenses" />
      </Pane>
      <Pane display="flex" justifyContent="center" marginTop={60}>
        <Card alignItems="center" width="50%" elevation={2}>
          <EmptyState
            background="light"
            title="Expense not found"
            orientation="vertical"
            icon={<InfoSignIcon size={12} color="#C1C4D6" />}
            iconBgColor="#EDEFF5" />
        </Card>

      </Pane>


    </Pane>);

  return (
    <Pane>
      {
        editing &&
        <LeavePagePrompt
          userid={userid}
          itemid={expenseId}
          itemType="expense"
          when={!!(changed?.length)}
        />
      }
      <Pane>
        <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16}>
          <Breadcrumbs pageLabel="EXPENSE" curPage={expense.id} sourceUrl="/expenses" />
          <Pane marginTop={8} marginBottom={8} size={300} display="flex" justifyContent="space-between" >
            <Pane display="flex" alignItems="flex-end">
              <Paragraph marginLeft={4} size={300} fontSize={18} > Expense</Paragraph>

              <Paragraph marginLeft={8} size={300}><Badge color="neutral">ID: {expense.id}</Badge></Paragraph>
              <Badge marginLeft={8}>{staged.product ? "Product Expense" : "Company Expense"}</Badge>
            </Pane>
            <Pane>
              <SaveButtons
                itemType="expense"
                editing={editing}
                locked={expense.locked}
                itemId={expenseId}
                setEditing={setEditing}
                resetForm={resetForm}
                handleUpdate={handleUpdateExpense}
                handleDelete={handleDeleteExpense}
                refetch={refetch}
                canDelete={true}
              />
            </Pane>
          </Pane>
        </Pane>
        <ExpenseForm
          staged={staged}
          handleUpdateStaged={handleUpdateStaged}
          editing={editing}
          handleAttachProduct={handleAttachProduct}
          newMode={false} />
      </Pane>
    </Pane>
  )
}

export default Expense;