import { gql } from '@apollo/client';

const UPDATE_CREATOR_MUTATION = gql`
  mutation UpdateCreatorMutation(
    $id: Int!, 
    $changed: String!, 
  ) {
    updateCreator(
      id: $id, 
      changed: $changed
    ) {
      message
    }
  }
`;

export default UPDATE_CREATOR_MUTATION;