import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';


// ------- FUNCTIONS
import { getCookie } from '../../util.js';

// ------- QUERIES
import QUERY_CUSTOMERS from "../../graphql/queries/Customers/QueryCustomers.js";

// ------- COMPONENTS
import LandingPage from "../../components/layout/LandingPage.js";
import NewCustomer from "./NewCustomer.js";
import CsvUpload from "../../components/layout/CsvUpload.js";

import {
  ForeignKeyMultiSelectDropDownInput,
} from "../../inputs/index.js";

import {
  Pane,
  AddIcon,
  SideSheet,
  DocumentShareIcon,
  Badge
} from 'evergreen-ui';

function Customers() {
  // ------- STATES
  const location = useLocation();


  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

  const [customers, setCustomers] = useState([]);
  const [count, setCount] = useState(0);
  const [pagecount, setPagecount] = useState(0);
  const [currpage, setCurrpage] = useState(1);

  const [newmode, setNewmode] = useState(false);
  const [csvmode, setCsvmode] = useState(false);
  const [params, setParams] = useState({});
  const [sourcePageInfo, setSourcePageInfo] = useState(false);

  // ------- HOOKS
  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_CUSTOMERS, {
    variables: { publisher: parseInt(currentpublisher.id), page: parseInt(currpage), params: JSON.stringify(params) },
  });
  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  useEffect(() => {
    if (data) {
      setCustomers(data.customers);
      const csrftoken = getCookie('csrftoken');
      fetch('/count_customers/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body: JSON.stringify({ publisher: currentpublisher.id })
      })
        .then(res => res.json())
        .then(data => {
          setCount(parseInt(data.message));
          setPagecount(Math.ceil(data.message / 25));
        })
    }
  }, [data, currentpublisher.id]);

  useEffect(() => {
    if (location.state) {
      let locState = location.state;
      setSourcePageInfo(locState)
    }
  }, [location.state]);


  // ------- FUNCTIONS
  const handleRefetchCustomers = () => {
    setParams({});
    refetch();
  }

  const handleToggleNewMode = () => {
    setNewmode(!newmode);
  }

  const handleToggleCsvMode = () => {
    setCsvmode(!csvmode);
    refetch();
  }


  // ------- INPUTS

  const buttons = [
    { title: "NEW CUSTOMER", icon: AddIcon, onClick: setNewmode },
    { title: "IMPORT CUSTOMERS", icon: DocumentShareIcon, onClick: setCsvmode },

  ];

  const cols = [
    { title: "FIRST NAME", type: "button", flex: "2", query: "first_name" },
    { title: "LAST NAME", type: "button", flex: "2", query: "last_name" },
    { title: "COMPANY", type: "button", flex: "2", query: "company" },
    { title: "PAYMENT TERMS", type: "button", flex: "2", query: "payment_terms__value" },
    { title: "CUSTOMER CLASS", type: "button", flex: "2", query: "customer_class__value" },
  ]

  const searchInit = {
    full_name: "",
    company: "",
    out_of_business: false,
    customer_class__value: "",
    payment_terms__value: ""
  }

  const searchInputs = {
    full_name: { searchq: "full_name", dropLabel: "By Customer Name" },
    company: { searchq: "company", dropLabel: "By Company Name" },
  }

  const filterInputs = {
    customer_class__value: { comp: ForeignKeyMultiSelectDropDownInput, formLabel: "CLASS", group: 1, values: publisherGlobalData.customerclasses, marginRight: 4 },
    payment_terms__value: { comp: ForeignKeyMultiSelectDropDownInput, formLabel: "TERMS", group: 1, values: publisherGlobalData.paymentterms, marginRight: 4 },
  }

  // const checkInputs = {
  //   out_of_business: { width: 200, comp: CheckboxInput, formLabel: "Out of business?", group: 2 },
  // }

  const csvFields = [
    "adminNotes",
    "company",
    "created",
    "customerClass",
    "customeremailSet",
    "customerphonenumberSet",
    "customeraddressSet",
    "firstName",
    "id",
    "lastName",
    "outOfBusiness",
    "paymentTerms"
  ];

  const csvConfig = {
    "paymentTerms": ["value"],
    "customerClass": ["value"],
    "primarySets": {
      "customeraddressSet": {
        "fields": ["line1", "line2", "city", "stateOrRegion", "country", "zipcode"],
        "subfields": {},
        "label": "addresses"
      },
      "customeremailSet": {
        "fields": ["value"],
        "subfields": {},
        "label": "emails"
      },
      "customerphonenumberSet": {
        "fields": ["value"],
        "subfields": {},
        "label": "phoneNumbers"
      }
    },
    "sets": {},
    "arrays": {}
  }

  const csvTemplate = [
    [
      "first_name",
      "last_name",
      "company",
      "payment_term",
      "customer_class",
      "email",
      "phone",
      "address_line1",
      "address_line2",
      "address_city",
      "address_region",
      "address_zipcode",
      "address_country",
      "admin_notes"
    ],
    [
      "Customer's first name",
      "Customer's last name",
      "Company Name",
      "The prefered payment term as it exists in Working Lit",
      "The customer class as it exists in Working Lit",
      "Primary email",
      "Primary phone number",
      "Street address",
      "Street address - line 2",
      "City",
      "State or Region",
      "Zipcode",
      "Country",
      "Notes about customer"

    ],
    [
      "Ella",
      "Green",
      "Main Street Gift Shop",
      "pre-pay",
      "Consumer",
      "main-street@gift.shop",
      "777-777-7777",
      "17515 S Miles Rd",
      "",
      "Cleveland",
      "OH",
      "44128",
      "US",
      "make sure they pre-pay"
    ]
  ];

  const csvWidths = [
    3,
    3,
    2,
    2,
    4,
    3,
    2,
    3,
    2,
    2,
    2,
    2,
    3
  ]

  if (error) return `Error! ${error}`;

  return (
    <Pane position="absolute" padding={16} width="100%" height="100%">
      {csvmode ? (
        <CsvUpload handleToggleCsvMode={handleToggleCsvMode} template={csvTemplate} itemType="customer" tableWidth={2000} colWidths={csvWidths} />
      ) : (
        <Pane>
          <SideSheet isShown={newmode} onCloseComplete={() => setNewmode(false)}>
            <NewCustomer
              handleToggleNewMode={handleToggleNewMode}
              handleRefetchCustomers={handleRefetchCustomers}
              publisher={currentpublisher.id}
            />
          </SideSheet>
          <LandingPage
            title="Customers"
            location={location}
            sourcePageInfo={sourcePageInfo}
            data={customers}
            loading={loading}
            buttons={buttons}
            count={count}
            pagecount={pagecount}
            searchInputs={searchInputs}
            filterInputs={filterInputs}
            searchInit={searchInit}
            csvConfig={csvConfig}
            csvFields={csvFields}
            csvType="Customers"
            cols={cols}
            refetch={refetch}
            setParams={setParams}
            RowComponent={CustomerRow}
            currpage={currpage}
            setCurrpage={setCurrpage}
          />
        </Pane >
      )}
      </Pane>)}

function CustomerRow({ item, allmode, handleCheck, colWidths, makeIDCell, makeTableCellArray }) {
  const [checked, setChecked] = useState(false);

  const handleSetChecked = () => {
    handleCheck(checked, item)
    setChecked(!checked);
  }

  const rowCells = [
    {
      content: makeIDCell(checked, handleSetChecked, item.id), disableSelect:true
    },

    { content: item.firstName },
    { content: item.lastName},
    { content: item.company},
    { content: item.paymentTerms && item.paymentTerms.value},
    { content: <Badge color="neutral">{item.customerClass && item.customerClass.value}</Badge>
  }
  ]

  useEffect(() => {
    setChecked(allmode);
  }, [allmode]);
  const cellArray = makeTableCellArray(rowCells, colWidths, item.id)

  return cellArray
}

  export default Customers;