export const baseExpenseObject = () => {
  return {
    id: "",
    product: {
      id: "",
      title: "",
      format: {
        id: "",
        value: "",
        description: ""
      }
    },
    category: {
      id: "",
      value: ""
    },
    adminNotes: "",
    expenseDate: "",
    amount: "",
    locked: false,
    lastEditor: {
      id: "",
      email: ""
    },
    created: "",
    modified: ""
  }
}