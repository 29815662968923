import { gql } from '@apollo/client';

const UPDATE_USER_ROLE_MUTATION = gql`
  mutation UpdateUserRoleMutation($id: Int!, $role: Int!) {
    updateUserRole(id: $id, role: $role) {
      id
      roleId
      roleName
    }
  }
`;

export default UPDATE_USER_ROLE_MUTATION;