import { gql } from '@apollo/client';

const DELETE_CUSTOMER_MUTATION = gql`
  mutation DeleteCustomerMutation(
    $id: Int!,
  ) {
    deleteCustomer(
      id: $id,
    ) {
      message
    }
  }
`;

export default DELETE_CUSTOMER_MUTATION;