import React, { useState } from "react";
import { useMutation } from '@apollo/client';

// ------- MUTATIONS
import CREATE_CUSTOMER_MUTATION from "../../graphql/mutations/Customers/CreateCustomer.js";

// ------- COMPONENTS
import { baseCustomerTemplate } from './BaseCustomerTemplate.js';
import CustomerForm from "./CustomerForm.js";

import {
  Pane,
  Button,
  Paragraph,
  Card
} from 'evergreen-ui';

function NewCustomer(props) {
  const [staged, setStaged] = useState(baseCustomerTemplate);

  const [createCustomer, { customerdata }] = useMutation(CREATE_CUSTOMER_MUTATION);

  const handleClearStaged = () => {
    setStaged(baseCustomerTemplate);
  }

  const handleCreateCustomer = () => {
    createCustomer(
      {
        variables: {
          publisherId: parseInt(props.publisher),
          customer: JSON.stringify(staged)
        }
      });
    handleClearStaged();
    props.handleToggleNewMode();
    props.handleRefetchCustomers();
  }

  const handleUpdateStaged = obj => {
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }))
  }

  return (
    <Pane backgroundColor="var(--background)" width="100%" height="100%">
      <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16} paddingBottom={8} width="100%" display="flex" justifyContent="space-between" alignItems="flex-end">
        <Paragraph fontSize={18}> New Customer </Paragraph>
        <Pane display="flex" flexDirection="row" justifyContent="flex-start">
          <Button className="confirm-button" marginRight={16} onClick={e => {
            handleCreateCustomer();
          }}>
            CREATE CUSTOMER
          </Button>
          <Button intent="danger" onClick={e => {
            handleClearStaged();
            props.handleToggleNewMode();
          }}>
            Cancel
          </Button>
        </Pane>

      </Pane>
      <Pane padding={16}>
        <Card backgroundColor="white" padding={16} elevation={1}>
          <CustomerForm
            staged={staged}
            handleUpdateStaged={handleUpdateStaged}
            editing={true}
            editMode="new"
          />
        </Card>

      </Pane>

    </Pane>
  )
}

export default NewCustomer;