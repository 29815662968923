import { gql } from '@apollo/client';

const UPDATE_PUBLISHER_USER_MUTATION = gql`
  mutation UpdatePublisherUserMutation($id: Int!, $changed: String!) {
    updatePublisherUser(id: $id, changed: $changed) {
      message
    }
  }
`;

export default UPDATE_PUBLISHER_USER_MUTATION;