// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import {getAuth} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCIBf_xnMOniJP6pi6lne5XDcrKPhAFGCY",
  authDomain: "glowing-icon-322520.firebaseapp.com",
  projectId: "glowing-icon-322520",
  storageBucket: "glowing-icon-322520.appspot.com",
  messagingSenderId: "937646249879",
  appId: "1:937646249879:web:ebb9a4b7f41662e8fa16d2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);

export {app, storage, auth};