import { gql } from '@apollo/client';

const UPDATE_PRODUCT_MUTATION = gql`
  mutation UpdateProductMutation(
    $id: Int!, 
    $changed: String!
  ) {
    updateProduct(
      id: $id, 
      changed: $changed
    ) {
      message
    }
  }
`;

export default UPDATE_PRODUCT_MUTATION;