import { gql } from '@apollo/client';

const UPDATE_ORDER_PRODUCT_MUTATION = gql`
  mutation UpdateOrderProductMutation(
    $id: Int!,
    $changed: String!
  ) {
    updateOrderProduct(
      id: $id,
      changed: $changed
    ) {
      message
    }
  }
`;

export default UPDATE_ORDER_PRODUCT_MUTATION;