import { gql } from '@apollo/client';

const QUERY_CUSTOMERS = gql`
  query Customers($publisher: Int!, $page: Int, $params: String) {
    customers(publisher: $publisher, page: $page, params: $params) {
      id,
      firstName,
      lastName,
      company,
      outOfBusiness,
      paymentTerms {
        id,
        value
      },
      customerClass {
        id,
        value
      },
      customeremailSet {
        id,
        value,
        primary
      },
      customerphonenumberSet {
        id,
        value,
        primary
      },
      customeraddressSet {
        id,
        line1,
        line2,
        city,
        stateOrRegion,
        country,
        zipcode,
        primary,
        addressType
      },
      adminNotes,
      locked,
      lastEditor {
        id,
        email
      },
      created,
      modified,
    }
  }
`;

export default QUERY_CUSTOMERS;