import React, { useState, useEffect } from "react";
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { makeDateString } from '../../../util.js';
import QUERY_EXPENSES_BY_DATE from "../../../graphql/queries/QueryExpensesByDate.js";

import {
  Pane,
  Paragraph,
  EmptyState,
  InfoSignIcon
} from 'evergreen-ui';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import {
  DateFieldInput,
} from "../../../inputs/index.js";

const ExpensesByDate = props => {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);

  const today = new Date();
  const prevMonth = new Date();
  // const initStartDate = makeDateString(new Date(prevMonth.setDate(prevMonth.getDate()-30)));
  const initStartDate = makeDateString(new Date(prevMonth.setDate(prevMonth.getDate() - 730)));

  const initEndDate = makeDateString(today);
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [chartData, setChartData] = useState([]);

  const handleChangeDate = obj => {
    let newDate = makeDateString(obj.val);
    if (obj.key === "startDate") {
      setStartDate(newDate)
    } else {
      setEndDate(newDate)
    }
  }

  const { loading, error, data } = useQuery(QUERY_EXPENSES_BY_DATE, {
    variables: { publisher: parseInt(currentpublisher.id), startDate: startDate, endDate: endDate },
  });

  useEffect(() => {
    if (data) {
      const summedObj = {};
      data.expensesbydate.forEach(item => {
        let myKey = "expenseDate";
        if (summedObj.hasOwnProperty(item[myKey])) {
          summedObj[item[myKey]] = summedObj[item[myKey]] + parseFloat(item.amount);
        } else {
          summedObj[item[myKey]] = parseFloat(item.amount);
        }
      });
      const finalArr = [];
      for (let item in summedObj) {
        finalArr.push({ key: item, amount: summedObj[item] });
      }
      setChartData(finalArr);
    }
  }, [data]);

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <Pane background="white" elevation={2} padding={16} border={"default"} marginRight={16}>
      <Paragraph size={500} fontWeight="bolder" marginRight={8} color="var(--green)">Expenses</Paragraph>
      <Pane display="flex" flexDirection="row" justifyContent="flex-start" marginY={8}>
        <Pane marginRight={32}>
        <DateFieldInput el="startDate" value={startDate} editing={true} handleChange={handleChangeDate} formLabel="Start Date" width={300} />

        </Pane>
        <DateFieldInput el="endDate" value={endDate} editing={true} handleChange={handleChangeDate} formLabel="End Date" width={300} />
      </Pane>
      {data && data.expensesbydate.length > 0 ?
        <Pane>
          <LineChart width={350} height={250} data={chartData}
            margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="key" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="amount" stroke="#dcb482" strokeWidth={2} />
          </LineChart>
        </Pane> :
        <Pane height={250} width={350}>
        <EmptyState
          background="light"
          title="No Expenses Recorded for Selected Dates"
          orientation="vertical"
          icon={<InfoSignIcon size={12} color="#C1C4D6" />}
          iconBgColor="#EDEFF5"
        />
      </Pane>}
      
    </Pane>
  );
}

export default ExpensesByDate;
