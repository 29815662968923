import { gql } from '@apollo/client';

const UPDATE_ADDRESS_MUTATION = gql`
  mutation UpdateAddressMutation(
    $itemId: Int!,
    $contactType: String!,
    $changed: String!
  ) {
    updateAddress(
      itemId: $itemId,
      contactType: $contactType,
      changed: $changed
    ) {
      message
    }
  }
`;

export default UPDATE_ADDRESS_MUTATION;