import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import PropTypes from "prop-types"


/**
 * Page Heading Component.
 *
 * @component
 * @example
 * const title = "Products"
 * const buttons = []
 * const queryTitle = "Products Created Today"
 * const sourcePageInfo = {sourcePageInfo: }
 * return (
 *   <PageHeading title={title} buttons={buttons} queryTitle={queryTitle} sourcePageInfo={sourcePageInfo} frozen/>
 * )
 */

import {
    Pane,
    Paragraph,
    Button,
    Tooltip,
    LockIcon,
    HelpIcon,
} from "evergreen-ui";
import { Link } from "react-router-dom";

function PageHeading(props) {
    const { title, buttons, sourcePageInfo, frozen, plan, maxProducts } = props
    return (
        <Pane id="page-heading" width="100%" height="auto" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" paddingBottom={16}>
            <Pane>
                <Paragraph fontWeight="bolder" size={500} marginLeft={4}>{title}</Paragraph>
                {sourcePageInfo?.queryTitle && <Breadcrumbs singlePage={sourcePageInfo ? sourcePageInfo.sourcePageTitle : "HOME"} pageLabel="" curPage={sourcePageInfo.queryTitle} sourceUrl={sourcePageInfo ? sourcePageInfo.sourceURL : "/dashboard"} />}
            </Pane>
            <Pane display="flex" alignItems="center" >
                {frozen && <Tooltip content={
                    <Pane >
                        <Paragraph size={300} color="white"> Publishers on the {plan ? plan : "Free Plan"} can only create/maintain up to {maxProducts ? maxProducts : "10"} products To add more products or use products created during your free trial, visit your <Link to="/publisher" className="dark-link">publisher settings</Link> to explore other plans.</Paragraph>
                    </Pane>
                }>
                    <HelpIcon color="muted" marginRight={16} /></Tooltip>}
                {buttons.map((item, index) => {
                    return (

                        <Button
                            key={item.title + "button"}
                            marginLeft={index > 0 ? 8 : 0}
                            className={frozen ? "" : "new-button"}
                            iconBefore={frozen ? LockIcon : item.icon}
                            onClick={e => item.onClick(true)}
                            disabled={frozen}
                        >

                            {item.title}
                        </Button>)
                })}
            </Pane>
        </Pane>)
};

PageHeading.propTypes = {
    /**
     * Page title
     */
    title: PropTypes.string,

    /**
     * Array of properties for buttons in top right of page
     */
    buttons: PropTypes.array,

    /**
     * Description of query that brought user to page
     */
    queryTitle: PropTypes.string,

    /**
     * Link to previous page
     */
    sourcePageInfo: PropTypes.string,



}


export default PageHeading;