// TODO: Rename, redo according to Elly notes
import React, { useState } from "react";
import { makeInputs } from '../../util.js';

import {
  Pane,
  Button,
  SelectMenu,
  SearchInput,
  CaretDownIcon,
  Card
} from 'evergreen-ui';

function SearchAdvanced(
  { searches, filters, staged, handleSearch, handleResetSearch, setFilterDetails, filterDetails
  }) {
  const [stagedParams, setStagedParams] = useState({exclude: {}})

  const selectOptions = Object.values(searches)

  const anyStaged = selectOptions.some(k => staged[k.searchq] !== "");
  const stagedSearch = anyStaged ? selectOptions.filter(k => staged[k.searchq] !== "") : selectOptions[0]

  const [searchInput, setSearchInput] = useState(staged[stagedSearch.searchq]);
  const [searchButton, setSearchButton] = useState(stagedSearch.dropLabel);
  const [searchKey, setSearchKey] = useState(stagedSearch.searchq);

  const handleChangeSearchParams = obj => {
    let params = stagedParams
    let value;
    if (obj.key === 'shipping_type__value' || obj.key === 'payment_term__value') {
      value = obj.val.value;
    } else {
      value = obj.val;
    }
    // if (obj.key.match(/created_/)) {
    //   value = obj.val
    // }

    if (obj.hasOwnProperty('exclude')) {
      let excluded = params.exclude
      params.exclude = {...excluded, [obj.key]: obj.exclude}
    }
   
    if (obj.val == null || obj.val === "" || obj.val.length === 0) {
      delete params[obj.key]
    } else {
      params = { ...params, [obj.key]: value };
    }

    setStagedParams({ ...params })
  }

  let filterKeys = Object.keys(filters);
  filterKeys.forEach(key => {
    filters[key].handleChange = handleChangeSearchParams;
    filters[key].setDetails = setFilterDetails;
    filters[key].details = filterDetails;
  }
  );

  const formInputs = makeInputs(staged, filters, true);

  const handleUpdateStaged = () => {
    let params = stagedParams;
    let stagedSearch = staged;
    for (let [key, value] of Object.entries(stagedParams)) {
      stagedSearch = { ...stagedSearch, [key]: value };
    }
    selectOptions.forEach(option => {
      let field = option.searchq;
      if ((field !== searchKey && stagedSearch.hasOwnProperty(field)) || searchInput === "") {
        delete params[field];
        stagedSearch[field] = "";
      } else {
        params[searchKey] = searchInput;
        stagedSearch[searchKey] = searchInput;
      }
    })

    return [params, stagedSearch]

  }

  const handleConfirmSearch = () => {
    let [params, searchStaged] = handleUpdateStaged()
    handleSearch( params, searchStaged)
  }


  return (
    <Card width="100%" display="flex" padding={8} borderRadius={10} border="default" justifyContent="space-between"
      backgroundColor="white" elevation={1} alignItems="flex-end">

      <Pane display="flex" justifyContent="space-around" alignItems="flex-end">
        <SearchInput
          className={"drop-search"}
          placeholder={"Search..."}
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
        />
        <SelectMenu
          hasTitle={false}
          hasFilter={false}
          options={selectOptions.map(
            item => ({
              key: item.dropLabel,
              value: item.searchq,
              label: item.dropLabel
            })
          )}
          selected={searchButton}
          closeOnSelect={true}
          onSelect={(option) => {
            setSearchButton(option.label)
            setSearchKey(option.value)
          }}
        >
          <Button className="search-dropdown" iconAfter={CaretDownIcon}>{searchButton} </Button>
        </SelectMenu>


        <Pane display="flex" marginLeft={16} alignItems="flex-end">
          {formInputs.group1}
        </Pane>

      </Pane>

      <Pane display="flex">
        <Button marginX={8} className="confirm-button" onClick={e => {
          handleConfirmSearch()
        }}>SEARCH</Button>

        <Button marginRight={8} onClick={e => {
          let [params, searchStaged] = handleUpdateStaged()
          setFilterDetails({})
          setStagedParams({})
          setSearchInput("")
          handleResetSearch(searchStaged)
        }}
        >Reset</Button>
        {/* <IconButton icon={DoubleChevronDownIcon} onClick={e => SetShowFilters(!showFilters)}> */}
        {/* </IconButton> */}


      </Pane>



    </Card>
  );
}

export default SearchAdvanced;