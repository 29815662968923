import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { useSelector, } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/layout/Breadcrumbs.js";
import SaveButtons from "../../components/layout/SaveButtons.js";

import { baseOrderObject } from './BaseOrderObject.js';
import OrderDetails from "./OrderDetails.js";
import ProductBox from "./ProductBox.js";
import CREATE_ORDER_PRODUCT_MUTATION from "../../graphql/mutations/Orders/CreateOrderProduct.js";
import DELETE_ORDER_PRODUCT_MUTATION from "../../graphql/mutations/Orders/DeleteOrderProduct.js";
import UPDATE_ORDER_MUTATION from "../../graphql/mutations/Orders/UpdateOrder.js";
import DELETE_ORDER_MUTATION from "../../graphql/mutations/Orders/DeleteOrder.js";
import UPDATE_ORDER_PRODUCT_MUTATION from "../../graphql/mutations/Orders/UpdateOrderProduct.js";
import QUERY_ORDER from "../../graphql/queries/Orders/QueryOrder.js";
import { getActualAmountArr, makeDateString, makeInputs } from '../../util.js';

import {
  CurrencyInput,
  DateFieldInput,
  TextInputFieldInput
} from "../../inputs/index.js";


import {
  Badge,
  Card,
  Checkbox,
  EmptyState,
  InfoSignIcon,
  Pane,
  Paragraph,
  toaster,
} from 'evergreen-ui';

import OrderCustomer from "./OrderCustomer.js";
import OrderPaymentStatus from "./OrderPaymentStatus.js";

function Order(props) {
  const navigate = useNavigate();
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const [order, setOrder] = useState(baseOrderObject);
  const [changed, setChanged] = useState([]);
  const [staged, setStaged] = useState(baseOrderObject);
  const [stagedProducts, setStagedProducts] = useState([])
  const [canSave, setCanSave] = useState(true);
  const [canDelete, setCanDelete] = useState(false)

  const [newProducts, setNewProducts] = useState([])
  const [updatedProducts, setUpdatedProducts] = useState([])
  const [deletedProducts, setDeletedProducts] = useState([])
  const [editing, setEditing] = useState(false);
  const { orderId } = useParams();

  const handleUpdateStaged = obj => {
    // check for an existing record in changed
    delete obj.val.__typename;
    delete obj.val.label;

    let newArr = changed.filter(item => item.hasOwnProperty("key") && item.key !== obj.key);
    newArr.push(obj)

    if (obj.key === "customer") {
      const customerFields = ['orderEmail', 'orderAddress', 'orderPhone']
      newArr.filter(item => !customerFields.includes(item.key))
      customerFields.forEach(field => {
        newArr.push({ key: field, val: '' })
      })
    }

    setChanged(newArr);
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }));
    if (obj.key === "customer") {
      // reset order address, order email, order phone - eventually default to primary phone/address/email?
      setStaged(oldState => ({
        ...oldState,
        orderAddress: {
          id: "",
          line1: "",
          line2: "",
          city: "",
          stateOrRegion: "",
          country: "",
          zipcode: "",
          primary: "",
          addressType: "",
        },
        orderEmail: {
          id: "",
          valueid: "",
          primaryid: ""
        },
        orderPhone: {
          id: "",
          value: "",
          primary: ""
        },
        [obj.key]: obj.val
      }))

    }
    if (obj.key === 'shipped' && obj.val === true && !staged.shipDate) {
      const today = makeDateString(new Date());
      const shipDate = { key: 'shipDate', val: today };
      let currentArr = newArr.filter(item => item.hasOwnProperty("key") && item.key !== "shipDate");
      setChanged([shipDate].concat(currentArr));
    }
    if (obj.key === 'paid' && obj.val === true && !staged.paidDate) {
      const today = makeDateString(new Date());
      const paidDate = { key: 'paidDate', val: today };
      let currentArr = newArr.filter(item => item.hasOwnProperty("key") && item.key !== "paidDate");
      setChanged([paidDate].concat(currentArr));
    }
  }

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_ORDER, {
    variables: { publisher: parseInt(currentpublisher.id), id: parseInt(orderId) },
  });

  const [updateOrder, { orderdata }] = useMutation(UPDATE_ORDER_MUTATION);
  const [createOrderProduct, { createdata }] = useMutation(CREATE_ORDER_PRODUCT_MUTATION);
  const [updateOrderProduct, { updatedata }] = useMutation(UPDATE_ORDER_PRODUCT_MUTATION);
  const [deleteOrderProduct, { deletedata }] = useMutation(DELETE_ORDER_PRODUCT_MUTATION);
  const [deleteOrder, { deleteorderdata }] = useMutation(DELETE_ORDER_MUTATION);



  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  const handleRefetchOrder = () => {
    refetch();
  }

  const handleNewOrderProduct = (product, params) => {
    params.product = product;
    params.retailPrice = product.retailPrice;
    params.calcDiscount = 0

    if (params.discountPerUnit) { params.calcDiscount = params.discountPerUnit }

    if (params.netPrice) { params.calcDiscount = 100 * (params.retailPrice - params.netPrice) / (params.retailPrice) }
    params.calcPrice = params.retailPrice * (100 - params.calcDiscount) / 100

    let newArr = newProducts.concat(params)
    let stagedArr = stagedProducts.concat(params)
    setNewProducts(newArr)
    setStagedProducts(stagedArr)
    checkIfValid(stagedArr)
  }

  const handleUpdateOrderProduct = (item) => {
    let stagedArr = stagedProducts;
    if (item.tmpid) {
      stagedArr = stagedArr.filter(stagedItem => stagedItem.tmpid !== item.tmpid)
      stagedArr.push(item);
      setStagedProducts(stagedArr)
      let newArr = newProducts.filter(newItem => newItem.tmpid !== item.tmpid)
      newArr.push(item);
      setNewProducts(newArr)
    } else {
      stagedArr = stagedArr.filter(stagedItem => stagedItem.id !== item.id)
      stagedArr.push(item);
      setStagedProducts(stagedArr)
      let updatedArr = updatedProducts.filter(updatedItem => updatedItem.id !== item.id)
      updatedArr.push(item);
      setUpdatedProducts(updatedArr)
    }
    checkIfValid(stagedArr)
  }

  const handleDeleteOrderProduct = (item) => {
    let stagedArr = stagedProducts;
    if (item.tmpid) {
      stagedArr = stagedArr.filter(stagedItem => stagedItem.tmpid !== item.tmpid)
      setStagedProducts(stagedArr)
      let newArr = newProducts.filter(newItem => newItem.tmpid !== item.tmpid)
      setNewProducts(newArr)
    } else {
      stagedArr = stagedArr.filter(stagedItem => stagedItem.id !== item.id)
      setStagedProducts(stagedArr)
      let updatedArr = updatedProducts.filter(updatedItem => updatedItem.id !== item.id)
      setUpdatedProducts(updatedArr)
      let deletedArr = deletedProducts.concat(item)
      setDeletedProducts(deletedArr)
    }
    checkIfValid(stagedArr)

  }

  const resetForm = () => {
    setStaged(order);
    setStagedProducts(order.orderproductSet)
    setChanged([])
    setNewProducts([])
    setUpdatedProducts([])
    setDeletedProducts([])
  }

  const handleUpdateOrder = async () => {
    try {
      if (changed.length) {

        const results = await updateOrder(
          {
            variables: {
              id: parseInt(staged.id),
              changed: JSON.stringify(changed),
            }
          });
        toaster.success(results.data.updateOrder.message)
        setChanged([]);
        setOrder(staged);
      }

      if (newProducts.length) {
        const allNew = await Promise.all(newProducts.map(async (orderprod) => {
          const params = Object.fromEntries(
            ['quantity', 'retailPrice', 'calcDiscount', 'calcPrice', 'discountPerUnit', 'netPrice']
              .filter(key => key in orderprod) // line can be removed to make it inclusive
              .map(key => [key, orderprod[key]])
          );

          const newprod = await createOrderProduct(
            {
              variables: {
                productId: parseInt(orderprod.product.id),
                orderId: parseInt(staged.id),
                orderProduct: JSON.stringify(params),
              }
            });
          return newprod
        }))

        toaster.closeAll()
        toaster.success(`Added ${allNew.length} product(s) to order`, { duration: 10 })
        setNewProducts([])
      }

      if (updatedProducts.length) {
        const allUpdated = await Promise.all(updatedProducts.map(async (orderprod) => {
          const params =
            ['quantity', 'retailPrice', 'calcDiscount', 'calcPrice', 'discountPerUnit', 'netPrice']
              .filter(key => key in orderprod) // line can be removed to make it inclusive
              .map(key => { return { key: key, val: orderprod[key] } })


          const updatedProd = await updateOrderProduct(
            {
              variables: {
                id: parseInt(orderprod.id),
                changed: JSON.stringify(params),
              }
            });
          return updatedProd
        }))

        toaster.closeAll()
        toaster.success(`Updated ${allUpdated.length} product(s) in order`, { duration: 10 })
        setUpdatedProducts([])
      }

      if (deletedProducts.length) {
        const allDeleted = await Promise.all(deletedProducts.map(async (orderprod) => {
          const deletedprod = await deleteOrderProduct(
            {
              variables: {
                id: parseInt(orderprod.id),
              }
            });
          return deletedprod
        }))
        toaster.closeAll()
        toaster.success(`Deleted ${allDeleted.length} product(s) from order`, { duration: 10 })
        setDeletedProducts([])
      }
      handleRefetchOrder()

    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error saving order. Try again or contact developer", { duration: 5 })
      console.log(error)
    }




  }

  const handleDeleteOrder = async () => {
    try {
      const results = await deleteOrder(
        {
          variables: {
            id: parseInt(staged.id),
          }
        });
      toaster.closeAll()
      toaster.success(`Order ${results.data.deleteOrder.message} deleted succesfully!`, { duration: 10 })
      navigate('/orders')

    }
    catch (error) {
      toaster.closeAll()
      toaster.danger("Error deleting Order. Try again or contact developer", { duration: 5 })
      return error.message;
    }
  }


  const handleChooseInfo = (itemString, itemType) => {
    let item = JSON.parse(itemString)
    handleUpdateStaged({ key: itemType, val: item });
  }

  const checkIfValid = arr => {
    let invalid = arr.some(item => {
      return (!item.quantity || parseInt(item.quantity) === 0)
    })
    if (invalid) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }

  const inputs = {
    paidDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 1, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },
    shipDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 2, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },
    shippingCost: { width: 100, comp: CurrencyInput, formLabel: "", handleChange: handleUpdateStaged, group: 3 },
    poNumber: { width: 100, comp: TextInputFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 4, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },

  };

  const formInputs = makeInputs(staged, inputs, editing);
  const total_amount_arr = getActualAmountArr(stagedProducts);
  const total_paid_arr = stagedProducts.map(item => item.amount);
  const total_quantity_arr = stagedProducts.map(item => parseInt(item.quantity));





  useEffect(() => {
    if (data && data.order.length) {
      setOrder(data.order[0]);
      setStaged(data.order[0]);
      setStagedProducts(data.order[0].orderproductSet)
      const linkedModelCount = data.order[0].orderproductSet.length
      if (linkedModelCount === 0) {
        setCanDelete(true)
      }
    }
  }, [data]);

  if (loading) return null;
  if (error) return `Error! ${error}`;
  if (data.order.length === 0) return (
    <Pane >
      <Pane paddingX={16} paddingTop={16}>
        <Breadcrumbs pageLabel="ORDER" sourceUrl="/orders" />
      </Pane>
      <Pane display="flex" justifyContent="center" marginTop={60}>
        <Card alignItems="center" width="50%" elevation={2}>
          <EmptyState
            background="light"
            title="Order not found"
            orientation="vertical"
            icon={<InfoSignIcon size={12} color="#C1C4D6" />}
            iconBgColor="#EDEFF5" />
        </Card>

      </Pane>


    </Pane>);

  return (
    <Pane>
      <Pane id="page-heading" borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16}>
        <Breadcrumbs pageLabel="ORDER" curPage={order.id} sourceUrl="/orders" />
        <Pane marginTop={8} marginBottom={8} size={300} display="flex" justifyContent="space-between" >
          <Pane display="flex" alignItems="flex-end">
            <Paragraph marginLeft={4} size={300} fontSize={18}>Order</Paragraph>
            <Paragraph marginLeft={8} size={300}><Badge color="neutral">ID: {order.id}</Badge></Paragraph>
            {editing ?
              <Pane display="flex" alignItems="flex-end">
                <Paragraph marginLeft={8} size={300}>PO: </Paragraph>
                {formInputs.group4}
              </Pane>
              : order.poNumber &&
              <Paragraph marginLeft={8} size={300}><Badge color="neutral">PO: {order.poNumber}</Badge></Paragraph>}
            {!editing && order.orderStatus &&
              (<Paragraph marginLeft={8} size={300}>
                <Badge color={order.orderStatus.value === "Ready" ? "green" : "neutral"}>
                  {order.orderStatus ? order.orderStatus.value : "Status Unavailable"}
                </Badge>
              </Paragraph>)
            }

            <Pane display="flex" alignItems="center">
              <Checkbox marginY={0} marginLeft={8} marginRight={4} label="PAID" checked={staged.paid}
                disabled={!editing} onChange={e => handleUpdateStaged({ key: 'paid', val: !staged.paid })} />
              <Pane>{editing ? staged.paid ? <Pane>{formInputs.group1}</Pane> : "" : (staged.paid && <Paragraph size={300}>
                ({typeof order.paidDate === "string" ? (order.paidDate) : order.paidDate ?
                  order.paidDate.toISOString().replace(/T.*/, '').split('-').join('-') : ""})</Paragraph>)}</Pane>
              <Checkbox marginY={0} marginLeft={8} marginRight={4} label="SHIPPED" checked={staged.shipped}
                disabled={!editing} onChange={e => handleUpdateStaged({ key: 'shipped', val: !staged.shipped })} />
              {editing ? staged.shipped ? <Pane>{formInputs.group2}</Pane> : "" : (staged.shipped && <Paragraph size={300}>
                ({typeof order.shipDate === "string" ? (order.shipDate) : order.shipDate ?
                  order.shipDate.toISOString().replace(/T.*/, '').split('-').join('-') : ""})</Paragraph>)}
            </Pane>

          </Pane>
          <Pane>
            <SaveButtons
              itemType="order"
              editing={editing}
              locked={order.locked}
              itemId={order.id}
              setEditing={setEditing}
              handleUpdate={handleUpdateOrder}
              handleDelete={handleDeleteOrder}
              deleteMsg={"Cannot delete order if there are any products in order. Edit order to remove products before deletion"}
              resetForm={resetForm}
              refetch={refetch}
              canSave={canSave}
              canDelete={canDelete}
            />
          </Pane>
        </Pane>
      </Pane>
      <Pane display="flex">
        <Pane>

        </Pane>
        <OrderCustomer order={order} staged={staged} editing={editing} handleChooseInfo={handleChooseInfo} />
        <OrderDetails
          staged={staged}
          handleUpdateStaged={handleUpdateStaged}
          handleRefetchOrder={handleRefetchOrder}
          editing={editing}
          unitCount={total_quantity_arr.reduce((partial_sum, a) => partial_sum + a, 0)}
        />
        <OrderPaymentStatus
          staged={staged}
          editing={editing}
          stagedProducts={stagedProducts}
          handleRefetchOrder={handleRefetchOrder}
          handleUpdateStaged={handleUpdateStaged}
        />


      </Pane>
      <Pane id="order-products" margin={16} >
        <ProductBox
          orderId={order.id}
          orderproductSet={stagedProducts}
          handleRefetchOrder={handleRefetchOrder}
          handleNewOrderProduct={handleNewOrderProduct}
          handleUpdateOrderProduct={handleUpdateOrderProduct}
          handleDeleteOrderProduct={handleDeleteOrderProduct}
          total_amount_arr={total_amount_arr}
          total_paid_arr={total_paid_arr}
          shippingCost={order.shippingCost ? order.shippingCost : 0}
          paid={order.paid}
          editing={editing}
        />
      </Pane>
    </Pane>

  )
}

export default Order;
