import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { checkValidity } from '../../util.js';
import AddProductSimple from "../../inputs/select/SelectProduct.js"

import {
  Pane,
  Paragraph,
  Button,
  TextInput,
  toaster,
  SelectField,
  AddIcon
} from 'evergreen-ui';

function AddOrderProduct(props) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [discount, setDiscount] = useState("retail");
  const [selected, setSelected] = useState(false);
  const [staged, setStaged] = useState({
    quantity: "",
    discountPerUnit: "",
    netPrice: "",
  })
  const isValid = checkValidity([{ name: "quantity", required: true, fieldType: "text" }], staged);
  const handleUpdateStaged = (key, value) => {
    setStaged(oldState => ({ ...oldState, [key]: value }))
  }

  const handleUpdateProduct = item => {
    setSelected(JSON.parse(item))
  }

  const clearStaged = () => {
    setSelected(false)
    setDiscount("retail")
    setStaged({
      quantity: "",
      discountPerUnit: "",
      netPrice: "",
    })
  }

  const handleAddProduct = () => {
    if (isValid || !props.orderMode) {
      props.handleNewOrderProduct(selected, staged);
      setSelected(false)
      setStaged({
        quantity: "",
        discountPerUnit: "",
        netPrice: "",
      })
    } else {
      toaster.danger("Please fix the errors before adding");
    }
  }

  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  return (<Pane>
    <Pane display="flex" justifyContent="space-between">
      <Paragraph size={300} fontWeight="bold" color="#5e5e5e" marginBottom={8}>NEW ORDER ITEM</Paragraph>
      <Pane>
        <Button size="small" iconBefore={AddIcon} className="confirm-button" onClick={e => handleAddProduct()} disabled={!isValid}>ADD</Button>
        <Button size="small" marginLeft={8} onClick={e => clearStaged()}>Clear</Button>
      </Pane>
    </Pane>
    <Pane display="flex" marginTop={16}>
      <AddProductSimple handleAttachProduct={handleUpdateProduct} product={selected} editing={true} parent="orders"/>
    </Pane>
    <Pane display="flex" marginTop={16}>
      <Pane marginRight={16}>
        <Paragraph color="#667b7f" size={300} marginBottom={8}> Quantity </Paragraph>
        <TextInput width={56}
          isInvalid={!(/^\d+$/.test(staged.quantity))}
          value={staged.quantity} onChange={e => handleUpdateStaged("quantity", e.target.value)} />
      </Pane>
      <Pane display="flex" flexDirection="column">
        <Paragraph color="#667b7f" size={300} > Discount Type </Paragraph>
        <SelectField
          width={100}
          value={discount}
          label=""
          onChange={e => {
            setDiscount(e.target.value)
            setStaged(oldState => ({
              ...oldState,
              "discountPerUnit": "",
              "netPrice": ""
            }))
          }}
        >
          <option value="retail"> None (MSRP) </option>
          <option value="percent">Percent</option>
          <option value="net">Net Price</option>
        </SelectField>
      </Pane>
      {discount === "net" && (
        <Pane marginLeft={16}>
          <Paragraph marginLeft={8} color="#667b7f" size={300} marginBottom={8} > Net Price </Paragraph>
          <Paragraph marginBottom={8}>
            {currency} <TextInput width="50%" value={staged.netPrice}
              onChange={e => handleUpdateStaged('netPrice', e.target.value)

              }
            />
          </Paragraph>

        </Pane>
      )}
      {discount === "percent" && (
        <Pane marginLeft={16}>
          <Paragraph color="#667b7f" size={300} marginBottom={8} > Discount </Paragraph>
          <Paragraph marginBottom={8}>
            <TextInput width="50%" value={staged.discountPerUnit}
              onChange={e => handleUpdateStaged('discountPerUnit', e.target.value)} /> %
          </Paragraph>
        </Pane>
      )}
      {discount === "retail" && (
        <Pane marginLeft={16}>
          <Paragraph marginLeft={8} color="#667b7f" size={300} marginBottom={8} > Retail Price </Paragraph>
          <Paragraph marginBottom={8}>
            {currency} <TextInput width={80} value={selected ? selected.retailPrice : ""} disabled />
          </Paragraph>
        </Pane>
      )}
    </Pane>

  </Pane>

  )
}

export default AddOrderProduct;