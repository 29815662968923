import React from "react";

import { Pane, Paragraph, StatusIndicator } from "evergreen-ui";

function InboxItem({ item, unread }) {
    return (
        <Pane marginBottom={16} borderBottomColor="var(--medgrey)" borderBottomWidth={1} borderBottomStyle="solid">
            <Pane paddingX={16} paddingBottom={8}>
                <Pane display="flex" justifyContent="space-between" marginBottom={8} alignItems="center">
                    <Pane display="flex" alignItems="center">
                        {unread && <StatusIndicator color="var(--orange)" />}
                        <Paragraph size={400} fontWeight="bold">{item.title}</Paragraph>
                    </Pane>
                    <Paragraph size={300} color="var(--darkgrey)">{item.date}</Paragraph>
                </Pane>
                <Paragraph size={300}>{item.message}</Paragraph>
            </Pane>

        </Pane>)
}

export default InboxItem;