export const baseProductObject = () => {
  return {
    id: "",
    title:"",
    subtitle:"",
    description: "",
    isbn: "",
    shipDate: "",
    publicationDate: "",
    status: {
      id: "",
      value: "",
      description: ""
    },
    format: {
      id: "",
      value: "",
      description: ""
    },
    formatDetail: {
      id: "",
      value: "",
      description: ""
    },
    retailPrice: "",
    wholesalePrice: "",
    inventoryQuantity: "",
    cartonQty: "",
    weight: {},
    width: {},
    height: {},
    thickness: {},
    pageCount: "",
    imprint: {
      id: "",
      value: ""
    },
    audience: {
      id: "",
      value: "",
      description: ""
    },
    audienceDetails: "",
    keySellingPoints: "",
    marketing: "",
    adminNotes: "",
    tags: "[]",
    edition: "",
    volume: "",
    series: {
      id: "",
      value: ""
    },
    bisac: [],
    outOfPrint: false,
    hasShippingCharges: true,
    coverImage: "",
    interiorImages: "",
    comps: "",
    locked: false,
    lastEditor: {
      id: "",
      email: ""
    },
    created: "",
    modified: "",
    productcreatorSet: [
      {
        id: "",
        creator: {
          id: "",
          displayName: ""
        },
        onixContributorCode: {
          id: "",
          value: "",
          description: ""
        }
      }
    ]
  }
}