import React from "react";
import { useMutation } from '@apollo/client';
import { useSelector } from "react-redux";
import { getCookie } from "../../util";

import LOCK_ITEM_MUTATION from "../../graphql/mutations/LockItem";

import {
  Pane,
  Button,
  Tooltip,
  HelpIcon,
  toaster,
  Popover,
  Paragraph,
  Spinner
} from 'evergreen-ui';

function SaveButtons(props) {
  const userid = useSelector((state) => state.user.value);
  const [lockItem, { lockdata }] = useMutation(LOCK_ITEM_MUTATION);

  const handleEnterEditMode = () => {
    if (props.setCancelled) { props.setCancelled(false) };
    const csrftoken = getCookie('csrftoken');

    fetch('/get_locked_status/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({ item_type: props.itemType, item_id: props.itemId })
    })
      .then(res => res.json())
      .then(data => {
        if (data.locked === true) {
          toaster.warning(`This record is currently being edited by ${data.email}`)
          props.refetch()
        } else {
          setLockedStatus(true);
          props.setEditing(true)
        }
      });

  }

  const setLockedStatus = async (bool) => {
    let lockResult = await lockItem(
      {
        variables: {
          userId: userid,
          itemType: props.itemType,
          itemId: parseInt(props.itemId),
          status: bool
        }
      })
    props.refetch()
    return lockResult.data.lockItem.message
  }

  const handleSave = async () => {
    let updateResult = await props.handleUpdate()
    console.log(updateResult)
  }

  const handleSaveAndClose = async () => {
    let updateResult = await props.handleUpdate()
    console.log(updateResult)
    props.setEditing(false)
    let lockResult = await setLockedStatus(false)
    console.log(lockResult)
  }

  const handleDelete = async () => {
    let deleteResult = await props.handleDelete()
    console.log(deleteResult)
  }

  const handleCancel = async () => {
    props.resetForm()
    props.setEditing(false)
    let lockResult = await setLockedStatus(false)
    console.log(lockResult)
    if (props.setCancelled) { props.setCancelled(true) };
    props.refetch()
  }

  const canSave = props.canSave === undefined ? false : !props.canSave
  console.log('!!!!! SaveButtons props:', {
    canSave: props.canSave,
    computedCanSave: canSave,
    editing: props.editing,
    locked: props.locked,
    frozen: props.frozen
  });

  if (props.disabled) {
    return (
      <Pane display="flex" alignItems="center">
        <Tooltip content={"Temporarily disabled for site update."} ><HelpIcon color="muted" marginRight={16} /></Tooltip>
        <Button disabled={true} >Edit</Button>
        <Button disabled={true} marginLeft={8} >Unlock</Button>
      </Pane>
    )
  }
  else if (props.editing) {
    return (
      <Pane display="flex" alignItems="center">
        {(props.loading || lockdata?.loading) && <Spinner size={24}/>}
        <Button marginRight={8} disabled={canSave} onClick={e => handleSave()}>Save</Button>
        <Button marginRight={8} disabled={canSave} onClick={e => handleSaveAndClose()}>Save and Close</Button>
        <Button onClick={e => handleCancel()}>Cancel</Button>
      </Pane>
    )
  } else {
    return (
      <Pane display="flex" alignItems="center">
        <Button disabled={props.frozen ? true : props.locked} onClick={e => handleEnterEditMode()}>Edit</Button>
        <Button marginLeft={8} disabled={!props.locked} onClick={e => setLockedStatus(false)} >Unlock</Button>
        <Popover
                  content={({ close }) => (
                    <Pane padding={16} >
                      <Paragraph>Are you sure you want to delete this object?</Paragraph>
                      <Paragraph>This action cannot be undone.</Paragraph>
                      <Pane marginTop={16}>
                        <Button marginRight={16} appearance="primary" intent="danger" onClick={e => handleDelete()} fontWeight="bold">
                          DELETE
                        </Button>
                        <Button onClick={close}> Cancel</Button>
                      </Pane>
                    </Pane>
                  )}
                >
        <Button marginLeft={8} intent="danger" disabled={!props.canDelete} onClick={e => handleDelete()}>Delete</Button>
        </Popover>
        { !props.canDelete &&
        <Tooltip content={props.deleteMsg} ><HelpIcon color="muted" marginX={8} /></Tooltip>
        }

      </Pane>
    )
  }
}

export default SaveButtons;
