import { gql } from '@apollo/client';

const QUERY_ROYALTYS_FOR_STATEMENT = gql`
  query RoyaltysForStatement($userid: Int!, $publisher: Int!, $creator: String!, $startDate: String, $endDate: String) {
    royaltysforstatement(publisher: $publisher, creator:$creator, startDate: $startDate, endDate: $endDate) {
      id,
      product {
        id,
        title,
        retailPrice,
        format {
          id,
          value,
          description
        },
        isbn,
        
        expenseSet {
          id,
          expenseDate,
          amount,
          category {
            value
          }
          adminNotes
        }
      },
      creator {
        id,
        displayName,
        paymentName,
        paymentType {
          id,
          value
        },
        creatoremailSet {
          id,
          value,
          primary
        },
        creatoraddressSet {
          id,
          line1,
          line2,
          city,
          stateOrRegion,
          country,
          zipcode,
          primary
        }
      },
      royaltylineitemSet {
        id,
        amount,
        thresholdStart,
        thresholdEnd,
        category {
          id,
          value
        },
        royaltyTerm {
          id,
          value
        },
        tierDiscount
      },
      royaltypaymentSet {
        id,
        method {
          id,
          value
        },
        amount,
        sentDate
      }
      locked,
      lastEditor {
        id,
        email
      },
      created,
      modified,
      paid,
      statementInfo
    }
    user(id: $userid){
      id
	    email
	    publisheruserSet {
	    	id
			firstName
	    }

    }
  }
`;

export default QUERY_ROYALTYS_FOR_STATEMENT;