import { gql } from '@apollo/client';

const UPDATE_ORDER_MUTATION = gql`
  mutation UpdateOrderMutation(
    $id: Int!, 
    $changed: String!
  ) {
    updateOrder(
      id: $id, 
      changed: $changed
    ) {
      message
    }
  }
`;

export default UPDATE_ORDER_MUTATION;