export const baseCreatorTemplate = () => {
  return {
    id: "",
    displayName: "",
    paymentName: "", 
    photoUrl: "", 
    residence: "",  
    paymentType: {
      id: "",
      value: ""
    },
    royaltystatementSet: [],
    biography: "",
    locked: false,
    lastEditor: {
      id: "",
      email: ""
    }
  }
}