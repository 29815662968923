import React, { useState } from "react";
import { Combobox, Pane, Button, majorScale, Paragraph, Select } from 'evergreen-ui';

function Sort(props) {
  const [sortDirection, setSortDirection] = useState("ascending");

  return (
    <Pane display="flex" alignItems="center">
      <Paragraph size={300}> Sort by: </Paragraph>
      <Pane display="flex" alignItems="center" marginLeft={8} >
        <Combobox
          size="small"
          width={majorScale(20)}
          items={Object.keys(props.sortOptions)}
          itemToString={option => {
            return option === null ? '' : props.sortOptions[option].formLabel;
          }}
          onChange={props.handleSetSortField}
          initialSelectedItem={props.selectedSortOrder}
        />
        <Pane>
          <Select size="small" width={majorScale(15)} value={sortDirection} onChange={event => {
            setSortDirection(event.target.value)
            props.handleSetSortDirection(event.target.value)
          }} >
            <option value="ascending">Ascending</option>
            <option value="descending">Descending</option>
          </Select>
        </Pane>
        <Button marginLeft={16} size="small" onClick={props.handleSort}>Sort</Button>
      </Pane></Pane>
  );
}

export default Sort;
