import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    value: {
      id: false,
      firstName: false,
      lastName: false,
      email: false,
      editing: false,
    },
  },
  reducers: {
    setprofile: (state, action) => {
      if (action.payload.hasOwnProperty("__typename")) {
        delete action.payload.__typename;
      }
      const obj = Object.assign({}, state.value, action.payload);
      state.value = obj;
      // state.value = action.payload
    },
  },
})

export const { setprofile } = profileSlice.actions;

export default profileSlice.reducer;