export const baseRoyaltyObject = () => {
  return {
    id: "",
    product: {
      id: "",
      title: "",
      format: {
        id: "",
        value: "",
        description: ""
      },
      orderproductSet: [{
        order: {
          id: "",
          paid: ""
        },
        id: "",
        quantity: "",
        discountPerUnit: "",
        netPrice: "",
        wholesale: "",
        created: ""
      }
    ]},
    creator: {
      id: "",
      displayName: "",
      paymentName: "",
      paymentType: {
        id: "",
        value: ""
      },
      creatoremailSet: [
        {
          id: "",
          value: "",
          primary: ""
        }
      ],
      creatoraddressSet: [
        {
          id: "",
          line1: "",
          line2: "",
          city: "",
          stateOrRegion: "",
          country: "",
          zipcode: "",
          primary: "",
          addressType: ""
        }
      ]
    },
    royaltyTerm: {
      id: "",
      value: ""
    },
    amountType: "",
    thresholdType: "",
    royaltylineitemSet: [{
      id: "",
      amount: "",
      thresholdStart: "",
      thresholdEnd: "",
      tierDiscount: ""
    }],
    royaltypaymentSet: [{
      id: "",
      method: {
        id: "",
        value: ""
      },
      amount: "",
      sentDate: ""
    }],
    adminNotes: "",
    locked: false,
    lastEditor: {
      id: "",
      email: ""
    },
    created: "",
    modified: ""
  }
}
